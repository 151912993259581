import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "itinerary"
  }, [_vm.itinerary.length ? _c('div', {
    staticClass: "list"
  }, [_c('ol', _vm._l(_vm.itinerary, function (entry, idx) {
    return _c('li', {
      key: idx,
      staticClass: "flex",
      staticStyle: {
        "line-height": "28px",
        "justify-content": "flex-end"
      }
    }, [entry ? _c('div', {
      staticStyle: {
        "flex-grow": "1"
      }
    }, [_c('div', {
      staticClass: "bold"
    }, [entry.distance ? _c('span', [_vm._v("(" + _vm._s(entry.distance) + ")")]) : _vm._e(), entry.duration ? _c('span', [_vm._v("(" + _vm._s(entry.duration) + ")")]) : _vm._e()]), _c('div', [_vm._v(_vm._s(entry.label))])]) : _vm._e(), idx > 0 && idx < _vm.itinerary.length - 1 ? _c('button', {
      staticClass: "btn _clear",
      on: {
        "click": function click($event) {
          return _vm.itinerary.splice(idx, 1);
        }
      }
    }, [_c('i', {
      staticClass: "icon-trashbin"
    })]) : _c('div', {
      staticStyle: {
        "width": "32px"
      }
    }, [_vm._v(" ")])]);
  }), 0), _vm.totalDistance ? _c('div', {
    staticClass: "bold total"
  }, [_vm._v("Total:"), _c('br'), _vm._v(_vm._s(_vm.totalDistance) + " km / " + _vm._s(Math.floor(_vm.totalDuration / 60)) + " h " + _vm._s(_vm.totalDuration % 60) + " min")]) : _vm._e()]) : _vm._e(), _c('div', [_c('visitation-map', {
    on: {
      "click": _vm.onMapClicked
    },
    scopedSlots: _vm._u([{
      key: "context-menu",
      fn: function fn(_ref) {
        var marker = _ref.marker;
        return [_c('div', [_vm._v(_vm._s(marker.label))]), marker.meta && marker.meta.akt ? _c('div', [_vm._v(_vm._s((marker.meta.akt.new_auftraggeber || {}).name))]) : _vm._e(), _c('div', [_c('hr'), _c('context-action', {
          on: {
            "click": function click($event) {
              return _vm.$set(_vm.itinerary, 0, {
                loc: marker.position,
                label: marker.label
              });
            }
          }
        }, [_vm._v("Start für Route")]), _c('context-action', {
          on: {
            "click": function click($event) {
              return _vm.itinerary.splice(-1, 0, {
                loc: marker.position,
                label: marker.label
              });
            }
          }
        }, [_vm._v("Zum Weg hinzufügen")]), _c('context-action', {
          on: {
            "click": function click($event) {
              return _vm.$set(_vm.itinerary, _vm.itinerary.length > 1 ? _vm.itinerary.length - 1 : 1, {
                loc: marker.position,
                label: marker.label,
                kind: marker.meta && marker.meta.kind
              });
            }
          }
        }, [_vm._v("Ende für Route")])], 1)];
      }
    }])
  }, [_vm._l(_vm.otherAktsToDate, function (akt) {
    return akt.loc ? _c('map-marker', {
      key: akt.new_namekurz,
      attrs: {
        "meta": {
          akt: akt,
          kind: 'commission'
        },
        "label": akt.new_namekurz,
        "status": akt.new_status,
        "position": akt.loc
      }
    }) : _vm._e();
  }), _c('map-marker', {
    attrs: {
      "label": _vm.office.label,
      "icon": _vm.office.icon,
      "meta": {
        kind: 'office'
      },
      "position": _vm.office.loc
    }
  }), _vm.customMarker ? _c('map-marker', {
    attrs: {
      "label": "Eigener Marker",
      "icon": _vm.customMarkerIcon,
      "meta": {
        kind: 'custom'
      },
      "position": _vm.customMarker
    }
  }) : _vm._e()], 2), _vm.isCalculationStale ? _c('div', {
    staticClass: "stale-calculation-warning color-red small"
  }, [_vm._v("Berechnung verwendet eine alte Position des eigenen Markers " + _vm._s(' ')), _c('br'), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.updateCustomMarkerInItinerary
    }
  }, [_vm._v("(Neu berechnen)")])]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };