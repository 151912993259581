import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "flexUl"
  }, [_c('ul', {
    staticClass: "fItem"
  }, [_c('li', [_c('input-field', {
    attrs: {
      "label": "Rechnungsdatum"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.value.createdon).format('DD.MM.YYYY')))])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Rechnungsempfänger:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.crmAkt.new_honorartypneu,
      expression: "crmAkt.new_honorartypneu"
    }],
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.crmAkt, "new_honorartypneu", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.hntyp, function (val, ix) {
    return _c('option', {
      domProps: {
        "value": parseInt(ix)
      }
    }, [_vm._v(_vm._s(val))]);
  }), 0)])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Rechnung an:"
    }
  }, [_vm.allowAddressModification ? _c('TextareaAutosize', {
    staticClass: "input",
    attrs: {
      "min-height": 96,
      "max-height": 480
    },
    model: {
      value: _vm.addressSync,
      callback: function callback($$v) {
        _vm.addressSync = $$v;
      },
      expression: "addressSync"
    }
  }) : _c('div', {
    staticClass: "input whitespace-pre",
    domProps: {
      "innerHTML": _vm._s(_vm.addressSync)
    }
  })], 1)], 1)]), _c('ul', {
    staticClass: "fItem"
  }, [_c('li', [_c('input-field', {
    attrs: {
      "label": "Geändert / (Erstellt)"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.value.modifiedon).format('DD.MMM.YYYY HH:mm')) + " / (" + _vm._s(_vm.$dayjs(_vm.value.createdon).format('DD.MMM.YYYY')) + ")")])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Netto"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm.value.new_honorarsumme ? _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.value.new_honorarsumme)))]) : _c('span', {
    staticClass: "color-red"
  }, [_vm._v("--noch nicht berechnet--")]), _vm.value.new_honorarsumme !== _vm.currentTotalRevenue ? _c('span', [_vm._v(" (" + _vm._s(_vm.$_formatMoney(_vm.currentTotalRevenue)) + ")")]) : _vm._e()])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "USt. verrechnen"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#82C7EB",
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: _vm.value.new_ustverrechnen,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "new_ustverrechnen", $$v);
      },
      expression: "value.new_ustverrechnen"
    }
  })], 1)]), _vm.value.new_ustverrechnen ? _c('input-field', {
    attrs: {
      "label": "USt.:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm.value.new_ustverrechnen ? _c('span', [_vm.value.new_ustbetrag ? _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.value.new_ustbetrag)))]) : _c('span', {
    staticClass: "color-red"
  }, [_vm._v("--noch nicht berechnet--")]), _vm.value.new_honorarsumme !== _vm.currentTotalRevenue ? _c('span', [_vm._v(" (" + _vm._s(_vm.$_formatMoney(_vm.currentTotalRevenue * 0.2)) + ")")]) : _vm._e()]) : _vm._e()])]) : _vm._e()], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Brutto"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm.value.new_honorarsummebrutto ? _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.value.new_honorarsummebrutto)))]) : _c('span', {
    staticClass: "color-red"
  }, [_vm._v("--noch nicht berechnet--")]), _vm.value.new_honorarsumme !== _vm.currentTotalRevenue ? _c('span', [_vm._v(" (" + _vm._s(_vm.$_formatMoney(_vm.currentTotalRevenue * 1.2)) + ")")]) : _vm._e()])])], 1)]), _c('ul', {
    staticClass: "fItem"
  }, [_c('li', [_c('input-field', {
    attrs: {
      "label": "Aufwand (von SV)"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.value.new_aufwandkummuliert || 0)))]), _vm.currentTotalCost !== _vm.value.new_aufwandkummuliert ? _c('span', [_vm._v(" (" + _vm._s(_vm.$_formatMoney(_vm.currentTotalCost)) + ")")]) : _vm._e()])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Officekosten"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.value.new_brokosten || 0)))]), _vm.currentOfficeCost !== _vm.value.new_brokosten ? _c('span', [_vm._v(" (" + _vm._s(_vm.$_formatMoney(_vm.currentOfficeCost)) + ")")]) : _vm._e()])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Fixkosten"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.value.new_fixkosten || 0)))]), _vm.currentOverheadCost !== _vm.value.new_fixkosten ? _c('span', [_vm._v(" (" + _vm._s(_vm.$_formatMoney(_vm.currentOverheadCost)) + ")")]) : _vm._e()])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Deckungsbeitrag"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(((_vm.value.new_deckungsbeitrag_prozent || 0) * 100).toFixed(2)) + " %")]), _vm.currentContributionMargin !== _vm.value.new_deckungsbeitrag_prozent ? _c('span', [_vm._v(" (" + _vm._s((_vm.currentContributionMargin * 100).toFixed(2)) + " %)")]) : _vm._e()])])], 1), _c('li', [_c('input-field', {
    attrs: {
      "label": "Zahlung erfolgt"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_c('toggle-button', {
    attrs: {
      "color": "#82C7EB",
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: _vm.value.new_zahlungerfolgt,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "new_zahlungerfolgt", $$v);
      },
      expression: "value.new_zahlungerfolgt"
    }
  })], 1)])], 1), _vm.value.new_zahlungerfolgt ? _c('li', [_c('input-field', {
    attrs: {
      "label": "Bankauszug"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value.new_bankauszug,
      expression: "value.new_bankauszug"
    }],
    staticClass: "input",
    domProps: {
      "value": _vm.value.new_bankauszug
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.value, "new_bankauszug", $event.target.value);
      }
    }
  })])], 1) : _vm._e(), _vm.value.new_zahlungerfolgt ? _c('li', [_c('input-field', {
    attrs: {
      "label": "Bruttobetrag (Bank)"
    }
  }, [_vm.value.new_bruttobetrag !== null ? _c('money', {
    staticClass: "input",
    model: {
      value: _vm.value.new_bruttobetrag,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "new_bruttobetrag", $$v);
      },
      expression: "value.new_bruttobetrag"
    }
  }) : _c('button', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        _vm.value.new_bruttobetrag = 0;
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  })])], 1)], 1) : _vm._e(), _vm.value.new_zahlungerfolgt ? _c('li', [_c('input-field', {
    attrs: {
      "label": "Bankauszugsdatum"
    }
  }, [_c('crm-date-native', {
    model: {
      value: _vm.value.new_bankauszugsdatum,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "new_bankauszugsdatum", $$v);
      },
      expression: "value.new_bankauszugsdatum"
    }
  })], 1)], 1) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };