import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": "Vorlage auswählen"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('card', [_c('label', {
    staticClass: "label",
    attrs: {
      "for": "template-selector"
    }
  }, [_vm._v("Vorlagen:")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedTemplate,
      expression: "selectedTemplate"
    }],
    staticClass: "input",
    attrs: {
      "id": "template-selector"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedTemplate = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.templates, function (t) {
    return _c('option', {
      domProps: {
        "value": t
      }
    }, [_vm._v(_vm._s(t.name))]);
  }), 0), _c('button', {
    staticClass: "btn _clear",
    attrs: {
      "id": "add-template"
    },
    on: {
      "click": _vm.addTemplate
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })])]), _vm.selectedTemplate ? _c('card', [_c('template-configuration', {
    on: {
      "save": _vm.saveUser
    },
    model: {
      value: _vm.selectedTemplate,
      callback: function callback($$v) {
        _vm.selectedTemplate = $$v;
      },
      expression: "selectedTemplate"
    }
  })], 1) : _vm._e(), _c('div', [_c('button', {
    staticClass: "btn",
    attrs: {
      "type": "button",
      "id": "template-select"
    },
    on: {
      "click": _vm.onSelectClicked
    }
  }, [_vm._v("Auswählen")]), _c('button', {
    staticClass: "btn",
    attrs: {
      "type": "button",
      "id": "template-select-cancel"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Abbrechen")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };