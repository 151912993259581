var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm.hnNumber ? _c('div', {
          staticClass: "fr"
        }, [!_vm.feeNote.new_schalter ? _c('button-with-confirmation', {
          attrs: {
            "button-class": "_bordered",
            "confirm-label": "Bitte bestätigen: Honorarnummer sperren?"
          },
          on: {
            "click": function click($event) {
              !_vm.isLoadingHnClosing && _vm.crmCloseHn(true);
            }
          }
        }, [_vm.isLoadingHnClosing ? _c('div', {
          staticClass: "loading-spinner inline"
        }) : _vm._e(), _c('span', [_vm._v("Sperren")])]) : _c('button-with-confirmation', {
          attrs: {
            "button-class": "_bordered",
            "confirm-label": "Bitte bestätigen: Honorarnummer entsperren?"
          },
          on: {
            "click": function click($event) {
              !_vm.isLoadingHnClosing && _vm.crmCloseHn(false);
            }
          }
        }, [_vm.isLoadingHnClosing ? _c('div', {
          staticClass: "loading-spinner inline"
        }) : _vm._e(), _c('span', [_vm._v("Entsprerren")])])], 1) : _c('div', {
          staticClass: "fr"
        }, [!_vm.$features.hnImprovements ? _c('button-with-confirmation', {
          attrs: {
            "button-class": "blue",
            "confirm-label": "Bitte bestätigen: Honorarnummer vergeben?"
          },
          on: {
            "click": function click($event) {
              !_vm.isLoadingHnCreation && _vm.crmassignNumberToHonorarNote();
            }
          }
        }, [_vm.isLoadingHnCreation ? _c('div', {
          staticClass: "loading-spinner inline"
        }) : _vm._e(), _c('span', [_vm._v("Nummer vergeben!")])]) : _vm._e(), _c('button-with-confirmation', {
          attrs: {
            "button-class": "_red",
            "confirm-label": "Bitte bestätigen: Honorarnummer löschen?"
          },
          on: {
            "click": function click($event) {
              !_vm.isLoadingHnDeletion && _vm.crmDeleteHn();
            }
          }
        }, [_vm.isLoadingHnDeletion ? _c('div', {
          staticClass: "loading-spinner inline"
        }) : _vm._e(), _c('span', [_vm._v("HN löschen!")])])], 1), _c('h4', [_vm._v("HN: " + _vm._s(_vm.hnNumber || '--HN-NEU--'))])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "clear"
  }), _vm.feeNote ? _c('fee-note', {
    attrs: {
      "crm-akt": _vm.crmAkt,
      "expenses": _vm.expenses,
      "address": _vm.rechnungsZeile,
      "allow-address-modification": _vm.crmAkt.new_honorartypneu === 100000003
    },
    on: {
      "update:address": function updateAddress($event) {
        _vm.rechnungsZeile = $event;
      }
    },
    model: {
      value: _vm.feeNote,
      callback: function callback($$v) {
        _vm.feeNote = $$v;
      },
      expression: "feeNote"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "clear"
  }), !_vm.feeNote.new_schalter || _vm.$features.hnImprovements ? [_c('div', {
    staticClass: "fl"
  }, [_vm.$features.hnImprovements && !_vm.isCrmAufwandChanged ? [!_vm.feeNote.new_schalter ? _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        !_vm.isLoadingHnCalculation && _vm.finalizeHn();
      }
    }
  }, [_vm.isLoadingHnCalculation ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e(), _c('span', [_vm._v("Honorarnote erstellen")])]) : _vm._e()] : !_vm.isCrmAufwandChanged ? _c('span', [_c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        !_vm.isLoadingHnCalculation && _vm.crmCreateKostenFromAufwand();
      }
    }
  }, [_vm.isLoadingHnCalculation ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e(), _c('span', [_vm._v("Honorarnote berechnen")])])]) : _vm._e(), _vm.isCrmAufwandChanged ? _c('p', {
    staticClass: "color-red"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Achtung:  ")]), _c('span', [_vm._v("Bitte vor dem Berechnen die Aufwand Daten speichern!  ")])]) : _vm._e(), _c('div', {
    staticClass: "clear"
  }), _vm.hasEmptyHnNr && _vm.hnNumber ? _c('p', {
    staticClass: "color-red"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Achtung:  ")]), _c('span', [_vm._v("Bitte zuerst neu erstellte HN bearbeiten, HNNummer vergeben oder löschen!  ")])]) : _vm._e()], 2), _c('div', {
    staticClass: "fr"
  }, [_vm.$features.hnImprovements ? [!_vm.hnNumber ? _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        !_vm.isLoadingHnPdf && _vm.crmCreateHnPdf();
      }
    }
  }, [_vm.isLoadingHnPdf ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e(), _c('span', [_vm._v("Honorarnote PDF Vorschau")])]) : _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ShowHnPdf');
      }
    }
  }, [_vm._v("Honorarnote PDF Anzeigen")])] : [_c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        !_vm.isLoadingHnPdf && _vm.crmCreateHnPdf();
      }
    }
  }, [_vm.isLoadingHnPdf ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e(), !_vm.hnNumber ? _c('span', [_vm._v("Honorarnote PDF Vorschau")]) : _c('span', {
    staticClass: "color-blue"
  }, [_vm._v("Honorarnote PDF Speichern")])])], _vm.$features.hnImprovements ? [!_vm.hnNumber ? _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        !_vm.isLoadingHnPdf && _vm.crmCreateTpPdf();
      }
    }
  }, [_vm.isLoadingTpPdf ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e(), _c('span', [_vm._v("Tätigkeitsprotokoll PDF Vorschau")])]) : _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ShowTpPdf');
      }
    }
  }, [_vm._v("Tätigkeitsprotokoll PDF Anzeigen")])] : [_c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        !_vm.isLoadingTpPdf && _vm.crmCreateTpPdf();
      }
    }
  }, [_vm.isLoadingTpPdf ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e(), !_vm.hnNumber ? _c('span', [_vm._v("Tätigkeitsprotokoll PDF Vorschau")]) : _c('span', {
    staticClass: "color-blue"
  }, [_vm._v("Tätigkeitsprotokoll PDF Speichern")])])]], 2)] : [_c('p', {
    staticClass: "color-red"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Achtung:  ")]), _c('span', [_vm._v("HN gesperrt. Für weitere Bearbeitung entsperren!")])])], _c('div', {
    staticClass: "clear"
  }), _c('div', {
    staticClass: "tac"
  }), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ShowHnPdf",
      "name": "ShowHnPdf",
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "98%",
      "height": "98%",
      "maxHeight": 1280,
      "maxWidth": 1280
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Honorarnote"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ShowHnPdf');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block_iframe"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "embeded-iframe"
  }, [_c('embed', {
    attrs: {
      "src": _vm.hnPdfUrl,
      "width": "100%",
      "height": "100%",
      "type": "application/pdf"
    }
  })])])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ShowTpPdf",
      "name": "ShowTpPdf",
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "98%",
      "height": "98%",
      "maxHeight": 1280,
      "maxWidth": 1280
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Tätigkeitsprotokoll"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ShowTpPdf');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block_iframe"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "embeded-iframe"
  }, [_c('embed', {
    attrs: {
      "src": _vm.tpPdfUrl,
      "width": "100%",
      "height": "100%",
      "type": "application/pdf"
    }
  })])])])])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };