var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', [_vm._m(0), _c('card', [_c('table', {
    staticClass: "table fullwidth"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("HN")]), _c('th', [_vm._v("Bearbeiter")]), _c('th', [_vm._v("Datum")]), _c('th', [_vm._v("Tätigkeit")]), _c('th', [_vm._v("Anzahl")])])]), _c('tbody', _vm._l(_vm.tpPositions, function (val, key) {
    return _c('tr', {
      class: {
        'color-lightgrey': !val.active
      }
    }, [_c('td', {
      staticClass: "_small"
    }, [_vm._v(_vm._s(val.hnNumber))]), _c('td', {
      staticClass: "_small"
    }, [_vm._v(_vm._s(val.bearbeiter))]), _c('td', {
      staticClass: "_small"
    }, [_vm._v(_vm._s(val.datum))]), _c('td', [_c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(val.tatigkeit))]), _c('br'), _c('small', [_vm._v(_vm._s(val.beschreibung))])]), _c('td', {
      staticClass: "_small tac"
    }, [_vm._v(_vm._s(val.anzahl) + " " + _vm._s(val.einheit))])]);
  }), 0)])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('header', [_c('h2', {
    staticClass: "padding"
  }, [_vm._v("Tätigkeitsprotokoll")])]);
}];
export { render, staticRenderFns };