import { render, staticRenderFns } from "./CostPositions.vue?vue&type=template&id=4b358f07&scoped=true&lang=pug&"
import script from "./CostPositions.vue?vue&type=script&lang=ts&"
export * from "./CostPositions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b358f07",
  null
  
)

export default component.exports