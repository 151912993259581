var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('block-editor-input', {
    attrs: {
      "display": _vm.display,
      "label": _vm.label
    },
    scopedSlots: _vm._u([_vm.$slots.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._t("label")];
      },
      proxy: true
    } : null, {
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "input"
        }, [_vm.toggleable ? [_c('small', {
          staticClass: "bold"
        }, [_vm._v("Anzeigen:")]), _c('toggle-button', {
          attrs: {
            "height": 13,
            "width": 42,
            "color": "#82C7EB",
            "sync": true,
            "labels": {
              checked: 'JA',
              unchecked: 'NEIN'
            }
          },
          model: {
            value: _vm.template.toggled,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "toggled", $$v);
            },
            expression: "template.toggled"
          }
        })] : _vm._e(), !_vm.toggleable || _vm.template.toggled ? _c('TextareaAutosize', {
          class: {
            invalid: _vm.valid !== true
          },
          attrs: {
            "placeholder": "",
            "min-height": 24,
            "max-height": 320
          },
          model: {
            value: _vm.template.value,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "value", $$v);
            },
            expression: "template.value"
          }
        }) : _vm._e(), _vm.valid !== true ? _c('div', {
          staticClass: "invalid-description"
        }, [_vm._v(_vm._s(_vm.valid))]) : _vm._e(), _vm._t("after")], 2)];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };