var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": _vm.title
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "body"
  }, [_c('notifications-table', {
    attrs: {
      "list": _vm.messages,
      "showUntilsDate": 1
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };