import { render, staticRenderFns } from "./Stairwell.vue?vue&type=template&id=2d90400e&scoped=true&lang=pug&"
import script from "./Stairwell.vue?vue&type=script&lang=ts&"
export * from "./Stairwell.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d90400e",
  null
  
)

export default component.exports