var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "input-field"
  }, [_vm._isAdmin ? _c('v-select', {
    staticClass: "input",
    attrs: {
      "options": _vm.availableAppraisers,
      "reduce": function reduce(x) {
        return x.id;
      },
      "clearable": false
    },
    model: {
      value: _vm.selectedAppraiser,
      callback: function callback($$v) {
        _vm.selectedAppraiser = $$v;
      },
      expression: "selectedAppraiser"
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.currentAppraiserName))]), _vm.valueChanged ? _c('div', [_vm.isLoading ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _c('button', {
    staticClass: "btn _med _green",
    on: {
      "click": _vm.changeAktUser
    }
  }, [_vm._v("Ändern")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };