var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.statusNr >= 72 && _vm.statusNr <= 74 ? _c('step-progress-bar', {
    staticClass: "control",
    attrs: {
      "steps": ["GA/SN hochgeladen", "GA/SN in Verrechnung", "GA/SN versenden"],
      "num-completed": _vm.statusNr - 71
    },
    on: {
      "node-clicked": _vm.handleProgressNodeClicked
    }
  }) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };