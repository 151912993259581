var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "card",
    attrs: {
      "id": "Journal"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content ovauto"
  }, [_vm.journalData.length > 0 ? _c('table', {
    staticClass: "table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.journalData, function (it, ind) {
    return _c('tr', {
      staticClass: "utd item"
    }, [_c('td', [_vm._v(" " + _vm._s(_vm.ereignisse[it.new_ereignis]))]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(it.new_datum).format('DD.MMM.YY')))]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(it.new_datumdeseintrags).format('DD.MMM.YY')))]), _c('td', [_vm._v(_vm._s(it.new_notizlang))]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(it.modifiedon).format('DD.MMM.YY-HH:mm')))])]);
  }), 0)]) : _vm.journalData.length === 0 ? _c('div', [_c('div', {
    staticClass: "bold"
  }, [_vm._v("keine Daten")])]) : _vm._e()])]), _c('div', {
    staticClass: "card"
  }, [_vm._m(2), _c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('TipTap', {
    attrs: {
      "content": _vm.o,
      "ckey": 'message'
    }
  })], 1)]), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.saveAnnotation
    }
  }, [_vm._v("Neue Notitz speichern")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_vm._v("Journal")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('thead', [_c('th', [_vm._v("Ereignis")]), _c('th', [_vm._v("Ereignis am")]), _c('th', [_vm._v("Eintrag am")]), _c('th', [_vm._v("Notiz")]), _c('th', [_vm._v("Geändert")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Notizen")])]);
}];
export { render, staticRenderFns };