import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.starts-with.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor blkEdr noselect"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "blkEdr__topLine"
  }, [_c('div', {
    staticClass: "flex jcsb fullwidth"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Automatisch speichern"
    },
    model: {
      value: _vm.isAutoSaveGa,
      callback: function callback($$v) {
        _vm.isAutoSaveGa = $$v;
      },
      expression: "isAutoSaveGa"
    }
  }), _c('notification-button', {
    attrs: {
      "consenseNr": _vm.crmAkt.new_namekurz,
      "button-text": "Gutachten wurde als Word-Datei hochgeladen und kann versendet werden",
      "message-calc": "Ein GA/Stellunganhme f\xFCr ".concat(_vm.crmAkt.new_namekurz, " wurde vom SV fertiggestellt. Bitte noch keine Verrechnung durchf\xFChren und Akt nicht schlie\xDFen"),
      "message-no-calc": "Ein GA/Stellungnahme f\xFCr ".concat(_vm.crmAkt.new_namekurz, " wurde abgeschlossen und liegt zur Fertigstellung bereit."),
      "commission": _vm.crmAkt
    },
    on: {
      "updated": function updated($event) {
        return _vm.$emit('updated');
      },
      "setGaSent": function setGaSent($event) {
        return _vm.setGaSent();
      }
    }
  })], 1)]), _c('div', {
    staticClass: "clear"
  })]), _vm.isOutdated ? _c('div', {
    staticClass: "card error"
  }, [_vm._v("Gutachten benötigt eine neuere Version der WebApp, um editiert zu werden! Bitte laden Sie die Seite neu (Shift-F5 auf Windows, CMD-option-R in Safari, CMD-Shift-R auf MacOS), um die neue Version zu verwenden.")]) : _vm._e(), _c('tabs', {
    attrs: {
      "data": _vm.syncedContent.data,
      "selected": _vm.syncedContent.selected
    },
    on: {
      "update:selected": function updateSelected($event) {
        return _vm.$set(_vm.syncedContent, "selected", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "head",
      fn: function fn(_ref) {
        var data = _ref.data,
          idx = _ref.idx;
        return [_c('span', {
          class: {
            'color-red': data.$errors && data.$errors.length
          }
        }, [_vm._v(_vm._s(idx + 1) + " " + _vm._s(_vm.rootBlock(data).title.value))])];
      }
    }, {
      key: "add-popover",
      fn: function fn() {
        return [_c('BlockSelector', {
          directives: [{
            name: "close-popover",
            rawName: "v-close-popover"
          }],
          attrs: {
            "context": {
              akt: _vm.crmAkt,
              aggrievedParty: _vm.geschadigterData
            },
            "next-index": _vm.mainContent.data.length + 1
          },
          on: {
            "add-new-block": function addNewBlock($event) {
              return _vm.addNewBlock($event);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "tab",
      fn: function fn(_ref2) {
        var data = _ref2.data,
          idx = _ref2.idx;
        return [data.$errors && data.$errors.length ? _c('c-card', {
          staticClass: "error"
        }, [_vm._v("Fehler in folgenden Feldern müssen bereinigt werden, bevor das Gutachten gespeichert werden kann:"), _c('ul', {
          staticClass: "errorlist"
        }, _vm._l(data.$errors, function (err) {
          return _c('li', [_vm._v(_vm._s(err.blockLabel) + " - " + _vm._s(err.fieldLabel) + ": " + _vm._s(err.error))]);
        }), 0)]) : _vm._e(), _c('div', [_c('block-section', {
          attrs: {
            "no-header": "",
            "template": _vm.rootBlock(data)
          }
        }, [_c('block-editor-input', {
          attrs: {
            "label": "versenden"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_c('notification-button', {
                staticClass: "flex-grow",
                attrs: {
                  "consenseNr": _vm.crmAkt.new_namekurz,
                  "button-text": "".concat(_vm.rootBlock(data).title.value, " ").concat(_vm.fileName, " versenden"),
                  "message-calc": "Ein GA/Stellunganhme f\xFCr ".concat(_vm.crmAkt.new_namekurz, " - ").concat(_vm.rootBlock(data).title.value + ' ' + _vm.fileName, " wurde vom SV fertiggestellt. Bitte noch keine Verrechnung durchf\xFChren und Akt nicht schlie\xDFen"),
                  "message-no-calc": "Ein GA/Stellungnahme f\xFCr ".concat(_vm.crmAkt.new_namekurz, " - ").concat(_vm.rootBlock(data).title.value + ' ' + _vm.fileName, " wurde abgeschlossen und liegt zur Fertigstellung bereit."),
                  "commission": _vm.crmAkt
                },
                on: {
                  "updated": function updated($event) {
                    return _vm.$emit('updated');
                  },
                  "setGaSent": function setGaSent($event) {
                    return _vm.setGaSent();
                  }
                }
              }), _c('v-popover', {
                attrs: {
                  "offset": "0",
                  "position": "right"
                }
              }, [_c('button', {
                staticClass: "btn _clear nomargin"
              }, [_c('i', {
                staticClass: "icon-trashbin"
              })]), _c('template', {
                staticClass: "new-block-tt",
                slot: "popover"
              }, [_c('a', {
                directives: [{
                  name: "close-popover",
                  rawName: "v-close-popover"
                }],
                staticClass: "link color-red",
                on: {
                  "click": function click($event) {
                    return _vm.deleteGsSnBlock(idx);
                  }
                }
              }, [_vm._v("Sicher \"" + _vm._s(_vm.rootBlock(data).title.value) + "\" entfernen?")])])], 2), _c('v-popover', {
                attrs: {
                  "offset": "0",
                  "position": "right"
                }
              }, [_c('button', {
                staticClass: "btn _clear nomargin"
              }, [_c('i', {
                staticClass: "icon-copy"
              })]), _c('template', {
                staticClass: "new-block-tt",
                slot: "popover"
              }, [_c('a', {
                directives: [{
                  name: "close-popover",
                  rawName: "v-close-popover"
                }],
                staticClass: "link color-blue",
                on: {
                  "click": function click($event) {
                    return _vm.duplicateGsSnBlock(idx);
                  }
                }
              }, [_vm._v("Kopie von \"" + _vm._s(_vm.rootBlock(data).title.value) + "\" erstellen?")])])], 2)];
            },
            proxy: true
          }], null, true)
        }), _c('block-editor-text-input', {
          attrs: {
            "template": _vm.rootBlock(data).title
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v("Titel:"), _c('br'), _c('small', [_vm._v("(interne Bezeichnung)")])];
            },
            proxy: true
          }], null, true)
        }), _c('block-editor-text-input', {
          attrs: {
            "label": "Kurzname:",
            "template": _vm.rootBlock(data).shortName
          }
        }), _c('block-editor-text-input', {
          attrs: {
            "label": "Dateiname:",
            "template": _vm.rootBlock(data).fileName
          }
        }), _c('block-editor-toggle', {
          attrs: {
            "label": "MFG auf erster Seite",
            "label-checked": "Ja",
            "label-unchecked": "Nein",
            "template": _vm.rootBlock(data).mfgInSntexte
          }
        })], 1), _vm._l(_vm.optionalBlocks(data), function (section, bidx) {
          return _c('block-section', {
            key: bidx,
            attrs: {
              "type": data.$type,
              "template": section,
              "idx": idx,
              "akt": _vm.crmAkt
            }
          }, [section.$key.startsWith('be_') ? [_c(section.$component, {
            tag: "component",
            attrs: {
              "crmAkt": _vm.crmAkt,
              "content": section,
              "beSummary": _vm.beSummary(data),
              "blockedImages": _vm.allImageIds
            }
          })] : section.images ? [_c(section.$component, {
            tag: "component",
            attrs: {
              "crmAkt": _vm.crmAkt,
              "content": section,
              "blockedImages": _vm.allImageIds
            }
          })] : [_c(section.$component, {
            tag: "component",
            attrs: {
              "crmAkt": _vm.crmAkt,
              "content": section
            }
          })]], 2);
        }), data.$errors && data.$errors.length ? _c('c-card', {
          staticClass: "error"
        }, [_vm._v("Fehler in folgenden Feldern müssen bereinigt werden, bevor das Gutachten gespeichert werden kann:"), _c('ul', {
          staticClass: "errorlist"
        }, _vm._l(data.$errors, function (err) {
          return _c('li', [_vm._v(_vm._s(err.blockLabel) + " - " + _vm._s(err.fieldLabel) + ": " + _vm._s(err.error))]);
        }), 0)]) : _vm._e()], 2)];
      }
    }])
  }), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "ShowGaPdf",
      "name": "ShowGaPdf",
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "98%",
      "height": "98%",
      "maxHeight": 1280,
      "maxWidth": 1280
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Gutachten"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('ShowGaPdf');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block_iframe"
  }, [_c('div', {
    staticClass: "ovauto"
  }, [_c('div', {
    staticClass: "embeded-iframe"
  }, [_c('embed', {
    attrs: {
      "src": _vm.gaPdfUrl,
      "width": "100%",
      "height": "100%",
      "type": "application/pdf"
    }
  })])])])]), _c('ImageChooser'), _c('div', {
    staticClass: "spacer _vertical _large"
  }), _c('div', {
    staticClass: "spacer _vertical _large"
  }), _vm._isAdmin ? _c('div', {
    staticClass: "_opened noselect",
    attrs: {
      "id": "copyPdf"
    }
  }, [_c('v-popover', {
    attrs: {
      "offset": "0",
      "position": "right"
    }
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v("PDF zu fertige Akte kopieren")]), _c('template', {
    staticClass: "new-block-tt",
    slot: "popover"
  }, [_c('a', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "link color-red",
    on: {
      "click": function click($event) {
        return _vm.copyGaPdfToFinishedDocs();
      }
    }
  }, [_vm._v("Bestätigen")])])], 2)], 1) : _vm._e(), _c('div', {
    staticClass: "_opened noselect",
    attrs: {
      "id": "showPdf"
    },
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ShowGaPdf');
      }
    }
  }, [_c('i', {
    staticClass: "icon-file"
  }), _c('div', {
    staticClass: "bold"
  }, [_vm._v("PDF Öffnen")])]), !_vm.isOutdated ? _c('div', {
    staticClass: "_opened noselect",
    attrs: {
      "id": "makePdf"
    },
    on: {
      "click": function click($event) {
        !_vm.isCreatingPdf && _vm.createGaPdf();
      }
    }
  }, [!_vm.isCreatingPdf ? _c('i', {
    staticClass: "icon-file"
  }) : _vm.isCreatingPdf ? _c('div', {
    staticClass: "loading-spinner _small tac"
  }) : _vm._e(), _c('br'), _c('span', {
    staticClass: "bold"
  }, [_vm._v("ERSTELLE PDF")])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };