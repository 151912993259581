import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import { cloneDeep } from 'lodash';
import { flow, get, eq } from 'lodash/fp';
var section = function section(name) {
  return flow(get('type'), eq(name));
};
var migrations = {
  galngattachments: {
    2: {
      targetVersion: 3,
      migrate: function migrate(block, doc) {
        block = block.content;
        var maintable = doc.content.find(section('gamaintable'));
        var presentAtAssessment = (maintable.content.anwesendbefund || '').split('\n').map(function (x) {
          return x.trim();
        });
        var presentAtAssessmentContacts = [];
        var otherContacts = [];
        function moveContact(fname, type) {
          if (!block[fname]) return;
          if (presentAtAssessment.includes(block[fname])) {
            presentAtAssessmentContacts.push({
              type: {
                value1: type
              },
              name: block[fname],
              $type: 'person'
            });
            var idx = presentAtAssessment.indexOf(block[fname]);
            presentAtAssessment.splice(idx, 1);
          } else {
            otherContacts.push({
              type: {
                value1: type
              },
              name: block[fname],
              $type: 'person'
            });
          }
        }
        moveContact('vnehmer', 'Versicherungsnehmer');
        moveContact('ast', 'Anspruchsteller');
        moveContact('mieter', 'Mieter');
        moveContact('makler', 'Makler/Betreuer');
        moveContact('ageberref', 'Auftraggeber/Referent');
        moveContact('hv', 'Hausverwaltung');
        moveContact('fachfirma', 'Fachfirma');
        moveContact('sanierfirma', 'Sanierungsunternehmen');
        var _iterator = _createForOfIteratorHelper(presentAtAssessment),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var contact = _step.value;
            if (!contact) continue;
            presentAtAssessmentContacts.push({
              type: {
                value1: 'Andere'
              },
              name: contact,
              $type: 'person'
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        block.presentAtAssessment = presentAtAssessmentContacts;
        block.otherContacts = otherContacts;
      }
    }
  },
  gabatable: {
    2: {
      targetVersion: 3,
      migrate: function migrate(block, _, doc) {
        block.type = 'galngbatable';
        block = block.content;
        block.findings = {
          $type: 'building',
          buildingUsage: block.gebaeudenutzung,
          site: block.lage,
          structure: block.bauweise,
          roofing: block.dachdeckung,
          construction: block.errichtungsanierung,
          condition: block.gebzustandWartungAny
        };
        delete block.gebaeudenutzung;
        delete block.lage;
        delete block.bauweise;
        delete block.dachdeckung;
        delete block.errichtungsanierung;
        delete block.gebzustandWartungAny;
        var gaschadenbild = doc.content.find(section('gaschadenbild'));
        if (!gaschadenbild) {
          gaschadenbild = {
            type: 'gaschadenbild',
            version: 1,
            hide: false,
            content: {}
          };
          doc.content.splice(doc.content.findIndex(section('galngschadenhergang')) + 1, 0, gaschadenbild);
        }
        if (!gaschadenbild.content.subblocks) gaschadenbild.content.subblocks = [];
        gaschadenbild.content.subblocks = gaschadenbild.content.subblocks.concat((block.whg || []).map(function (w) {
          return {
            $type: 'flat',
            title: w.whgname,
            room: w.whgraum,
            area: w.groesseAny.groesse,
            ast: w.groesseAny,
            equipment: w.ausstattungAny,
            condition: w.whgzustandwartungAny
          };
        }));
      }
    }
  },
  galngschadenhergang: {
    2: {
      targetVersion: 3,
      migrate: function migrate(block, orig, doc) {
        block = block.content;
        var gaschadenbild = doc.content.find(section('gaschadenbild'));
        if (!gaschadenbild) {
          gaschadenbild = {
            type: 'gaschadenbild',
            version: 1,
            hide: false,
            content: {}
          };
          doc.content.splice(doc.content.findIndex(section('galngschadenhergang')) + 1, 0, gaschadenbild);
        }
        if (!gaschadenbild.content.subblocks) gaschadenbild.content.subblocks = [];
        gaschadenbild.content.subblocks.push({
          $type: 'constructionState',
          constructionState: block.zustandbefundAny,
          damagePreventionMeasures: block.schadenmassnahmenAny,
          habitability: block.bewohnbarkeitAny
        });
        gaschadenbild.content.subblocks.push({
          $type: 'text',
          title: 'Schaden am Gebäude',
          text: block.schadenamgebaude
        });
        gaschadenbild.content.subblocks.push({
          $type: 'text',
          title: 'Schaden am Inhalt',
          text: block.schadenaminhalt
        });
        gaschadenbild.content.subblocks.push({
          $type: 'text',
          title: 'Sonstige Info',
          text: block.sonstigeInfo
        });
      }
    }
  },
  galngschadenmassnahmen: {
    2: {
      targetVersion: 3,
      migrate: function migrate(block, orig, doc) {
        block = block.content;
        var gaschadenbild = doc.content.find(section('gaschadenbild'));
        if (!gaschadenbild) {
          gaschadenbild = {
            type: 'gaschadenbild',
            version: 1,
            hide: false,
            content: {}
          };
          doc.content.splice(doc.content.findIndex(section('galngschadenhergang')) + 1, 0, gaschadenbild);
        }
        if (!gaschadenbild.content.subblocks) gaschadenbild.content.subblocks = [];
        gaschadenbild.content.subblocks.push({
          $type: 'text',
          title: 'Massnahmen am Gebäude',
          text: block.massnahmengebaude
        });
        gaschadenbild.content.subblocks.push({
          $type: 'text',
          title: 'Massnahmen am Inhalt',
          text: block.massnahmeninventar
        });
        gaschadenbild.content.subblocks.push({
          $type: 'text',
          title: 'Sonstige Info (Massnahmen)',
          text: block.sonstigeInfo
        });
        var idx = doc.content.findIndex(section('galngschadenmassnahmen'));
        doc.content.splice(idx, 1);
      }
    }
  }
};
export function migrate(doc) {
  if (doc.key === 'smallGa') return doc;
  var original = cloneDeep(doc);
  var _iterator2 = _createForOfIteratorHelper(doc.content),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _migrations$block$typ;
      var block = _step2.value;
      var def = migrations === null || migrations === void 0 ? void 0 : (_migrations$block$typ = migrations[block.type]) === null || _migrations$block$typ === void 0 ? void 0 : _migrations$block$typ[block.version];
      if (!def) continue;
      def.migrate(block, original, doc);
      block.version = def.targetVersion;
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  return doc;
}