import _slicedToArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export var dot = function dot(key) {
  return function (object) {
    if (key === '') return object;
    if (!key) return undefined;
    return key.split('.').reduce(function (acc, cur) {
      return acc && acc[cur];
    }, object);
  };
};
export var dotSet = function dotSet(key, value) {
  return function (object) {
    var parts = key.split('.');
    var secondToLast = parts.slice(0, -1).reduce(function (acc, cur) {
      return acc && acc[cur];
    }, object);
    if (secondToLast) secondToLast[parts.slice(-1)[0]] = value;
  };
};
/*
 * These functions are supposed to be used with the `Object.entries` api
 */
export var entryKey = function entryKey(_ref) {
  var _ref2 = _slicedToArray(_ref, 1),
    k = _ref2[0];
  return k;
};
export var entryValue = function entryValue(_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
    _ = _ref4[0],
    v = _ref4[1];
  return v;
};
export var keys = function keys(o) {
  return Object.entries(o).map(entryKey);
};
export var values = function values(o) {
  return Object.entries(o).map(entryValue);
};
export var entries = function entries(o) {
  return Object.entries(o);
};
export var mapEntries = function mapEntries(cb) {
  return function (o) {
    return Object.fromEntries(Object.entries(o).map(cb));
  };
};
export var mapValues = function mapValues(cb) {
  return mapEntries(function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      k = _ref6[0],
      v = _ref6[1];
    return [k, cb(v)];
  });
};
export var mapKeys = function mapKeys(cb) {
  return mapEntries(function (_ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      k = _ref8[0],
      v = _ref8[1];
    return [cb(k), v];
  });
};
export var flatMapEntries = function flatMapEntries(cb) {
  return function (o) {
    return Object.fromEntries(Object.entries(o).flatMap(cb));
  };
};
export var filterEntries = function filterEntries(cb) {
  return function (o) {
    return Object.fromEntries(Object.entries(o).filter(cb));
  };
};
export var filterKeys = function filterKeys(cb) {
  return filterEntries(function (_ref9) {
    var _ref10 = _slicedToArray(_ref9, 1),
      k = _ref10[0];
    return cb(k);
  });
};
export var filterValues = function filterValues(cb) {
  return filterEntries(function (_ref11) {
    var _ref12 = _slicedToArray(_ref11, 2),
      _ = _ref12[0],
      v = _ref12[1];
    return cb(v);
  });
};
export var only = function only() {
  for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
    keys[_key] = arguments[_key];
  }
  return filterKeys(function (k) {
    return keys.includes(k);
  });
};
export var except = function except() {
  for (var _len2 = arguments.length, keys = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    keys[_key2] = arguments[_key2];
  }
  return filterKeys(function (k) {
    return !keys.includes(k);
  });
};