import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import { mapGetters, mapState, mapActions } from "vuex";
var vu = null;
export default {
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  data: function data() {
    return {
      showBewertungen: true,
      SAVE_bewertungData: [],
      bewertungData: [],
      setted: false
    };
  },
  props: ["ite"],
  computed: _objectSpread({}, mapState(["sparten"])),
  watch: {
    $route: {
      immediate: true,
      handler: function handler(nv) {
        vu.setted = false;
      }
    },
    $props: {
      handler: function handler() {
        if (!vu.setted) {
          vu.bewertungData = JSON.parse(JSON.stringify(vu.ite));
          vu.SAVE_bewertungData = JSON.parse(JSON.stringify(vu.bewertungData));
          vu.setted = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted: function mounted() {},
  methods: {}
};