import _defineProperty from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('c-card', {
    scopedSlots: _vm._u([{
      key: "before",
      fn: function fn() {
        var _class;
        return [_vm.showHeader ? _c('div', {
          staticClass: "header",
          class: (_class = {}, _defineProperty(_class, _vm.type, true), _defineProperty(_class, '-disabled', _vm.template.$active === false), _class),
          on: {
            "click": function click($event) {
              _vm.template.$show = !_vm.template.$show;
            }
          }
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.template.$title))]), _c('div', {
          staticClass: "toggles"
        }, [_vm.template.$showPdfToggle ? _c('div', [_c('small', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: {
              content: 'Ob an dieser Stelle der Inhalt zum PDF hinzugefügt werden soll.',
              placement: 'right',
              offset: 10,
              delay: {
                show: 200,
                hide: 300
              }
            },
            expression: "{content: 'Ob an dieser Stelle der Inhalt zum PDF hinzugefügt werden soll.' ,placement: 'right', offset:10, delay: {show: 200,hide: 300}}"
          }],
          staticClass: "inl bold uppercase"
        }, [_vm._v("PDF:")]), _c('toggle-button', {
          attrs: {
            "color": "#6eb595",
            "height": 14,
            "width": 48,
            "sync": true,
            "labels": {
              checked: 'JA',
              unchecked: 'NEIN'
            }
          },
          model: {
            value: _vm.template.$active,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "$active", $$v);
            },
            expression: "template.$active"
          }
        })], 1) : _vm._e(), _vm.template.$showPageBreakToggle ? _c('div', [_c('small', {
          staticClass: "inl bold"
        }, [_vm._v("Seitenumbruch:")]), _c('toggle-button', {
          attrs: {
            "color": "#6eb595",
            "height": 14,
            "width": 48,
            "sync": true,
            "labels": {
              checked: 'JA',
              unchecked: 'NEIN'
            }
          },
          model: {
            value: _vm.template.$pageBreak,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "$pageBreak", $$v);
            },
            expression: "template.$pageBreak"
          }
        })], 1) : _vm._e()])]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.template.$reset ? _c('div', {
    staticClass: "tac"
  }, [_c('v-popover', {
    attrs: {
      "offset": "0",
      "position": "right"
    }
  }, [_c('button', {
    staticClass: "btn _small"
  }, [_vm._v("Vorausfüllen")]), _c('template', {
    staticClass: "new-block-tt",
    slot: "popover"
  }, [_c('a', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "link color-red",
    on: {
      "click": function click($event) {
        return _vm.template.$reset(_vm.akt, _vm.idx);
      }
    }
  }, [_vm._v("Achtung: Eigene Eingaben werden überschrieben! Sicher?")])])], 2)], 1) : _vm._e(), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };