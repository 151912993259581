var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card noselect",
    attrs: {
      "id": "Bewertungen"
    }
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.showBewertungen = !_vm.showBewertungen;
      }
    }
  }, [_vm._v("Bewertungen")])])]), _vm.showBewertungen ? _c('div', {
    staticClass: "card-content ovauto"
  }, [_vm.bewertungData.length > 0 ? _c('span', [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.bewertungData, function (bw, ind) {
    return _c('tr', {
      staticClass: "utd item"
    }, [_c('td', [_vm._v(_vm._s(_vm.sparten.sparten[bw.new_sparte]) + " (" + _vm._s(bw.new_erledigungsjahr) + ")")]), _c('td', [_c('div', {
      staticClass: "tar"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(bw.new_honorar)))])]), _c('td', [_c('div', {
      staticClass: "tar"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(bw.new_vs_neu)))])]), _c('td', [_vm._v(_vm._s(_vm.$_formatMoney(bw.new_vs_alt)))]), _c('td', [_vm._v(_vm._s(_vm.$_formatMoney(bw.new_vs_diff)))]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(bw.new_stichtag).format('DD.MMM.YYYY')))]), _c('td', [_vm._v(_vm._s(bw.new_promille))]), _c('td', [_vm._v(_vm._s(bw.new_honorarobergrenze))])]);
  }), _c('tr', [_c('td', [_vm._v("SUMME:")]), _c('td', [_vm._v(_vm._s(_vm.$_formatMoney(_vm.$_sumUpListVals(_vm.bewertungData, 'new_honorar'))))]), _c('td', [_vm._v(_vm._s(_vm.$_formatMoney(_vm.$_sumUpListVals(_vm.bewertungData, 'new_vs_neu'))))]), _c('td', [_vm._v(_vm._s(_vm.$_formatMoney(_vm.$_sumUpListVals(_vm.bewertungData, 'new_vs_alt'))))]), _c('td', [_vm._v(_vm._s(_vm.$_formatMoney(_vm.$_sumUpListVals(_vm.bewertungData, 'new_vs_diff'))))]), _c('td', [_vm._v("-")]), _c('td', [_vm._v("-")]), _c('td', [_vm._v("-")])])], 2)])]) : _c('span', [_c('pre', [_vm._v("keine Daten")])])]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('thead', [_c('th', [_vm._v("Sparte(Jahr)")]), _c('th', [_vm._v("Honorar")]), _c('th', [_vm._v("VS Summe")]), _c('th', [_vm._v("VS S.alt")]), _c('th', [_vm._v("VS S.diff")]), _c('th', [_vm._v("Stichtag")]), _c('th', [_vm._v("Promille")]), _c('th', [_vm._v("HOG")])]);
}];
export { render, staticRenderFns };