var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('h3', [_vm._v(_vm._s(_vm.content.title.value || 'Freitext'))]), _c('block-editor-text-input', {
    attrs: {
      "label": "Titel",
      "template": _vm.content.title
    }
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "",
      "template": _vm.content.text
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };