import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('ul', {
    staticClass: "contacts"
  }, [_vm._l(_vm.contacts, function (contact, idx) {
    return _c('li', {
      key: idx,
      staticClass: "contact"
    }, [_c('div', {
      staticClass: "controls-container"
    }, [_c('block-editor-radiocheck', {
      attrs: {
        "label": "Kategorie",
        "template": contact.type,
        "options": _vm.template.contact,
        "no-annotation": ""
      }
    }), _c('div', {
      staticClass: "controls"
    }, [_c('button', {
      staticClass: "btn nomargin _clear",
      on: {
        "click": function click($event) {
          return _vm.$emit('remove', idx);
        }
      }
    }, [_c('i', {
      staticClass: "icon-trashbin"
    })])])], 1), _c('block-editor-text-input', {
      attrs: {
        "label": "Name",
        "template": contact.name
      }
    })], 1);
  }), _c('li', {
    staticClass: "add-contact"
  }, [_c('button', {
    staticClass: "btn _clear",
    on: {
      "click": function click($event) {
        return _vm.$emit('add');
      }
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })])])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };