// TODO: merge with fieldDefinition entries, there should only be one source of truth!
export var subblocks = {
  flat: {
    label: 'Wohnung'
  },
  floor: {
    label: 'Stockwerk'
  },
  house: {
    label: 'Einfamilienhaus'
  },
  room: {
    label: 'Raum'
  },
  stairwell: {
    label: 'Stiegenhaus'
  },
  constructionState: {
    label: 'Zustand & Maßnahmen'
  },
  text: {
    label: 'Freitext',
    fields: {
      title: {
        label: 'Titel',
        type: 'text'
      }
    }
  },
  images: {
    label: 'Bilder'
  }
};