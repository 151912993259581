import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "crmAufwandKontrolle"
  }, [_vm._isAdmin ? _c('div', [_c('h2', {
    staticClass: "padding"
  }, [_vm._v("Aufwand Kontrolle")]), _vm.otherAktsToDate.length > 0 ? _c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h5', [_vm._v("Andere Akte zum selben Besichtigungsdatum (selber SV) (" + _vm._s(_vm.otherAktsToDate.length) + ")")])];
      },
      proxy: true
    }], null, false, 168298013)
  }, [_c('div', {
    staticClass: "other-cases"
  }, [_c('ul', _vm._l(_vm.otherAktsToDate, function (akt) {
    return _c('li', {
      key: akt.new_namekurz
    }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$dayjs(akt.new_besichtigungsdatum).format(_vm.MAIN_DATE_FORMAT_CONDENSED)) + "   :  ")]), _c('router-link', {
      staticClass: "bold color-blue",
      attrs: {
        "to": '/crmakt/' + akt.new_namekurz
      }
    }, [_vm._v(_vm._s(akt.new_namekurz))])], 1), _c('div', [_vm._v(_vm._s((akt.new_auftraggeber || {}).name) + " / " + _vm._s((akt.new_referent || {}).fullname))])]);
  }), 0), _c('itinerary', {
    attrs: {
      "other-akts-to-date": _vm.otherAktsToDate
    }
  })], 1)]) : _vm._e(), _c('card', [_vm.insurerHourlyAmounts.length > 1 ? _c('label', [_vm._v("Betrag auswählen, um diesen als Stundenrate bei allen neuen Aufwänden zu setzen:")]) : _vm._e(), _vm._l(_vm.insurerHourlyAmounts, function (c) {
    return _c('button', {
      staticClass: "btn",
      on: {
        "click": function click($event) {
          return _vm.setDefaultHourlyRate(c.amount);
        }
      }
    }, [_vm._v(_vm._s(c.amount))]);
  }), _vm._v(_vm._s(" • ")), _c('money', {
    staticClass: "input",
    model: {
      value: _vm.customAmount,
      callback: function callback($$v) {
        _vm.customAmount = $$v;
      },
      expression: "customAmount"
    }
  }), _c('button', {
    staticClass: "btn",
    attrs: {
      "disabled": !_vm.customAmount
    },
    on: {
      "click": function click($event) {
        return _vm.setDefaultHourlyRate(_vm.customAmount);
      }
    }
  }, [_vm._v("Betrag setzen")]), _c('div', {
    staticClass: "gfAufwand"
  }, [_c('div', {
    staticClass: "ovauto g-padding -verysmall"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('th', [_vm._v("SV")]), _c('th', [_vm._v("HN")]), _c('th', [_c('a', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.selectAllUbr();
      }
    }
  }, [_vm._v("Übr.")])]), _c('th', {
    staticClass: "gfAufwand-largerColumn"
  }, [_vm._v("Tätigkeit / HN Text")]), _c('th', [_vm._v("Einheit")]), _c('th', [_vm._v("Anzahl")]), _c('th', [_vm._v("Kosten")]), _c('th', [_vm._v("Gesamt")]), _c('th')]), _c('tbody', [_vm._l(_vm.aufwandArr, function (it, ind) {
    return it ? _c('tr', {
      staticClass: "gfAufwandTr",
      class: _vm.checkGenehmigtClass(ind)
    }, [_c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it._new_bearbeiter_value,
        expression: "it._new_bearbeiter_value"
      }],
      staticClass: "mwidth130",
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.$set(it, "_new_bearbeiter_value", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      domProps: {
        "value": _vm.CONSENSE_MITARBEITER_ID
      }
    }, [_vm._v("*Consense Mitarbeiter")]), _c('option', {
      domProps: {
        "value": null
      }
    }, [_vm._v("*Sekretariat")]), _vm._l(_vm.sharedUsers, function (user, key) {
      return _vm.sharedUsers.length ? _c('option', {
        domProps: {
          "value": user
        }
      }, [_vm._v(_vm._s((_vm._svNamesByCrmContactId[user] || {}).name || 'kein Name') + "  ")]) : _vm._e();
    })], 2)]), _c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('small', {
      staticClass: "condensed"
    }, [_c('div', [_vm._v(_vm._s(it.new_honorarnr || 'neu'))])])]), _c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('v-checkbox', {
      attrs: {
        "label": ""
      },
      model: {
        value: it.new_uebernehmen,
        callback: function callback($$v) {
          _vm.$set(it, "new_uebernehmen", $$v);
        },
        expression: "it.new_uebernehmen"
      }
    })], 1), _c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('small', {
      staticClass: "italic color-grey"
    }, [_vm._v(_vm._s(_vm.tatigkeit[it.new_taetigkeit]))]), _c('small', [_vm._v(" "), _vm.checkSameTexte(ind) && it.new_taetigkeit ? _c('a', {
      staticClass: "link color-lightgrey bold",
      on: {
        "click": function click($event) {
          return _vm.prepareGfAufwand(ind);
        }
      }
    }, [_c('small', [_vm._v("Nehmen")])]) : _vm._e(), _c('span', [_vm._v(" ")]), it.new_hntexte && _vm.TAT_HN_TRANS[it.new_taetigkeit] && _vm.TAT_HN_TRANS[it.new_taetigkeit] != parseInt(it.new_hntexte) ? _c('a', {
      staticClass: "link color-green bold",
      on: {
        "click": function click($event) {
          return _vm.prepareGfTranslatedAufwand(ind);
        }
      }
    }, [_c('small', [_vm._v("Auto.")])]) : _vm._e()]), _c('small', {
      staticClass: "fr gfAufwand-date color-grey"
    }, [!it.new_datum ? _c('span', {
      staticClass: "color-red"
    }, [_vm._v("KEIN DATUM")]) : _c('span', [_vm._v(_vm._s(_vm.$dayjs(it.new_datum).format(_vm.DATE_CONDENSED)))]), _c('crm-date-native', {
      attrs: {
        "small": true,
        "whichType": 'date'
      },
      model: {
        value: it.new_datum,
        callback: function callback($$v) {
          _vm.$set(it, "new_datum", $$v);
        },
        expression: "it.new_datum"
      }
    })], 1), _c('div', {
      staticClass: "clear"
    }), _c('select', {
      staticClass: "fullwidth",
      class: {
        '-redborder': !_vm.TAT_HN_TRANS[it.new_taetigkeit] && !_vm.sevenPoints.includes(parseInt(it.new_hntexte))
      },
      domProps: {
        "value": it.new_hntexte || _vm.TAT_HN_TRANS[it.new_taetigkeit]
      },
      on: {
        "input": function input($event) {
          return _vm.updateHnTexte(it, $event.target.value);
        }
      }
    }, _vm._l(_vm.hntexte, function (option, index) {
      return _c('option', {
        domProps: {
          "value": index
        }
      }, [_vm._v(_vm._s(option))]);
    }), 0), _c('div', {
      staticClass: "clear"
    }), _c('small', {
      staticClass: "tatDetailsFlex"
    }, [_c('span', {
      staticClass: "bold"
    }, [_vm._v("DETAILS:")]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.new_taetigkeitsdetails,
        expression: "it.new_taetigkeitsdetails"
      }],
      staticClass: "tatDetailsFlex_input",
      domProps: {
        "value": it.new_taetigkeitsdetails
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "new_taetigkeitsdetails", $event.target.value);
        }
      }
    })])]), _c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('v-popover', {
      staticClass: "tac",
      attrs: {
        "offset": "3",
        "placement": "left"
      }
    }, [_c('button', {
      staticClass: "btn _small _bordered"
    }, [it.new_einheit == _vm.EINHEITKM ? _c('small', {
      staticClass: "bold"
    }, [_vm._v("km")]) : it.new_einheit == _vm.EINHEITSTUNDEN ? _c('small', {
      staticClass: "bold"
    }, [_vm._v("Stunden")]) : it.new_einheit == _vm.EINHEITPAUSCHAL ? _c('small', {
      staticClass: "bold"
    }, [_vm._v("Pauschal")]) : _c('small', {
      staticClass: "bold"
    }, [_vm._v("EINHEIT!")])]), _c('template', {
      slot: "popover"
    }, [_c('ul', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "chooseList tac"
    }, [_c('li', {
      staticClass: "chooseList_li",
      on: {
        "click": function click($event) {
          return _vm.updateUnit(it, _vm.EINHEITKM);
        }
      }
    }, [_vm._v("km")]), _c('li', {
      staticClass: "chooseList_li",
      on: {
        "click": function click($event) {
          return _vm.updateUnit(it, _vm.EINHEITSTUNDEN);
        }
      }
    }, [_vm._v("Stunden")]), _c('li', {
      staticClass: "chooseList_li",
      on: {
        "click": function click($event) {
          return _vm.updateUnit(it, _vm.EINHEITPAUSCHAL);
        }
      }
    }, [_vm._v("Pauschal")])])])], 2)], 1), _c('td', {
      staticClass: "gfAufwandTd"
    }, [it.new_einheit == _vm.EINHEITKM ? _c('span', {
      staticClass: "bold"
    }, [it.new_fahrtstrecke ? _c('small', {
      staticClass: "color-grey"
    }, [_vm._v(_vm._s(it.new_fahrtstrecke) + " km"), _c('small', [_vm._v(" ")]), it.new_fahrtstreckeverrechenbar && it.new_fahrtstreckeverrechenbar != it.new_fahrtstrecke ? _c('a', {
      staticClass: "link color-blue bold",
      on: {
        "click": function click($event) {
          return _vm.$set(it, 'new_fahrtstreckeverrechenbar', it.new_fahrtstrecke);
        }
      }
    }, [_c('small', [_vm._v("  Nehmen")])]) : _vm._e()]) : _vm._e(), _c('input', {
      staticClass: "input _small _bordered",
      attrs: {
        "type": "number",
        "step": "1",
        "min": "0",
        "max": "5000"
      },
      domProps: {
        "value": it.new_fahrtstreckeverrechenbar || it.new_fahrtstrecke
      },
      on: {
        "input": function input($event) {
          return _vm.updateFahrtstrecke(it, $event.target.value);
        }
      }
    })]) : _vm._e(), it.new_einheit == _vm.EINHEITSTUNDEN ? _c('span', {
      staticClass: "bold"
    }, [it.new_dauer ? _c('small', {
      staticClass: "color-grey"
    }, [_vm._v(_vm._s(it.new_dauer) + " h"), _c('small', [_vm._v(" ")]), it.new_dauerverrechenbar && it.new_dauerverrechenbar !== it.new_dauer ? _c('a', {
      staticClass: "link color-blue bold",
      on: {
        "click": function click($event) {
          return _vm.$set(it, 'new_dauerverrechenbar', it.new_dauer);
        }
      }
    }, [_c('small', [_vm._v("  Nehmen")])]) : _vm._e()]) : _vm._e(), _c('input', {
      staticClass: "input _small _bordered",
      attrs: {
        "type": "number",
        "step": ".25",
        "min": "0",
        "max": "100000"
      },
      domProps: {
        "value": it.new_dauerverrechenbar || it.new_dauer
      },
      on: {
        "input": function input($event) {
          return _vm.updateDauer(it, $event.target.value);
        }
      }
    })]) : _vm._e(), it.new_einheit == _vm.EINHEITPAUSCHAL ? _c('small', {
      staticClass: "bold"
    }, [it.new_kostenverrechenbar && it.new_kosten !== it.new_kostenverrechenbar ? _c('small', {
      staticClass: "color-grey"
    }, [_vm._v(_vm._s(it.new_kosten) + " €"), _c('small', [_vm._v(" ")]), _c('div', {
      staticClass: "clear"
    }), _c('a', {
      staticClass: "link color-blue bold",
      on: {
        "click": function click($event) {
          return _vm.$set(it, 'new_kostenverrechenbar', it.new_kosten);
        }
      }
    }, [_c('small', [_vm._v("  Nehmen")])])]) : _c('small'), _c('div', {
      staticClass: "clear"
    }), _c('small', [_vm._v("Anzahl = 1!")])]) : _vm._e()]), _c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('small', {
      staticClass: "color-grey"
    }, [it.new_kostendezimal ? _c('span', [_vm._v(_vm._s(_vm.$_formatMoney(it.new_kostendezimal)))]) : _c('span', [_vm._v("-")])]), _c('div', {
      staticClass: "clear"
    }), _c('span', [_c('money', {
      staticClass: "input _small condensed",
      attrs: {
        "value": it.new_kostenverrechenbar || it.new_kosten || 0
      },
      on: {
        "input": function input($event) {
          return _vm.updateKosten(it, $event);
        }
      }
    })], 1)]), _c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('small', {
      staticClass: "color-grey"
    }, [_vm._v("-")]), _c('div', {
      staticClass: "clear"
    }), _c('money', {
      staticClass: "input _small condensed",
      attrs: {
        "readonly": "",
        "value": _vm.totalForExpenseRow(it)
      }
    })], 1), _c('td', {
      staticClass: "gfAufwandTd"
    }, [_c('v-popover', {
      attrs: {
        "offset": "0",
        "position": "right"
      }
    }, [_c('a', {
      staticClass: "link color-red"
    }, [_c('i', {
      staticClass: "icon-trashbin"
    })]), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link padLink color-red",
      on: {
        "click": function click($event) {
          return _vm.crmDeleteAufwand(ind);
        }
      }
    }, [_vm._v("Sicher diesen Aufwand löschen?")])])], 2)], 1)]) : _vm._e();
  }), _c('tr', [_c('td', {
    attrs: {
      "colspan": "10"
    }
  }, [_c('div', {
    staticClass: "btn _bordered -small",
    on: {
      "click": function click($event) {
        return _vm.addNewAudwand();
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  }), _c('span', [_vm._v("  neu")])]), _c('div', {
    staticClass: "btn _bordered -small",
    on: {
      "click": function click($event) {
        return _vm.addNewAudwand(_vm.HN_BUERO);
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  }), _c('span', [_vm._v("  Büro")])]), _vm._l(_vm.insurerFlatAmounts, function (p) {
    return _c('div', {
      staticClass: "btn _bordered -small",
      on: {
        "click": function click($event) {
          return _vm.addNewAudwand(_vm.HN_REISE_SACH_GA, p.amount, _vm.crmAkt._new_mitarbeiter_value || null, true);
        }
      }
    }, [_c('i', {
      staticClass: "icon-add2"
    }), _c('span', [_vm._v("Pauschalbetrag " + _vm._s(p.amount))])]);
  })], 2)])], 2), _c('tfoot', [_c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "9"
    }
  })]), _c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', [_vm._v("Honorarnote")]), _c('td', [_vm._v(_vm._s(_vm.totalDistance) + " km")]), _c('td', [_vm._v(_vm._s(_vm.totalHours) + " h")]), _c('td'), _c('td', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.totalRevenue)))]), _c('td')]), _c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', [_vm._v("Honorarnote inkl.USt.")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.totalRevenue * 1.2)))]), _c('td')]), _c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "9"
    }
  })]), _c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', [_vm._v("∑ Personalkosten")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.totalExpenses)))]), _c('td')]), _c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', [_vm._v("Officekosten")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.officeExpenses)))]), _c('td')]), _c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', [_vm._v("Fixkosten")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.overheadExpenses)))]), _c('td')]), _c('tr', {
    staticClass: "item padding"
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', [_vm._v("Deckungsbeitrag")]), _c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', {
    staticClass: "bold"
  }, [_vm._v(_vm._s((_vm.contributionMargin * 100).toFixed(2)) + " %")]), _c('td')])])])]), _c('div', {
    staticClass: "info padding"
  }, [_c('p', [_c('small', [_vm._v("Genehmigen: Aufwand vom SV akzeptieren")]), _c('br'), _c('small', [_vm._v("Übernehmen: Aufwand in die Honorarnote schreiben")])])]), _c('div', {
    staticClass: "info padding"
  }, [_vm.hasdifferentKosten.length ? _c('p', {
    staticClass: "color-red"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Achtung:  ")]), _c('span', [_vm._v("Folgende Aufwand-Positionen haben unterschiedliche Kostenwerte:  ")]), _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.hasdifferentKosten.join(', ')))]), _c('br'), _c('small', [_vm._v("Das kann zu Falschberechungen in der Honorarnote führen!")])]) : _vm._e(), _vm.hasdifferentUnits ? _c('p', {
    staticClass: "color-red"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Achtung:  ")]), _c('span', [_vm._v("Einheitenfehler!  ")]), _c('br'), _c('small', [_vm._v("Das kann zu Falschberechungen in der Honorarnote führen!")])]) : _vm._e()])])], 2)], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };