var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": "Statusänderung"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [!_vm.saving ? _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "input-field"
  }, [_c('v-select', {
    staticClass: "input",
    attrs: {
      "value": "".concat(_vm.commission.status),
      "options": _vm._filteredStatusList,
      "label": "name",
      "reduce": function reduce(o) {
        return o.id;
      },
      "clearable": false
    },
    on: {
      "input": function input($event) {
        _vm.commission.status = parseInt($event);
      }
    }
  })], 1), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('tip-tap', {
    attrs: {
      "content": _vm.cont,
      "ckey": "message"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.onSaveClicked
    }
  }, [_vm._v("Speichern")])])]) : _c('div', {
    staticClass: "loading"
  }, [_c('span', [_c('i', {
    staticClass: "loading-spinner"
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };