import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("Grundlagen")]), _c('div', _vm._l(_vm.content.basics.value, function (el, idx) {
    var _vm$content, _vm$content$basics, _vm$content$basics$va;
    return _c('label', {
      staticClass: "input-field flex jcc aic gap-2"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.content.basics.value[idx].checked,
        expression: "content.basics.value[idx].checked"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(_vm.content.basics.value[idx].checked) ? _vm._i(_vm.content.basics.value[idx].checked, null) > -1 : _vm.content.basics.value[idx].checked
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.content.basics.value[idx].checked,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.content.basics.value[idx], "checked", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.content.basics.value[idx], "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.content.basics.value[idx], "checked", $$c);
          }
        }
      }
    }), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.content.basics.value[idx].content,
        expression: "content.basics.value[idx].content"
      }],
      staticClass: "input",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": _vm.content.basics.value[idx].content
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.content.basics.value[idx], "content", $event.target.value);
        }
      }
    }), _c('button', {
      staticClass: "btn _clear",
      on: {
        "click": function click($event) {
          return _vm.removeLine(idx);
        }
      }
    }, [_c('i', {
      staticClass: "icon-times _redp"
    })]), _c('v-popover', {
      staticClass: "configbtn _hover",
      attrs: {
        "offset": "3",
        "placement": "left"
      }
    }, [_c('button', {
      staticClass: "btn _small _clear color-red"
    }, [_c('i', {
      staticClass: "icon-sortarrows"
    })]), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('ul', {
      staticClass: "tac color-red"
    }, [idx > 0 ? _c('li', {
      staticClass: "choose-block-li padding _small"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          return _vm.moveUp(idx);
        }
      }
    }, [_vm._v("nach oben")])]) : _vm._e(), idx < ((_vm$content = _vm.content) === null || _vm$content === void 0 ? void 0 : (_vm$content$basics = _vm$content.basics) === null || _vm$content$basics === void 0 ? void 0 : (_vm$content$basics$va = _vm$content$basics.value) === null || _vm$content$basics$va === void 0 ? void 0 : _vm$content$basics$va.length) - 1 ? _c('li', {
      staticClass: "choose-block-li padding _small"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          return _vm.moveDown(idx);
        }
      }
    }, [_vm._v("nach unten")])]) : _vm._e()])])], 2)], 1);
  }), 0), _c('div', {
    staticClass: "padding"
  }), _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.addNewLine();
      }
    }
  }, [_c('span', [_vm._v("Zeile hinzufügen ")]), _c('i', {
    staticClass: "icon-add2"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };