var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor GaLngVsum"
  }, [_c('h3', [_vm._v("Versicherungssumme")]), _c('h4', [_vm._v("Gebäude")]), _c('block-editor-money', {
    attrs: {
      "label": "VS laut AG:",
      "template": _vm.content.buildingInsuranceSumByAg
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "VS nach augenscheinlicher Überprüfung:",
      "template": _vm.content.isBuildingInsuranceSumPlausible,
      "type": "radio",
      "no-annotation": ""
    }
  }), _c('h4', [_vm._v("Inhalt")]), _c('block-editor-money', {
    attrs: {
      "label": "VS laut AG:",
      "template": _vm.content.furnishmentInsuranceSumByAg
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "VS nach Überprüfung plausibel:",
      "template": _vm.content.isFurnishmentInsuranceSumPlausible,
      "type": "radio",
      "no-annotation": ""
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };