import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "crmAufwandTable"
  }, [_c('h2', {
    staticClass: "padding"
  }, [_vm._v("SV Aufwand")]), _vm.otherAktsToDate.length > 0 ? _c('card', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h5', [_vm._v("Andere Akte zum selben Besichtigungsdatum (selber SV) (" + _vm._s(_vm.otherAktsToDate.length) + ")")])];
      },
      proxy: true
    }], null, false, 168298013)
  }, [_c('div', {
    staticClass: "other-cases"
  }, [_c('ul', _vm._l(_vm.otherAktsToDate, function (akt) {
    return _c('li', {
      key: akt.new_namekurz
    }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$dayjs(akt.new_besichtigungsdatum).format(_vm.MAIN_DATE_FORMAT_CONDENSED)) + "   :  ")]), _c('router-link', {
      staticClass: "bold color-blue",
      attrs: {
        "to": '/crmakt/' + akt.new_namekurz
      }
    }, [_vm._v(_vm._s(akt.new_namekurz))])], 1), _c('div', [_vm._v(_vm._s((akt.new_auftraggeber || {}).name) + " / " + _vm._s((akt.new_referent || {}).fullname))])]);
  }), 0), _c('itinerary', {
    attrs: {
      "other-akts-to-date": _vm.otherAktsToDate
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "nopadding ovauto"
  }, [_c('table', {
    staticClass: "table nopadding"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.aufwandArr, function (it, ix) {
    return !(!it.new_taetigkeit && it.new_hntexte) ? _c('tr', [_c('td', [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it._new_bearbeiter_value,
        expression: "it._new_bearbeiter_value"
      }],
      staticClass: "mwidth130",
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.$set(it, "_new_bearbeiter_value", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }, function ($event) {
          return _vm.updateCost(it);
        }]
      }
    }, [_c('option', {
      domProps: {
        "value": _vm.CONSENSE_MITARBEITER_ID
      }
    }, [_vm._v("* Consense Mitarbeiter *")]), _c('option', {
      domProps: {
        "value": null
      }
    }, [_vm._v("* Sekretariat *")]), _vm._l(_vm.sharedUsers, function (user, key) {
      return _vm.sharedUsers.length ? _c('option', {
        domProps: {
          "value": user
        }
      }, [_vm._v(_vm._s((_vm._svNamesByCrmContactId[user] || {}).name || 'kein Name') + "  ")]) : _vm._e();
    })], 2)]), _c('td', [_c('div', {
      staticClass: "itemg2 fullwidth"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model.number",
        value: it.new_taetigkeit,
        expression: "it.new_taetigkeit",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "input fullwidth",
      class: {
        '-redBordered': !it.new_taetigkeit
      },
      on: {
        "input": function input($event) {
          return _vm.onActivityChanged(it, $event.target.value);
        },
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return _vm._n(val);
          });
          _vm.$set(it, "new_taetigkeit", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, _vm._l(_vm.fileredTatigkeit, function (option, index) {
      return _c('option', {
        domProps: {
          "value": index
        }
      }, [_vm._v(_vm._s(option))]);
    }), 0)])]), _c('td', [_c('span', [!it.new_datum ? _c('span', {
      staticClass: "color-red"
    }, [_vm._v("KEIN DATUM")]) : _c('span', [_vm._v(_vm._s(_vm.$dayjs(it.new_datum).format(_vm.DATE_CONDENSED)))]), _c('crm-date-native', {
      attrs: {
        "small": true,
        "whichType": 'date'
      },
      model: {
        value: it.new_datum,
        callback: function callback($$v) {
          _vm.$set(it, "new_datum", $$v);
        },
        expression: "it.new_datum"
      }
    })], 1)]), _c('td', [_vm.checkUnit(ix) == _vm.EINHEITKM ? _c('span', {
      staticClass: "bold"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model.number",
        value: it.new_fahrtstrecke,
        expression: "it.new_fahrtstrecke",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "input _small _bordered",
      attrs: {
        "type": "number",
        "step": "1",
        "min": "0",
        "max": "5000"
      },
      domProps: {
        "value": it.new_fahrtstrecke
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "new_fahrtstrecke", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    }), _c('span', [_vm._v("  km")])]) : _vm.checkUnit(ix) == _vm.EINHEITSTUNDEN ? _c('span', {
      staticClass: "bold"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model.number",
        value: it.new_dauer,
        expression: "it.new_dauer",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "input _small _bordered",
      attrs: {
        "type": "number",
        "step": ".25",
        "min": "0",
        "max": "5000"
      },
      domProps: {
        "value": it.new_dauer
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "new_dauer", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    }), _c('span', [_vm._v("  h")])]) : _vm.checkUnit(ix) == _vm.EINHEITPAUSCHAL ? _c('span', {
      staticClass: "bold"
    }, [_c('money', {
      staticClass: "input _small condensed",
      model: {
        value: it.new_kosten,
        callback: function callback($$v) {
          _vm.$set(it, "new_kosten", $$v);
        },
        expression: "it.new_kosten"
      }
    }), _c('small', [_vm._v("  pauschal")])], 1) : _vm._e()]), _c('td', [_c('div', {
      staticClass: "item fullwidth"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.new_taetigkeitsdetails,
        expression: "it.new_taetigkeitsdetails"
      }],
      staticClass: "input _large fullwidth condensed",
      domProps: {
        "value": it.new_taetigkeitsdetails
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "new_taetigkeitsdetails", $event.target.value);
        }
      }
    })])])]) : _vm._e();
  }), _c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_vm.aufwandArr.length === 0 ? _c('span', [_c('button', {
    staticClass: "btn _green",
    on: {
      "click": function click($event) {
        return _vm.prepareAufwand();
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  }), _c('span', [_vm._v("  Aufwände vorbereiten")])])]) : _vm._e(), _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        return _vm.addNewAufwand();
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  }), _c('span', [_vm._v("  neuen Aufwand hinzufügen")])])])])])], 2)])]), _c('p', {
    staticClass: "fr bold padding _small"
  }, [_vm._v("Summe ∑ " + _vm._s(_vm.sumHours) + " h")]), _c('div', {
    staticClass: "clear"
  }), _c('p')])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('thead', [_c('th', [_vm._v("SV")]), _c('th', [_vm._v("Tätigkeit")]), _c('th', [_vm._v("Datum")]), _c('th', [_vm._v("Anzahl")]), _c('th', [_vm._v("Details")])]);
}];
export { render, staticRenderFns };