import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card commission-header",
    class: {
      important: _vm.isImportant
    },
    style: {
      backgroundColor: _vm.userMetadata.marker,
      position: 'relative'
    }
  }, [_c('marker-selector', {
    staticClass: "color-picker-popout",
    attrs: {
      "commission-nr": _vm.commission.commissionNr
    }
  }), _c('div', {
    staticClass: "commission-title"
  }, [_c('h1', [_vm._v(_vm._s(_vm.commission.title)), _c('i', {
    staticClass: "icon-copy-link",
    on: {
      "click": _vm.copyLinkToClipboard
    }
  })]), _c('div', {
    staticClass: "menu"
  }, [_c('a', {
    staticClass: "bold lcrmink",
    attrs: {
      "target": "_blank",
      "href": 'https://consense.crm4.dynamics.com/main.aspx?etc=10009&id=' + _vm.commission.id + '&Pagetype=entityrecord'
    }
  }, [_vm._v("CRM Link")]), _vm.$features['create-timetable'] && _vm._isAdmin ? _c('button', {
    staticClass: "btn _small",
    on: {
      "click": function click($event) {
        _vm._createTimeCard(_vm.commission.commissionNr.substring(2, 10));
      }
    }
  }, [_vm._v("Zeitkarte")]) : _vm._e()])]), _c('div', {
    staticClass: "commission-subtitle"
  }, [_c('h3', {
    staticClass: "custom-title"
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editDescription,
      expression: "editDescription"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.commission.description,
      expression: "commission.description"
    }],
    ref: "descriptionInput",
    staticClass: "input",
    domProps: {
      "value": _vm.commission.description
    },
    on: {
      "blur": function blur($event) {
        _vm.editDescription = false;
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.commission, "description", $event.target.value);
      }
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editDescription,
      expression: "!editDescription"
    }],
    staticClass: "color-lightgrey",
    on: {
      "click": _vm.makeDescriptionEditable
    }
  }, [_vm._v(_vm._s(_vm.commission.description || ' '))])]), _c('div', {
    staticClass: "icons"
  }, [_vm.urgent ? _c('div', {
    staticClass: "important-annotation"
  }, [_c('i', {
    staticClass: "icon-exclamation"
  }), _c('span', [_vm._v("Hohe Priorität")])]) : _vm.importantToEstablishContact ? _c('div', {
    staticClass: "important-annotation"
  }, [_c('i', {
    staticClass: "icon-phone-outgoing-exclamation"
  }), _c('span', [_vm._v("Dringende Kontaktaufnahme erforderlich!")])]) : _vm._e()])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };