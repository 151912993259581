import "core-js/modules/es.object.keys.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "crm-details-container"
  }, [_c('div', {
    staticClass: "sidecar"
  }, [_vm.commissionData ? _c('div', {
    staticClass: "sidecar-content"
  }, [_c('check-fee-note', {
    attrs: {
      "commission": _vm.commissionData
    }
  }), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "input-field"
  }, [_vm._isAdmin ? [_c('div', {
    staticClass: "input bold"
  }, [_vm._v(_vm._s(_vm.statusName))]), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.openStatusTransitionPopup
    }
  }, [_vm._v("Status ändern")])] : _c('div', {
    staticClass: "input bold"
  }, [_vm._v(_vm._s(_vm.statusName))])], 2), _c('ChangeUserToAkt', {
    key: _vm.commissionData.id,
    attrs: {
      "aktId": _vm.commissionData.id,
      "main-user-id": (_vm.commissionData.assignedAppraiser || {}).id
    },
    on: {
      "userChanged": function userChanged($event) {
        return _vm.InitMain();
      }
    }
  }), _c('shared-with-users', {
    attrs: {
      "users": _vm.sharedUsers
    }
  })], 1)]), _c('visit-address', {
    staticClass: "card",
    attrs: {
      "value": _vm.commissionData.visitAddress,
      "commission-id": _vm.commissionData.id
    }
  }), _vm.annotations.length ? [_c('div', {
    staticClass: "icon",
    on: {
      "click": _vm.showAnnotations
    }
  }, [_c('i', {
    staticClass: "icon-message"
  })]), _c('notifications-table', {
    staticClass: "table",
    attrs: {
      "list": _vm.annotations,
      "showUntilsDate": 1
    }
  })] : _vm._e()], 2) : _vm._e()]), _c('div', {
    staticClass: "crm-details"
  }, [_c('div', {
    staticClass: "section-header-flex-fixed"
  }, [_c('v-tabs', {
    attrs: {
      "tabs": _vm.tabs
    }
  }), !_vm.setted && !_vm.notfound ? _c('span', [!_vm.onlySP ? _c('div', {
    staticClass: "card"
  }, [_vm._m(0), _vm._m(1)]) : _vm._e()]) : _vm._e(), _vm.setted && _vm.notfound ? _c('span', {
    staticClass: "wz-flexer _jcc _fww"
  }, [!_vm.onlySP ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h4', [_vm._v("Akt nicht gefunden/ nicht erlaubt")]), _c('p', [_vm._v("Bitte kontrollieren, ob Art und Nummer stimmen:"), _c('br'), _vm._v(_vm._s(_vm.aktart))])])]) : _vm._e()]) : !_vm.onlySP && _vm.setted && _vm.crmAkt && Object.keys(_vm.crmAkt).length > 0 ? _c('crm-commission-header', {
    attrs: {
      "commission": _vm.commissionData,
      "meta-data": _vm.metaAktData
    }
  }) : _vm._e()], 1), !_vm.onlySP && _vm.setted && _vm.crmAkt && Object.keys(_vm.crmAkt).length > 0 ? _c('div', {
    staticClass: "section-content"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabs.selected == 'sp',
      expression: "tabs.selected == 'sp'"
    }]
  }, [_c('SpV2', {
    attrs: {
      "consenseNr": _vm.aktart,
      "preferredNotificationTransmission": _vm.preferredNotificationTransmission
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabs.selected == 'general',
      expression: "tabs.selected == 'general'"
    }]
  }, [_c('CrmAktAllgemein', {
    attrs: {
      "commission": _vm.commissionData,
      "shared-users": _vm.sharedUsers
    },
    on: {
      "refreshCrmAkt": function refreshCrmAkt($event) {
        return _vm.InitMain();
      }
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabs.selected == 'gutachten',
      expression: "tabs.selected == 'gutachten'"
    }]
  }, [_c('BlockEditor', {
    attrs: {
      "crmAkt": _vm.crmAkt,
      "geschadigterData": _vm.geschadigterData,
      "mainContent": _vm.crmGutachten
    },
    on: {
      "saveGutachten": function saveGutachten($event) {
        return _vm.saveGutachten();
      }
    }
  })], 1), _vm.tabs.selected == 'aufwand' ? _c('div', [_c('CrmAufwandTable', {
    attrs: {
      "crm-akt": _vm.crmAkt,
      "aktId": _vm.auftragsId,
      "otherAktsToDate": _vm.otherAktsToDate,
      "mainUserId": _vm.crmAkt._new_mitarbeiter_value,
      "aktName": _vm.crmAkt.new_name,
      "aufwandArr": _vm.aufwandArr,
      "sharedUsers": _vm.sharedUsers
    }
  })], 1) : _vm._e(), _vm.tabData.hnnote && _vm.tabData.hnnote.init ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabs.selected == 'hnnote',
      expression: "tabs.selected == 'hnnote'"
    }]
  }, [_c('CrmGfAufwand', {
    attrs: {
      "crm-akt": _vm.crmAkt,
      "aktId": _vm.auftragsId,
      "otherAktsToDate": _vm.otherAktsToDate,
      "aktName": _vm.crmAkt.new_name,
      "hnData": _vm.hnData,
      "aufwandArr": _vm.aufwandArr,
      "sharedUsers": _vm.sharedUsers
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.InitMain();
      },
      "updateCaompereAufwand": function updateCaompereAufwand($event) {
        return _vm.updateCaompereAufwand();
      }
    }
  }), _c('CrmHonorarnote', {
    attrs: {
      "crmAkt": _vm.crmAkt,
      "isCrmAufwandChanged": _vm.isCrmAufwandChanged,
      "hnNumberData": _vm.hnData,
      "kostenData": _vm.kostenData,
      "aufwandData": _vm.aufwandArr,
      "aktId": _vm.auftragsId,
      "aktName": _vm.crmAkt.new_name,
      "updateTick": _vm.updateTick
    },
    on: {
      "refresh": function refresh($event) {
        return _vm.InitMain('only_hnData');
      }
    }
  })], 1) : _vm._e(), _vm.tabs.selected == 'journal' ? _c('div', [_c('CrmAktJournal', {
    attrs: {
      "crm-akt": _vm.crmAkt
    },
    model: {
      value: _vm.journalData,
      callback: function callback($$v) {
        _vm.journalData = $$v;
      },
      expression: "journalData"
    }
  })], 1) : _vm._e(), _vm.tabs.selected == 'bewertungen' ? _c('div', [_c('CrmAktBewertungen', {
    attrs: {
      "ite": _vm.bewertungData
    }
  })], 1) : _vm._e(), _vm.tabs.selected == 'notifications' ? _c('div', [_c('NotificationsList', {
    attrs: {
      "notifications": _vm.notifications,
      "crmAkt": _vm.crmAkt,
      "isMainDataChanged": _vm.isMainDataChanged
    },
    on: {
      "commission-updated": function commissionUpdated($event) {
        return _vm.InitMain();
      }
    }
  })], 1) : _vm._e(), _vm.tabData.email && _vm.tabData.email.init ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabs.selected == 'email',
      expression: "tabs.selected == 'email'"
    }]
  }, [_c('email-form', {
    attrs: {
      "crm-akt": _vm.crmAkt,
      "aggrieved-party": _vm.geschadigterData
    }
  })], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabs.selected == 'recording',
      expression: "tabs.selected == 'recording'"
    }]
  }, [_c('CrmAktRecording', {
    attrs: {
      "recordingData": _vm.recordingData,
      "crm-akt": _vm.crmAkt
    }
  })], 1)]) : _vm._e(), _c('AssignUserToAktModal', {
    attrs: {
      "sharedUsers": _vm.sharedUsers,
      "aktId": _vm.auftragsId,
      "mainUserId": _vm.crmAkt._new_mitarbeiter_value,
      "isLoadingAkt": _vm.isLoadingAkt
    },
    on: {
      "refreshUser": function refreshUser($event) {
        return _vm.InitMain('only_metaAktData');
      }
    }
  }), !_vm.onlySP && _vm.setted && !_vm.isOutdated ? _c('div', {
    staticClass: "noselect",
    class: {
      _opened: _vm.isMainDataChanged || _vm.isCrmMetaDataChanged || _vm.isCrmAufwandChanged || _vm.isHnDataChanged
    },
    attrs: {
      "id": "savebtn"
    },
    on: {
      "click": _vm.SaveAkt
    }
  }, [_c('i', {
    staticClass: "icon-upload"
  }), _c('br'), _c('span', {
    staticClass: "bold"
  }, [_vm._v("SPEICHERN")])]) : _vm._e()], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-header tac"
  }, [_c('h4', [_vm._v("Akt wird geladen...")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tac padding"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })]);
}];
export { render, staticRenderFns };