var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": "\xDCbergang zu ".concat(_vm.label)
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [!_vm.saving ? _c('div', {
    staticClass: "process-transition"
  }, [_c('p', [_vm._v("Durch die Bestätigung dieses Dialoges wird der Akt-Status auf "), _c('b', [_vm._v(_vm._s(_vm.label))]), _vm._v(" gesetzt. ")]), _c('label', [_vm._v("Hinterlassen Sie hier Anmerkungen, die für die weitere Abarbeitung wichtig sind:")]), _c('tip-tap', {
    attrs: {
      "content": _vm.cont,
      "ckey": "message"
    }
  }), _c('button', {
    staticClass: "btn save",
    on: {
      "click": _vm.onSaveClicked
    }
  }, [_vm._v("Speichern")])], 1) : _c('div', {
    staticClass: "loading"
  }, [_c('span', [_c('i', {
    staticClass: "loading-spinner"
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };