import _taggedTemplateLiteral from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import "core-js/modules/es.function.name.js";
import dayjs from "dayjs";
import { nullAsEmpty } from "@/libs/util/templateTags";
import { pick } from 'lodash/fp';
import ImagesBlock from "@/views/Crm/CrmAkt/Blocks/ImagesBlock.vue";
//! building evaluation:
import BEHead from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEHead.vue";
import BEMainTable from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEMainTable.vue";
import BECalculationTable from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BECalculationTable.vue";
import BEKeyData from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEKeyData.vue";
import BEBuildingOverview from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEBuildingOverview.vue";
import BEBuildings from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEBuildings.vue";
import BESummary from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BESummary.vue";
import BEBasics from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEBasics.vue";
import BEGeneral from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEGeneral.vue";
// import BETitelImage from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BETitelImage.vue"
var buildingEvaluatinosFields = {
  be_head: {
    $component: BEHead,
    $title: 'Gebäudebewertung Kopfteil',
    $showPageBreakToggle: false,
    $reset: true,
    title: {
      default: {
        initialValue: "Gebäudebewertung"
      }
    },
    consenseNr: {
      $alias: 'consensenr',
      default: {
        initialValue: function initialValue(_ref) {
          var akt = _ref.akt;
          return akt.new_name.substring(0, 10);
        }
      }
    },
    editor: {
      $alias: 'bearbeiter',
      default: {
        initialValue: function initialValue(_ref2) {
          var akt = _ref2.akt;
          var editor = akt.new_mitarbeiter || {};
          return nullAsEmpty(_templateObject || (_templateObject = _taggedTemplateLiteral(["", " ", " ", ""])), editor.new_titel, editor.firstname, editor.lastname);
        }
      }
    }
  },
  be_maintable: {
    $component: BEMainTable,
    $title: 'Haupttabelle',
    $showPageBreakToggle: false,
    $reset: true,
    clientAddress: {
      default: {
        initialValue: function initialValue(_ref3) {
          var akt = _ref3.akt;
          var client = akt.new_auftraggeber || {};
          var advisor = akt.new_referent || {};
          return nullAsEmpty(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", "\nzH ", " ", " ", " ", "\n", "\n", " ", ""])), client.name, advisor.salutation, advisor.new_titel, advisor.firstname, advisor.lastname, client.address1_line1, client.address1_postalcode, client.address1_city);
        }
      }
    },
    purpose: {
      // $alias: 'zweck',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    bkIndex: {
      // $alias: 'BK-Index',
      default: {
        initialValue: function initialValue() {
          return '2020=100; Dez. 2022 = 120,2 v.W.';
        }
      }
    },
    bpIndex: {
      // $alias: 'BP-Index',
      default: {
        initialValue: function initialValue() {
          return '2020=100; 3. Qu.2022 = 117,6 v.W.';
        }
      }
    },
    insuree: {
      // $alias: 'Versicherungsnehmer',
      default: {
        initialValue: function initialValue(_ref4) {
          var akt = _ref4.akt;
          var insurant = akt.new_versicherungsnehmer;
          return nullAsEmpty(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " ", " ", ""])), insurant.new_titel, insurant.new_vorname, insurant.new_versicherungsnehmer);
        }
      }
    },
    insureeAddress: {
      // $alias: 'Versicherungsnehmeradresse',
      default: {
        initialValue: function initialValue(_ref5) {
          var _insurant$new_plz;
          var akt = _ref5.akt;
          var insurant = akt.new_versicherungsnehmer;
          return nullAsEmpty(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", "\n", " ", ""], ["", "\\n", " ", ""])), insurant.new_strasse, insurant === null || insurant === void 0 ? void 0 : (_insurant$new_plz = insurant.new_plz) === null || _insurant$new_plz === void 0 ? void 0 : _insurant$new_plz.new_plz, insurant.new_ort);
        }
      }
    },
    riskAddress: {
      // $alias: 'risikoadresse',
      default: {
        initialValue: function initialValue(_ref6) {
          var _akt$new_plz;
          var akt = _ref6.akt;
          var address = akt.new_besichtigungsadresse || {};
          return nullAsEmpty(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", "\n", " ", ""])), address.new_strasse, (_akt$new_plz = akt.new_plz) === null || _akt$new_plz === void 0 ? void 0 : _akt$new_plz.new_plz, address.new_ort);
        }
      }
    },
    inspectionDate: {
      // $alias: 'date',
      default: {
        initialValue: function initialValue() {
          return "".concat(dayjs().format("DD.MM.YYYY"));
        }
      }
    },
    keyDate: {
      // $alias: 'date',
      default: {
        initialValue: function initialValue() {
          return "".concat(dayjs().format("DD.MM.YYYY"));
        }
      }
    }
  },
  be_titleimage: {
    $component: ImagesBlock,
    // $component: BETitelImage,
    $title: 'Titelbild',
    $dontShowImageConfig: true,
    $captionOfFirstImageAsHeading: true,
    $maxImages: 1,
    $showPdfToggle: false,
    $hideIfInactive: false,
    $showPageBreakToggle: false,
    images: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data', 'layout', 'quality'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.images.value);
        }
      },
      default: {
        initialValue: {
          data: [],
          quality: "high",
          layout: "be-title"
        }
      }
    }
  },
  be_basics: {
    $component: BEBasics,
    $title: 'Grundlagen',
    basics: {
      $alias: 'basics',
      default: {
        initialValue: function initialValue() {
          return [
            // { content: 'Ermittlung der Versicherungswerte (FEUER)', checked: true },
            // { content: 'Flächen lt. Norm', checked: true },
            // { content: 'Pläne teilweise beigestellt', checked: true },
            // { content: 'Alle Werte gerundet und ohne Mehrwertsteuer angegeben', checked: true },
            // { content: 'Erhebung an Ort und Stelle', checked: true },
          ];
        }
      }
    }
  },
  be_overviewimages: {
    $component: ImagesBlock,
    $title: 'Übersicht/Lage',
    // $dontShowImageConfig: true,
    // $dontShowCaption: true,
    $maxImages: 2,
    $showPdfToggle: false,
    $hideIfInactive: false,
    $showPageBreakToggle: false,
    images: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data', 'layout', 'quality'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.images.value);
        }
      },
      default: {
        initialValue: {
          data: [],
          quality: "high",
          layout: "2"
        }
      }
    }
  },
  be_summary: {
    $component: BESummary,
    $title: 'Zusammenfassung',
    // summary: {
    //     $alias: 'summary',
    //     default: { initialValue: () => { } }
    // },
    enclosedArea: {
      $alias: 'enclosedArea',
      default: {
        initialValue: function initialValue() {
          return 0;
        }
      }
    },
    extraFields: {
      $alias: 'extraFields',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    }
  },
  be_keydata: {
    $component: BEKeyData,
    $title: 'Eckdaten',
    // summary: {},
    // keyData: {},
    buildingLabel: {
      $alias: 'buildingLabel',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    buildingType: {
      $alias: 'buildingType',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    buildingYear: {
      $alias: 'buildingYear',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    lastRenovation: {
      $alias: 'lastRenovation',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    location: {
      $alias: 'location',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    usage: {
      $alias: 'usage',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    apartments: {
      $alias: 'apartments',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    business: {
      $alias: 'business',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    construction: {
      $alias: 'construction',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    monumentProtection: {
      $alias: 'monumentProtection',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    specialities: {
      $alias: 'specialities',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    property: {
      $alias: 'property',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    buildingCount: {
      $alias: 'buildingCount',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    floors: {
      $alias: 'floors',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    outside: {
      $alias: 'outside',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    }
  },
  be_planshora: {
    $component: ImagesBlock,
    $title: 'Pläne/Hora',
    // $dontShowImageConfig: true,
    // $dontShowCaption: true,
    $maxImages: 99,
    $showPdfToggle: false,
    $hideIfInactive: false,
    $showPageBreakToggle: false,
    images: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data', 'layout', 'quality'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.images.value);
        }
      },
      default: {
        initialValue: {
          data: [],
          quality: "high",
          layout: "2"
        }
      }
    }
  },
  be_buildingoverview: {
    $component: BEBuildingOverview,
    $title: 'Gebäudeübersicht',
    buildingoverview: {
      $alias: 'buildingoverview',
      default: {
        initialValue: function initialValue() {
          return [];
        }
      }
    }
  },
  be_buildings: {
    $component: BEBuildings,
    $title: 'Gebäude',
    introText: {
      $alias: 'introText',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    buildings: {
      $alias: 'buildings',
      default: {
        initialValue: function initialValue() {
          return [];
        }
      }
    }
  },
  be_calculationtable: {
    $component: BECalculationTable,
    $title: 'Berechnungstabelle',
    tableTitle: {
      $alias: 'tableTitle',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    calculationtable: {
      $alias: 'calculationtable',
      default: {
        initialValue: function initialValue() {
          return [];
        }
      }
    },
    calculationresults: {
      $alias: 'calculationresults',
      default: {
        initialValue: function initialValue() {}
      }
    }
  },
  // be_images: {
  //     $component: BECalculationTable,
  //     $title: 'Berechnungstabelle',
  //     tableTitle: {
  //         $alias: 'tableTitle',
  //         default: { initialValue: () => '' }
  //     },
  //     calculationtable: {
  //         $alias: 'calculationtable',
  //         default: { initialValue: () => [] }
  //     },
  // },
  be_bpibki: {
    $component: ImagesBlock,
    $title: 'BPI/BKI',
    // $dontShowImageConfig: true,
    // $dontShowCaption: true,
    $maxImages: 2,
    $showPdfToggle: false,
    $hideIfInactive: false,
    $showPageBreakToggle: false,
    images: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data', 'layout', 'quality'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.images.value);
        }
      },
      default: {
        initialValue: {
          data: [],
          quality: "high",
          layout: "2"
        }
      }
    }
  },
  be_general: {
    $component: BEGeneral,
    $title: 'Grundlagen',
    general: {
      $alias: 'general',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    buildings: {
      $alias: 'buildings',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    valuation: {
      $alias: 'valuation',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    attachments: {
      $alias: 'attachments',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    }
  }
};
var buildingEvaluation = ["be_head", "be_maintable", "be_titleimage", "be_basics", "be_overviewimages", "be_keydata", "be_planshora",
// "images", //!
// "images", //!
// "be_buildingoverview",
"be_buildings", "be_summary", "be_calculationtable", "be_general", "be_bpibki", "attachments"];
// const buildingEvaluation = ["be_head","be_maintable", "images", "be_buildings", "be_calculationtable"]
// const buildingEvaluation = ["be_head", "be_maintable", "be_buildings", "be_calculationtable"]
export { buildingEvaluatinosFields, buildingEvaluation };