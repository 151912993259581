var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('h2', {
    staticClass: "padding"
  }, [_vm._v("Kostenpositionen")]), _c('card', [_c('table', {
    staticClass: "table fullwidth"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "_small tac"
  }, [_vm._v("HN")]), _c('th', {
    staticClass: "_small tac"
  }, [_vm._v("Pos.")]), _c('th', {
    staticClass: "_small tac"
  }, [_vm._v("Anzahl")]), _c('th', {
    staticClass: "_large"
  }, [_vm._v("Tätigkeit")]), _c('th', {
    staticClass: "_mid"
  }, [_vm._v("Kosten")]), _c('th', {
    staticClass: "_mid"
  }, [_vm._v("Gesamt")])])]), _c('tbody', [_vm._l(_vm.positions, function (val, key) {
    return _c('tr', {
      class: {
        'color-lightgrey': !val.active
      }
    }, [_c('td', {
      staticClass: "_small tac"
    }, [_vm._v(_vm._s(val.hnNumber || '_neu_'))]), _c('td', {
      staticClass: "_small tac"
    }, [_vm._v(_vm._s(val.pos))]), _c('td', {
      staticClass: "_small tac"
    }, [_vm._v(_vm._s(val.anzahl) + " " + _vm._s(val.einheit))]), _c('td', {
      staticClass: "_large"
    }, [_vm._v(_vm._s(val.tatigkeit))]), _c('td', {
      staticClass: "_mid"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(val.kosten)))]), _c('td', {
      staticClass: "_mid"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(val.gesamt)))])]);
  }), _c('tr', [_c('td', {
    attrs: {
      "colspan": "7"
    }
  })]), _vm.withUst ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }), _c('td', {
    staticClass: "_mid"
  }, [_vm._v("Kosten:")]), _c('td', {
    staticClass: "_mid"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.sumOfPositions)))]), _c('td')]) : _vm._e(), _vm.withUst ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }), _c('td', {
    staticClass: "_mid"
  }, [_vm._v("USt.:")]), _c('td', {
    staticClass: "_mid"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.sumOfPositions * _vm.ust)))]), _c('td')]) : _vm._e(), _c('tr', {
    staticClass: "bold"
  }, [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }), _c('td', {
    staticClass: "_mid"
  }, [_vm._v("Summe:")]), _c('td', {
    staticClass: "_mid"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.sumOfPositions * (1 + _vm.ust))))]), _c('td')])], 2)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };