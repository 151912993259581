var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor gaAtts"
  }, [_c('h3', [_vm._v("GRUNDLAGEN")]), _c('attachments-section', {
    attrs: {
      "content": _vm.content,
      "show-attachment-summary-toggle": false
    }
  }), _c('present-at-assessment-section', {
    attrs: {
      "content": _vm.content
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };