import { render, staticRenderFns } from "./GaLngVsum.vue?vue&type=template&id=8399a8ae&scoped=true&lang=pug&"
import script from "./GaLngVsum.vue?vue&type=script&lang=ts&"
export * from "./GaLngVsum.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8399a8ae",
  null
  
)

export default component.exports