var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "link",
    on: {
      "click": _vm.onClick
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };