var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._t("label", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2), _c('div', {
    staticClass: "input"
  }, [_c('span', [_c('toggle-button', {
    attrs: {
      "width": 64,
      "height": 18,
      "color": "#5e88ad",
      "labels": {
        checked: _vm.labelChecked,
        unchecked: _vm.labelUnchecked
      }
    },
    model: {
      value: _vm.template.value,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "value", $$v);
      },
      expression: "template.value"
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };