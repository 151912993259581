import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _typeof from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _slicedToArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _taggedTemplateLiteral from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.unscopables.flat.js";
import GaHead from "@/views/Crm/CrmAkt/Blocks/GaHead.vue";
import dayjs from "dayjs";
import { nullAsEmpty } from "@/libs/util/templateTags";
import GaMainTable from "@/views/Crm/CrmAkt/Blocks/GaMainTable.vue";
import crmstuff from "@/assets/json/crmstuff";
import GaBaTableV2 from "@/views/Crm/CrmAkt/Blocks/GaBaTableV2.vue";
import GaLngBaTable from "@/views/Crm/CrmAkt/Blocks/GaLngBaTable.vue";
import GaSbTable from "@/views/Crm/CrmAkt/Blocks/GaSbTable.vue";
import GaCostTable from "@/views/Crm/CrmAkt/Blocks/GaCostTable.vue";
import GaAttachments from "@/views/Crm/CrmAkt/Blocks/GaAttachments.vue";
import GaLngShTable from "@/views/Crm/CrmAkt/Blocks/GaLngShTable.vue";
import { entryKey, filterKeys, mapEntries } from "@consense/util/functional";
import { capitalize, entries, flow, get, mapValues, omit, pick } from 'lodash/fp';
import { cloneDeep, isFunction, set } from 'lodash';
import SnMainTable from "@/views/Crm/CrmAkt/Blocks/Stellungnahme/SnMainTable.vue";
import SnTexts from "@/views/Crm/CrmAkt/Blocks/Stellungnahme/SnTexts.vue";
import ImagesBlock from "@/views/Crm/CrmAkt/Blocks/ImagesBlock.vue";
import GaLngSuTable from "@/views/Crm/CrmAkt/Blocks/GaLngSuTable.vue";
import GaLngMnTable from "@/views/Crm/CrmAkt/Blocks/GaLngMnTable.vue";
import GaLngAttchmts from "@/views/Crm/CrmAkt/Blocks/GaLngAttchmts.vue";
import GaLngNotices from "@/views/Crm/CrmAkt/Blocks/GaLngNotices.vue";
import GaLngVsum from "@/views/Crm/CrmAkt/Blocks/GaLngVsum.vue";
import GaSanierungsmassnahmen from '@/views/Crm/CrmAkt/Blocks/GaSanierungsmassnahmen.vue';
import GaSchadenbild from "@/views/Crm/CrmAkt/Blocks/GaSchadenbild.vue";
import Flat from "@/views/Crm/CrmAkt/Blocks/Schadenbild/Flat.vue";
import ConstructionState from "@/views/Crm/CrmAkt/Blocks/Schadenbild/ConstructionState.vue";
import Text from "@/views/Crm/CrmAkt/Blocks/Schadenbild/Text.vue";
import House from "@/views/Crm/CrmAkt/Blocks/Schadenbild/House.vue";
import Stairwell from "@/views/Crm/CrmAkt/Blocks/Schadenbild/Stairwell.vue";
import Room from "@/views/Crm/CrmAkt/Blocks/Schadenbild/Room.vue";
import Floor from "@/views/Crm/CrmAkt/Blocks/Schadenbild/Floor.vue";
import Images from "@/views/Crm/CrmAkt/Blocks/Schadenbild/Images.vue";
import CauseOfDamagesCombined from "@/views/Crm/CrmAkt/Blocks/ShortOpinion/CauseOfDamagesCombined.vue";
import Attachments from "@/views/Crm/CrmAkt/Blocks/ShortOpinion/Attachments.vue";
//! building evaluation:
// import BEHead from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEHead.vue"
// import BEMainTable from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEMainTable.vue"
// import BECalculationTable from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BECalculationTable.vue"
// import BEBuildings from "@/views/Crm/CrmAkt/Blocks/BuildingEvaluation/BEBuildings.vue"
import { buildingEvaluatinosFields, buildingEvaluation } from "./fieldDefinitions/buildingEvaluatinos";
var roomFields = {
  area: {
    default: {
      initialValue: ''
    }
  },
  ast: {
    default: {
      initialValue: {
        options: [{
          name: "nachvollziehbar",
          value: "nachvollziehbar"
        }, {
          name: "nicht nachvollziehbar",
          value: "nichtnachvollziehbar"
        }],
        selected: "empty",
        annotation: "" // anmerkung
      }
    }
  },
  equipment: {
    default: {
      initialValue: {
        options: [{
          name: "Einfach",
          value: "einfach"
        }, {
          name: "Standard",
          value: "standard"
        }, {
          name: "Exklusiv - Hochwertig",
          value: "exklusivhochwertig"
        }],
        selected: "empty",
        annotation: "" // anmerkung
      }
    }
  },
  condition: {
    default: {
      initialValue: {
        options: [{
          name: "neuwertig",
          value: "neuwertig"
        }, {
          name: "gut",
          value: "gut"
        }, {
          name: "abgenützt",
          value: "abgenutzt"
        }, {
          name: "desolat",
          value: "desolat"
        }],
        selected: "empty",
        annotation: "" // anmerkung
      }
    }
  }
};
var presentAtAssessment = {
  $type: 'choice',
  $arity: 'n',
  $initialValue: [],
  person: {
    type: {
      default: {
        initialValue: {
          value1: ''
        }
      }
    },
    name: {
      default: {
        initialValue: ''
      }
    }
  }
};
var attachments = {
  $alias: {
    get: function get(block, into) {
      Object.assign(into, pick(['data'])(block));
    },
    set: function set(into, block) {
      Object.assign(into, block.attachments.value);
    }
  },
  default: {
    initialValue: {
      data: []
    }
  }
};
var assessment = {
  $component: GaLngBaTable,
  $title: 'Befundaufnahme',
  $version: 3,
  findings: {
    $type: 'choice',
    $arity: '1',
    $initialValue: 'building',
    building: {
      buildingUsage: {
        default: {
          initialValue: {
            value1: '',
            value2: '',
            annotation: ''
          }
        }
      },
      site: {
        default: {
          initialValue: {
            values1: [],
            annotation: ''
          }
        }
      },
      structure: {
        default: {
          initialValue: {
            value1: '',
            value2: '',
            annotation: ''
          }
        }
      },
      roofing: {
        default: {
          initialValue: {
            values1: [],
            annotation: ''
          }
        }
      },
      construction: {
        default: {
          initialValue: ''
        }
      },
      condition: {
        default: {
          initialValue: {
            options: [{
              name: "neuwertig",
              value: "neuwertig"
            }, {
              name: "gut",
              value: "gut"
            }, {
              name: "abgenützt",
              value: "abgenutzt"
            }, {
              name: "desolat",
              value: "desolat"
            }],
            selected: "",
            annotation: "" // anmerkung
          }
        }
      }
    },
    area: {
      site: {
        default: {
          initialValue: ''
        }
      },
      circumstancesOnSite: {
        default: {
          initialValue: ''
        }
      }
    }
  }
};
var schadenbildDefinition = {
  $component: GaSchadenbild,
  $title: 'Schadenbild & Sanierungsmaßnahmen',
  $version: 3,
  subblocks: {
    $type: 'choice',
    $arity: 'n',
    $initialValue: [],
    flat: _objectSpread({
      $component: Flat,
      title: {
        default: {
          initialValue: ''
        }
      },
      room: {
        default: {
          initialValue: {
            value1: '',
            value2: '',
            value3: '',
            annotation: ''
          }
        }
      }
    }, roomFields),
    house: _objectSpread({
      $component: House,
      title: {
        default: {
          initialValue: ''
        }
      },
      room: {
        default: {
          initialValue: {
            value1: '',
            value2: ''
          }
        }
      }
    }, roomFields),
    stairwell: _objectSpread({
      $component: Stairwell,
      title: {
        default: {
          initialValue: ''
        }
      },
      room: {
        default: {
          initialValue: {
            value1: ''
          }
        }
      }
    }, pick(['equipment', 'condition'])(roomFields)),
    room: _objectSpread({
      $component: Room,
      title: {
        default: {
          initialValue: ''
        }
      },
      room: {
        default: {
          initialValue: {
            value1: '',
            value2: ''
          }
        }
      }
    }, roomFields),
    floor: _objectSpread({
      $component: Floor,
      title: {
        default: {
          initialValue: ''
        }
      },
      room: {
        default: {
          initialValue: {
            value1: '',
            value2: ''
          }
        }
      }
    }, roomFields),
    constructionState: {
      $component: ConstructionState,
      constructionState: {
        default: {
          initialValue: {
            options: [{
              name: "unsaniert",
              value: "unsaniert"
            }, {
              name: "teilsaniert",
              value: "teilsaniert"
            }, {
              name: "saniert",
              value: "saniert"
            }],
            selected: "",
            annotation: "" // anmerkung
          }
        }
      },
      damagePreventionMeasures: {
        default: {
          initialValue: {
            options: [{
              name: "besprochen",
              value: "besprochen"
            }, {
              name: "notwendig",
              value: "notwendig"
            }, {
              name: "nicht notwendig",
              value: "nichtnotwendig"
            }, {
              name: "veranlasst",
              value: "veranlasst"
            }],
            selected: "",
            annotation: "" // anmerkung
          }
        }
      },
      habitability: {
        default: {
          initialValue: {
            options: [{
              name: "bewohnbar",
              value: "bewohnbar"
            }, {
              name: "eingeschränkt bewohnbar",
              value: "eingeschranktbewohnbar"
            }, {
              name: "unbewohnbar",
              value: "unbewohnbar"
            }, {
              name: "(nicht im PDF anzeigen)",
              value: ""
            }],
            selected: "",
            annotation: "" // anmerkung
          }
        }
      }
    },
    text: {
      $component: Text,
      title: {
        default: {
          initialValue: ''
        }
      },
      text: {
        default: {
          initialValue: '<p></p>'
        }
      }
    },
    images: {
      $component: Images,
      $maxImages: 6,
      images: {
        default: {
          initialValue: {
            data: [],
            quality: "low",
            layout: "2"
          }
        }
      }
    }
  }
};
var fieldDefinitions = _objectSpread({
  root: {
    title: {
      default: {
        initialValue: function initialValue(_ref) {
          var type = _ref.type;
          switch (type) {
            case 'smallGa':
              return 'Gutachten';
            case 'largeGa':
              return 'Gutachten';
            case 'shortOpinion':
              return 'Kurzgutachten';
            case 'smallSn':
              return 'Stellungnahme';
            case 'buildingEvaluation':
              return 'Gebäudebewertung';
          }
        }
      }
    },
    shortName: {
      default: {
        initialValue: 'GA'
      }
    },
    fileName: {
      default: {
        readonly: true,
        initialValue: function initialValue(_ref2, data, idx) {
          var akt = _ref2.akt;
          return "".concat(data.shortName, "(").concat(idx + 1, ")-").concat(akt.new_name.substring(0, 10), ".pdf");
        }
      }
    },
    isMfgSmall: {
      default: {
        initialValue: false
      }
    },
    mfgInSntexte: {
      default: {
        initialValue: false
      }
    },
    isSent: {
      default: {
        initialValue: false
      }
    },
    isSvLocked: {
      default: {
        initialValue: false
      }
    }
  },
  gahead: {
    $component: GaHead,
    $title: 'Kopf',
    $showPageBreakToggle: false,
    $reset: true,
    placeAndDate: {
      $alias: 'date',
      default: {
        initialValue: function initialValue() {
          return "Wien, am ".concat(dayjs().format("DD.MM.YYYY"));
        }
      }
    },
    clientAddress: {
      $alias: 'headInfo1',
      default: {
        initialValue: function initialValue(_ref3) {
          var akt = _ref3.akt;
          var client = akt.new_auftraggeber || {};
          var advisor = akt.new_referent || {};
          return nullAsEmpty(_templateObject || (_templateObject = _taggedTemplateLiteral(["", "\nzH ", " ", " ", " ", "\n", "\n", " ", ""])), client.name, advisor.salutation, advisor.new_titel, advisor.firstname, advisor.lastname, client.address1_line1, client.address1_postalcode, client.address1_city);
        }
      }
    },
    consenseNr: {
      $alias: 'consensenr',
      default: {
        initialValue: function initialValue(_ref4) {
          var akt = _ref4.akt;
          return akt.new_name.substring(0, 10);
        }
      }
    },
    editor: {
      $alias: 'bearbeiter',
      default: {
        initialValue: function initialValue(_ref5) {
          var akt = _ref5.akt;
          var editor = akt.new_mitarbeiter || {};
          return nullAsEmpty(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", " ", " ", ""])), editor.new_titel, editor.firstname, editor.lastname);
        }
      }
    }
  },
  gamaintable: {
    $component: GaMainTable,
    $title: 'Haupttabelle',
    $showPageBreakToggle: false,
    $reset: true,
    title: {
      $alias: 'titelfeld',
      default: {
        initialValue: function initialValue(_ref6) {
          var _akt$new_auftraggeber;
          var akt = _ref6.akt;
          if ((akt === null || akt === void 0 ? void 0 : (_akt$new_auftraggeber = akt.new_auftraggeber) === null || _akt$new_auftraggeber === void 0 ? void 0 : _akt$new_auftraggeber.accountid) === 'c0f18a79-0ac2-e311-b7b1-d89d67630d54') return {
            value1: 'Expertise'
          };
          return {
            value1: 'Gutachterliche Stellungnahme'
          };
        }
      }
    },
    insurant: {
      $alias: 'versicherungsnehmer',
      default: {
        initialValue: function initialValue(_ref7) {
          var akt = _ref7.akt;
          var insurant = akt.new_versicherungsnehmer;
          return nullAsEmpty(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["", " ", " ", ""])), insurant.new_titel, insurant.new_vorname, insurant.new_versicherungsnehmer);
        }
      }
    },
    damageNumber: {
      $alias: 'schadennummer',
      default: {
        initialValue: function initialValue(_ref8) {
          var akt = _ref8.akt;
          return akt.new_schadennummer || '';
        }
      }
    },
    claimant: {
      $alias: 'anspruchsteller',
      default: {
        initialValue: function initialValue(_ref9) {
          var aggrievedParty = _ref9.aggrievedParty;
          return aggrievedParty.map(function (i) {
            var g = i.new_geschaedigter;
            return nullAsEmpty(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["", " ", " ", ""])), g.new_vorname, g.new_nachname, g.new_firmenname ? "(".concat(g.new_firmenname, ")") : g.new_versicherungsnehmer);
          }).join('\n');
        }
      }
    },
    jobNumber: {
      $alias: 'auftragsnummer',
      default: {
        initialValue: function initialValue(_ref10) {
          var akt = _ref10.akt;
          return akt.new_auftragsnummer || '';
        }
      }
    },
    inspectionLocation: {
      $alias: 'besichtigungsort',
      default: {
        initialValue: function initialValue(_ref11) {
          var _akt$new_plz;
          var akt = _ref11.akt;
          return nullAsEmpty(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["", " ", " ", ""])), (_akt$new_plz = akt.new_plz) === null || _akt$new_plz === void 0 ? void 0 : _akt$new_plz.new_plz, akt.new_besichtigungsadresse.new_ort, akt.new_besichtigungsadresse.new_strasse);
        }
      }
    },
    damageDate: {
      $alias: 'schadendatum',
      default: {
        initialValue: function initialValue(_ref12) {
          var akt = _ref12.akt;
          return akt.new_schadendatum ? dayjs(akt.new_schadendatum).format("DD.MM.YYYY") : "";
        }
      }
    },
    policyNumber: {
      $alias: 'polizzennummer',
      default: {
        initialValue: function initialValue(_ref13) {
          var akt = _ref13.akt;
          return akt.new_polizzennummer || "";
        }
      }
    },
    jobDate: {
      $alias: 'auftragvom',
      default: {
        initialValue: function initialValue(_ref14) {
          var akt = _ref14.akt;
          return akt.new_auftragsdatum ? dayjs(akt.new_auftragsdatum).format("DD.MM.YYYY") : "";
        }
      }
    },
    visitedAt: {
      $alias: 'besichtigtam',
      default: {
        initialValue: function initialValue(_ref15) {
          var akt = _ref15.akt;
          return akt.new_besichtigungsdatum ? dayjs(akt.new_besichtigungsdatum).format("DD.MM.YYYY") : "";
        }
      }
    },
    branch: {
      $alias: 'sparte',
      default: {
        initialValue: function initialValue(_ref16) {
          var akt = _ref16.akt;
          return crmstuff.sparten.details[akt.new_spartendetails];
        }
      }
    },
    consensenr: {
      $alias: 'consensenr',
      default: {
        initialValue: function initialValue(_ref17) {
          var akt = _ref17.akt;
          return akt.new_name.substring(0, 10);
        }
      }
    },
    editor: {
      $alias: 'bearbeiter',
      default: {
        initialValue: function initialValue(_ref18) {
          var akt = _ref18.akt;
          var editor = akt.new_mitarbeiter || {};
          return nullAsEmpty(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["", " ", " ", ""])), editor.new_titel, editor.firstname, editor.lastname);
        }
      }
    },
    assessed: {
      $alias: 'bewertetAny',
      default: {
        initialValue: {
          options: [{
            name: "Gebäude",
            value: "gebaude"
          }, {
            name: "Inhalt",
            value: "inhalt"
          }, {
            name: "Sonst.",
            value: "sonstiges"
          }],
          checked: [],
          annotation: "" // anmerkung
        }
      }
    },
    regressAvailable: {
      $alias: 'regressmoglichkeitAny',
      $label: 'Regressmöglichkeit',
      $validation: {
        inputAnnotation: function inputAnnotation(value) {
          if (value && value.length > 200) return 'Anmerkung zu lang! Maximal 200 Zeichen erlaubt';
          return true;
        }
      },
      default: {
        initialValue: {
          options: [{
            name: "Ja",
            value: "ja"
          }, {
            name: "Nein",
            value: "nein"
          }],
          selected: "",
          annotation: "" // anmerkung
        }
      }
    },
    existingDamages: {
      $alias: 'vorschadenAny',
      $label: 'Vorschaden',
      $validation: {
        inputAnnotation: function inputAnnotation(value) {
          if (value && value.length > 200) return 'Anmerkung zu lang! Maximal 200 Zeichen erlaubt';
          return true;
        }
      },
      default: {
        initialValue: {
          options: [{
            name: "Ja",
            value: "ja"
          }, {
            name: "Nein",
            value: "nein"
          }],
          selected: "",
          annotation: "" // anmerkung
        }
      },
      selected: "",
      annotation: "" // anmerkung
    },
    causeOfDamage: {
      $alias: 'schadenursache',
      $label: 'Schadenursache',
      $validation: {
        input: function input(value) {
          if (value && value.length > 300) return 'Feld zu lang! Maximal 300 Zeichen erlaubt';
          return true;
        }
      },
      default: {
        initialValue: ''
      }
    },
    originalValue: {
      $alias: 'neuwert',
      default: {
        initialValue: ''
      }
    },
    presentValue: {
      $alias: 'zeitwert',
      default: {
        initialValue: ''
      }
    },
    noOriginalValueCalculation: {
      $alias: 'neuwertnichtubernehmen',
      default: {
        initialValue: false
      }
    },
    includeUstInDamages: {
      $alias: 'schadensummeAny',
      default: {
        initialValue: {
          options: [{
            name: "inkl. USt.",
            value: "incmwst"
          }, {
            name: "exkl. USt.",
            value: "excmwst"
          }],
          selected: ""
        }
      }
    },
    wantsCompensation: {
      $alias: 'abloseAny',
      default: {
        initialValue: {
          options: [{
            name: "JA, AST an Ablöse interessiert",
            value: "ja"
          }, {
            name: "Nein",
            value: "nein"
          }, {
            name: "Details siehe Gutachten",
            value: "siehega"
          }],
          selected: ""
        }
      }
    },
    accountNumber: {
      $alias: 'kontonr',
      default: {
        initialValue: ''
      }
    },
    showAccountNumber: {
      $alias: 'kontonranzeigen',
      default: {
        initialValue: false
      }
    }
  },
  so_assessment: _objectSpread(_objectSpread({}, assessment), {}, {
    $showPdfToggle: true,
    $hideIfInactive: true
  }),
  galngbatable: assessment,
  gabatable: {
    $component: GaBaTableV2,
    $title: 'Befundaufnahme',
    $version: 2,
    buildingUsage: {
      $alias: 'gebaeudenutzung',
      default: {
        initialValue: {
          value1: '',
          value2: '',
          annotation: ''
        }
      }
    },
    site: {
      $alias: 'lage',
      default: {
        initialValue: {
          value1: '',
          annotation: ''
        }
      }
    },
    structure: {
      $alias: 'bauweise',
      default: {
        initialValue: {
          value1: '',
          value2: '',
          annotation: ''
        }
      }
    },
    roofing: {
      $alias: 'dachdeckung',
      default: {
        initialValue: {
          value1: '',
          annotation: ''
        }
      }
    },
    construction: {
      $alias: 'errichtungsanierung',
      default: {
        initialValue: ''
      }
    },
    condition: {
      $alias: 'gebzustandWartungAny',
      default: {
        initialValue: {
          options: [{
            name: "neuwertig",
            value: "neuwertig"
          }, {
            name: "gut",
            value: "gut"
          }, {
            name: "abgenützt",
            value: "abgenutzt"
          }, {
            name: "desolat",
            value: "desolat"
          }],
          selected: "",
          annotation: "" // anmerkung
        }
      }
    },
    whg: [{
      name: {
        $alias: 'whgname',
        default: {
          initialValue: ''
        }
      },
      room: {
        $alias: 'whgraum',
        default: {
          initialValue: {
            value1: '',
            value2: '',
            value3: '',
            annotation: ''
          }
        }
      },
      area: {
        $alias: 'groesseAny.groesse',
        default: {
          initialValue: ''
        }
      },
      ast: {
        $alias: 'groesseAny',
        default: {
          initialValue: {
            options: [{
              name: "nachvollziehbar",
              value: "nachvollziehbar"
            }, {
              name: "nicht nachvollziehbar",
              value: "nichtnachvollziehbar"
            }],
            selected: "empty",
            annotation: "" // anmerkung
          }
        }
      },
      equipment: {
        $alias: 'ausstattungAny',
        default: {
          initialValue: {
            options: [{
              name: "Einfach",
              value: "einfach"
            }, {
              name: "Standard",
              value: "standard"
            }, {
              name: "Exklusiv - Hochwertig",
              value: "exklusivhochwertig"
            }],
            selected: "empty",
            annotation: "" // anmerkung
          }
        }
      },
      condition: {
        $alias: 'whgzustandwartungAny',
        default: {
          initialValue: {
            options: [{
              name: "neuwertig",
              value: "neuwertig"
            }, {
              name: "gut",
              value: "gut"
            }, {
              name: "abgenützt",
              value: "abgenutzt"
            }, {
              name: "desolat",
              value: "desolat"
            }],
            selected: "empty",
            annotation: "" // anmerkung
          }
        }
      }
    }]
  },
  gaschadenbild: schadenbildDefinition,
  galngschadenbild: _objectSpread(_objectSpread({}, schadenbildDefinition), {}, {
    $title: 'Schadenbild'
  }),
  gasanierungsmassnahmen: {
    $component: GaSanierungsmassnahmen,
    $title: 'Sanierungsmaßnahmen',
    $version: 3,
    renovationMeasures: {
      default: {
        initialValue: '<p></p>'
      }
    }
  },
  gasbtable: {
    $component: GaSbTable,
    $title: 'Schadenhergang - Schadenursache',
    damageDescription: {
      $alias: 'schadenhergangursache',
      default: {
        initialValue: '<p></p>'
      }
    },
    constructionState: {
      $alias: 'zustandbefundAny',
      default: {
        initialValue: {
          options: [{
            name: "unsaniert",
            value: "unsaniert"
          }, {
            name: "teilsaniert",
            value: "teilsaniert"
          }, {
            name: "saniert",
            value: "saniert"
          }],
          selected: "",
          annotation: "" // anmerkung
        }
      }
    },
    damagePreventionMeasures: {
      $alias: 'schadenmassnahmenAny',
      default: {
        initialValue: {
          options: [{
            name: "besprochen",
            value: "besprochen"
          }, {
            name: "notwendig",
            value: "notwendig"
          }, {
            name: "nicht notwendig",
            value: "nichtnotwendig"
          }, {
            name: "veranlasst",
            value: "veranlasst"
          }],
          selected: "",
          annotation: "" // anmerkung
        }
      }
    },
    habitability: {
      $alias: 'bewohnbarkeitAny',
      default: {
        initialValue: {
          options: [{
            name: "bewohnbar",
            value: "bewohnbar"
          }, {
            name: "eingeschränkt bewohnbar",
            value: "eingeschranktbewohnbar"
          }, {
            name: "unbewohnbar",
            value: "unbewohnbar"
          }, {
            name: "(nicht im PDF anzeigen)",
            value: ""
          }],
          selected: "",
          annotation: "" // anmerkung
        }
      }
    },
    buildingDamages: {
      $alias: 'schadenamgebaude',
      default: {
        initialValue: '<p></p>'
      }
    },
    furnishmentDamages: {
      $alias: 'schadenaminhalt',
      default: {
        initialValue: '<p></p>'
      }
    },
    additionalInfo: {
      $alias: 'sonstigeInfo',
      default: {
        initialValue: '<p></p>'
      }
    }
  },
  gacosttable: {
    $component: GaCostTable,
    $title: 'Kostentabelle',
    $showPdfToggle: true,
    calculatePresentValue: {
      default: {
        initialValue: true
      }
    },
    aggregateUsedForEstablishedAmount: {
      default: {
        initialValue: {
          options: [{
            name: "Neuwert",
            value: "new-amount"
          }, {
            name: "Zeitwert",
            value: "time-deprecated-amount"
          }, {
            name: "Ablöse",
            value: 'compensatory-amount'
          }],
          selected: "compensatory-amount"
        }
      }
    },
    reportedAmount: {
      default: {
        initialValue: ''
      }
    },
    reportedVia: {
      $reduce: function $reduce(x) {
        return x.id;
      },
      default: {
        initialValue: {
          value1: ''
        }
      }
    },
    category: {
      $reduce: function $reduce(x) {
        return x.id;
      },
      default: {
        initialValue: {
          value1: ''
        }
      }
    },
    description: {
      default: {
        initialValue: ''
      }
    },
    data: {
      default: {
        initialValue: [{
          title: 'Gebäudeschaden',
          values: [{
            bez: '',
            neuwert: 0,
            prozent: 0,
            zeitwert: 0,
            ablose: 0
          }]
        }, {
          title: 'Inhaltschaden - Inventar',
          values: [{
            bez: '',
            neuwert: 0,
            prozent: 0,
            zeitwert: 0,
            ablose: 0
          }]
        }, {
          title: 'Kosten für Gebäude / Inhalt (Abbruch-, Aufräum-, Reinigungskosten, etc.)',
          values: [{
            bez: '',
            neuwert: 0,
            prozent: 0,
            zeitwert: 0,
            ablose: 0
          }]
        }]
      }
    }
  },
  attachments: {
    $component: GaAttachments,
    $title: 'Anhänge',
    attachments: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.attachments.value);
        }
      },
      default: {
        initialValue: {
          data: []
        }
      }
    }
  },
  snMainTable: {
    $component: SnMainTable,
    $title: 'Haupttabelle',
    $reset: true,
    title: {
      $alias: 'titelfeld',
      default: {
        initialValue: {
          value1: 'Stellungnahme'
        }
      }
    },
    editor: {
      $alias: 'bearbeiter',
      default: {
        initialValue: function initialValue(_ref19) {
          var akt = _ref19.akt;
          var editor = akt.new_mitarbeiter || {};
          return nullAsEmpty(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["", " ", " ", ""])), editor.new_titel, editor.firstname, editor.lastname);
        }
      }
    },
    damageNumber: {
      $alias: 'schadennummer',
      default: {
        initialValue: function initialValue(_ref20) {
          var akt = _ref20.akt;
          return akt.new_schadennummer || '';
        }
      }
    },
    insurant: {
      $alias: 'versicherungsnehmer',
      default: {
        initialValue: function initialValue(_ref21) {
          var akt = _ref21.akt;
          var insurant = akt.new_versicherungsnehmer || {};
          return nullAsEmpty(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["", " ", " ", ""])), insurant.new_titel, insurant.new_vorname, insurant.new_versicherungsnehmer);
        }
      }
    },
    policyNumber: {
      $alias: 'polizzennummer',
      default: {
        initialValue: function initialValue(_ref22) {
          var akt = _ref22.akt;
          return akt.new_polizzennummer || '';
        }
      }
    },
    orderNumber: {
      $alias: 'auftragsnummer',
      default: {
        initialValue: function initialValue(_ref23) {
          var akt = _ref23.akt;
          return akt.new_auftragsnummer || '';
        }
      }
    },
    inspectionLocation: {
      $alias: 'besichtigungsort',
      default: {
        initialValue: function initialValue(_ref24) {
          var _akt$new_plz2;
          var akt = _ref24.akt;
          var address = akt.new_besichtigungsadresse || {};
          return nullAsEmpty(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["", " ", ", ", ""])), (_akt$new_plz2 = akt.new_plz) === null || _akt$new_plz2 === void 0 ? void 0 : _akt$new_plz2.new_plz, address.new_ort, address.new_strasse);
        }
      }
    },
    damageDate: {
      $alias: 'schadendatum',
      default: {
        initialValue: function initialValue(_ref25) {
          var akt = _ref25.akt;
          return akt.new_schadendatum ? dayjs(akt.new_schadendatum).format('DD.MM.YYYY') : '';
        }
      }
    },
    inspectionDate: {
      default: {
        initialValue: function initialValue(_ref26) {
          var akt = _ref26.akt;
          return akt.new_besichtigungsdatum ? dayjs(akt.new_besichtigungsdatum).format("DD.MM.YYYY") : "";
        }
      }
    },
    includeUstInDamages: {
      $alias: 'schadensummeAny',
      default: {
        initialValue: {
          options: [{
            name: "inkl. USt.",
            value: "incmwst"
          }, {
            name: "exkl. USt.",
            value: "excmwst"
          }],
          selected: ""
        }
      }
    }
  },
  snTexts: {
    $component: SnTexts,
    $title: 'Stellungnahme Text',
    title: {
      default: {
        initialValue: ''
      }
    },
    text: {
      $alias: 'sntexte',
      default: {
        initialValue: '<p></p>'
      }
    }
  },
  snCostTable: {
    $component: GaCostTable,
    $title: 'Kostentabelle',
    $showPdfToggle: true,
    calculatePresentValue: {
      default: {
        initialValue: true
      }
    },
    aggregateUsedForEstablishedAmount: {
      default: {
        initialValue: {
          options: [{
            name: "Neuwert",
            value: "new-amount"
          }, {
            name: "Zeitwert",
            value: "time-deprecated-amount"
          }, {
            name: "Ablöse",
            value: 'compensatory-amount'
          }],
          selected: "compensatory-amount"
        }
      }
    },
    reportedAmount: {
      default: {
        initialValue: ''
      }
    },
    reportedVia: {
      $reduce: function $reduce(x) {
        return x.id;
      },
      default: {
        initialValue: {
          value1: ''
        }
      }
    },
    category: {
      $reduce: function $reduce(x) {
        return x.id;
      },
      default: {
        initialValue: {
          value1: ''
        }
      }
    },
    description: {
      default: {
        initialValue: ''
      }
    },
    data: {
      default: {
        initialValue: [{
          title: 'Gebäudeschaden',
          values: [{
            bez: '',
            neuwert: 0,
            prozent: 0,
            zeitwert: 0,
            ablose: 0
          }]
        }, {
          title: 'Inhaltschaden - Inventar',
          values: [{
            bez: '',
            neuwert: 0,
            prozent: 0,
            zeitwert: 0,
            ablose: 0
          }]
        }, {
          title: 'Kosten für Gebäude / Inhalt (Abbruch-, Aufräum-, Reinigungskosten, etc.)',
          values: [{
            bez: '',
            neuwert: 0,
            prozent: 0,
            zeitwert: 0,
            ablose: 0
          }]
        }]
      }
    }
  },
  imagesMax: {
    $component: ImagesBlock,
    $title: 'Bilder (max. 6 Bilder)',
    $maxImages: 6,
    $showPdfToggle: true,
    $hideIfInactive: true,
    images: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data', 'layout', 'quality'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.images.value);
        }
      },
      default: {
        initialValue: {
          data: [],
          quality: "low",
          layout: "2"
        }
      }
    }
  },
  images: {
    $component: ImagesBlock,
    $title: 'Bilder',
    $maxImages: 99,
    $showPdfToggle: true,
    $hideIfInactive: true,
    images: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data', 'layout', 'quality'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.images.value);
        }
      },
      default: {
        initialValue: {
          data: [],
          quality: "low",
          layout: "2"
        }
      }
    }
  },
  summaryImages: {
    $component: ImagesBlock,
    $title: 'Übersichtsbilder (max. 2 Bilder)',
    $maxImages: 2,
    $showPdfToggle: true,
    $hideIfInactive: true,
    images: {
      $alias: {
        get: function get(block, into) {
          Object.assign(into, pick(['data', 'layout', 'quality'])(block));
        },
        set: function set(into, block) {
          Object.assign(into, block.images.value);
        }
      },
      default: {
        initialValue: {
          data: [],
          quality: "low",
          layout: "2"
        }
      }
    }
  },
  so_causeOfDamagesCombined: {
    $component: CauseOfDamagesCombined,
    $title: 'Schadenhergang & Schadenursache',
    $version: 3,
    damageDescription: {
      $alias: 'schadenhergangursache',
      default: {
        initialValue: '<p></p>'
      }
    }
  },
  galngschadenhergang: {
    $component: GaLngShTable,
    $title: 'Schadenhergang',
    $version: 3,
    damageDescription: {
      $alias: 'schadenhergangursache',
      default: {
        initialValue: '<p></p>'
      }
    }
  },
  galngschadenursache: {
    $component: GaLngSuTable,
    $title: "Schadenursache",
    causeOfDamage: {
      $alias: 'schadenursache',
      default: {
        initialValue: ''
      }
    }
  },
  galngschadenmassnahmen: {
    $component: GaLngMnTable,
    $title: 'Maßnahmen',
    measuresOnBuilding: {
      $alias: 'massnahmengebaude',
      default: {
        initialValue: '<p></p>'
      }
    },
    measuresOnFurniture: {
      $alias: 'massnahmeninventar',
      default: {
        initialValue: '<p></p>'
      }
    },
    additionalInfo: {
      $alias: 'sonstigeInfo',
      default: {
        initialValue: '<p></p>'
      }
    }
  },
  so_attachments: {
    $component: Attachments,
    $title: 'Grundlagen - Anlagen',
    $version: 1,
    presentAtAssessment: presentAtAssessment,
    attachments: attachments
  },
  galngattachments: {
    $component: GaLngAttchmts,
    $title: 'Grundlagen - Anlagen',
    $version: 3,
    $showPageBreakToggle: false,
    presentAtAssessment: presentAtAssessment,
    otherContacts: {
      $type: 'choice',
      $arity: 'n',
      $initialValue: [],
      person: {
        type: {
          default: {
            initialValue: {
              value1: ''
            }
          }
        },
        name: {
          default: {
            initialValue: ''
          }
        }
      }
    },
    showOtherContactsInPdf: {
      default: {
        initialValue: false
      }
    },
    attachments: attachments,
    showAttachmentSummary: {
      default: {
        initialValue: false
      }
    },
    insurant: {
      $alias: 'vnehmer',
      default: {
        toggleable: true,
        initialValue: function initialValue(_ref27) {
          var akt = _ref27.akt;
          var insurant = akt.new_versicherungsnehmer || {};
          return nullAsEmpty(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["", " ", " ", ""])), insurant.new_titel, insurant.new_vorname, insurant.new_versicherungsnehmer);
        }
      }
    },
    claimant: {
      $alias: 'ast',
      default: {
        toggleable: true,
        initialValue: ''
      }
    },
    tenant: {
      $alias: 'mieter',
      default: {
        toggleable: true,
        initialValue: ''
      }
    },
    realtor: {
      $alias: 'makler',
      default: {
        toggleable: true,
        initialValue: function initialValue(_ref28) {
          var akt = _ref28.akt;
          var realtor = akt.new_makler || {};
          return nullAsEmpty(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["", " ", " ", " ", ""])), realtor.salutation, realtor.new_titel, realtor.firstname, realtor.lastname);
        }
      }
    },
    client: {
      $alias: 'ageberref',
      default: {
        toggleable: true,
        initialValue: function initialValue(_ref29) {
          var akt = _ref29.akt;
          var client = akt.new_referent || {};
          return nullAsEmpty(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["", " ", " ", " ", ""])), client.salutation, client.new_titel, client.firstname, client.lastname);
        }
      }
    },
    houseManagement: {
      $alias: 'hv',
      default: {
        toggleable: true,
        initialValue: ''
      }
    },
    specialistCompany: {
      $alias: 'fachfirma',
      default: {
        toggleable: true,
        initialValue: ''
      }
    },
    sanitationCompany: {
      $alias: 'sanierfirma',
      default: {
        toggleable: true,
        initialValue: ''
      }
    },
    annotation: {
      $alias: 'anmerkung',
      default: {
        initialValue: ''
      }
    }
  },
  galngnotices: {
    $component: GaLngNotices,
    $showPdfToggle: true,
    $initialToggleState: true,
    $hideIfInactive: true,
    $title: 'Besondere Hinweise',
    importantNotice: {
      $alias: 'besonderehinweise',
      default: {
        initialValue: '<p></p>'
      }
    }
  },
  galngvsum: {
    $component: GaLngVsum,
    $showPdfToggle: true,
    $initialToggleState: false,
    $hideIfInactive: true,
    $title: 'Versicherungssumme',
    buildingInsuranceSumByAg: {
      $alias: 'gebaeudevslautag',
      default: {
        initialValue: ''
      }
    },
    isBuildingInsuranceSumPlausible: {
      $alias: 'gebaeudevsplausibel',
      default: {
        initialValue: {
          options: [{
            name: "keine Angabe",
            value: "kA"
          }, {
            name: "plausibel",
            value: "plausibel"
          }, {
            name: "nicht plausibel,",
            value: "nichtplausibel"
          }],
          selected: "kA"
        }
      }
    },
    furnishmentInsuranceSumByAg: {
      $alias: 'inhaltvslautag',
      default: {
        initialValue: ''
      }
    },
    isFurnishmentInsuranceSumPlausible: {
      $alias: 'inhaltvsplausibel',
      default: {
        initialValue: {
          options: [{
            name: "keine Angabe",
            value: "kA"
          }, {
            name: "plausibel",
            value: "plausibel"
          }, {
            name: "nicht plausibel,",
            value: "nichtplausibel"
          }],
          selected: "kA"
        }
      }
    }
  }
}, buildingEvaluatinosFields);
var opinionDefinitions = {
  smallGa: ["gahead", "gamaintable", "gabatable", "imagesMax", "gasbtable", "imagesMax", "gacosttable", "images", "attachments"],
  largeGa: ["gahead", "gamaintable", "galngattachments", "galngbatable", "summaryImages", "galngschadenhergang", "galngschadenbild", "galngschadenursache", "gasanierungsmassnahmen", "imagesMax", "gacosttable", "imagesMax", "galngvsum", "galngnotices", "images"],
  shortOpinion: ["gahead", "gamaintable", "so_attachments", "so_assessment", "summaryImages", "so_causeOfDamagesCombined", "gaschadenbild", "imagesMax", "gacosttable", "imagesMax", "galngvsum", "galngnotices", "images"],
  smallSn: ["gahead", "snMainTable", "snTexts", "snCostTable", "images", "attachments"],
  buildingEvaluation: buildingEvaluation
};
export function newOpinionTemplate(type, context, idx) {
  console.log('new template for', type);
  var value = ['root'].concat(opinionDefinitions[type]).map(function (b) {
    return newFieldData(b, context, idx);
  });
  value.$type = type;
  return value;
}
var valueFromTemplate = function valueFromTemplate(content, context, data, idx) {
  return function (_ref30) {
    var _ref31 = _slicedToArray(_ref30, 2),
      k = _ref31[0],
      t = _ref31[1];
    if (t.$type === 'choice') {
      if (t.$arity === '1') return [k, content[k] !== undefined ? _objectSpread(_objectSpread(_objectSpread({}, filterKeys(isMeta)(content[k])), filterKeys(isMeta)(t[content[k].$type])), flow(filterKeys(isNotMeta), mapEntries(valueFromTemplate(content[k], context, data, idx)))(t[content[k].$type])) : getInitialValue(t[t.$initialValue], context, data, idx)];else if (t.$arity === 'n') return [k, content[k] !== undefined ? content[k].map(function (e) {
        return _objectSpread(_objectSpread(_objectSpread({}, filterKeys(isMeta)(e)), filterKeys(isMeta)(t[e.$type])), flow(filterKeys(isNotMeta), mapEntries(valueFromTemplate(e, context, data, idx)))(t[e.$type]));
      }) : t.$initialValue.map(function (x) {
        return getInitialValue(t[x], context, data, idx);
      })];
    }
    if (Array.isArray(t)) {
      if (!content[k]) return [k, []];else return [k, content[k].map(function (e, idx) {
        return mapEntries(valueFromTemplate(e, context, data, idx))(t[0]);
      })];
    }
    var value;
    if (content && content[k] !== undefined) value = content[k];else if (content && typeof t.$alias === 'string') value = get(t.$alias)(content);else if (content && _typeof(t.$alias) === 'object') t.$alias.get(content, value = {});
    if (value === undefined) value = getInitialValue(t.default.initialValue, context, data, idx);
    // Workaround since we can't currently map nested data (no alias definition possible)
    if (_typeof(value) === 'object' && 'anmerkung' in value) value = _objectSpread({
      annotation: value.anmerkung
    }, omit(['anmerkung'])(value));
    return [k, _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, pick(['$validation', '$reduce'])(t)), pick(['readonly', 'display', 'toggleable'])(t.default)), t.default.toggleable && {
      toggled: content[k] !== undefined ? content[k].toggled : content["show".concat(capitalize(t.$alias))]
    }), {}, {
      value: value
    })];
  };
};
export function opinionTemplateFromData(data, context, idx) {
  var template = [];
  template.$type = data.key;
  var root = _objectSpread({
    $key: 'root',
    $show: true
  }, filterKeys(isMeta)(fieldDefinitions.root));
  var _iterator = _createForOfIteratorHelper(flow(filterKeys(isNotMeta), entries)(fieldDefinitions.root)),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var f = _step.value;
      var _valueFromTemplate = valueFromTemplate(data, context, root, idx)(f),
        _valueFromTemplate2 = _slicedToArray(_valueFromTemplate, 2),
        k = _valueFromTemplate2[0],
        v = _valueFromTemplate2[1];
      root[k] = v;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  template.push(root);
  var _iterator2 = _createForOfIteratorHelper(data.content),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _block$$showPageBreak, _definition$$showPage, _block$$pageBreak;
      var block = _step2.value;
      var definition = fieldDefinitions[block.type];
      if (isFunction(definition)) definition = definition(context);
      if (!definition) continue;
      template.push(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
        $key: block.type,
        $show: !block.hide,
        $version: block.version || definition.$version || 2
      }, (definition.$version || 2) < block.version && {
        $newVersionRequired: true
      }), definition.$showPdfToggle !== undefined && {
        $active: block.content.active || definition.$initialToggleState || false
      }), {}, {
        $showPageBreakToggle: (_block$$showPageBreak = block.$showPageBreakToggle) !== null && _block$$showPageBreak !== void 0 ? _block$$showPageBreak : true
      }, ((_definition$$showPage = definition.$showPageBreakToggle) !== null && _definition$$showPage !== void 0 ? _definition$$showPage : true) && {
        $pageBreak: (_block$$pageBreak = block.$pageBreak) !== null && _block$$pageBreak !== void 0 ? _block$$pageBreak : false
      }), filterKeys(isMeta)(definition)), definition.$reset && {
        $reset: makeReset(block.type, context, idx)
      }), flow(filterKeys(isNotMeta), mapEntries(valueFromTemplate(block.content, context, {}, idx)))(definition)));
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  template.$newVersionRequired = template.some(function (t) {
    return t.$newVersionRequired || false;
  });
  return template;
}
var withoutMetaKeys = flow(entryKey, isNotMeta);
var valuesOnly = flow(filterKeys(isNotMeta), mapValues(get('value')));
export function opinionTemplateToData(template) {
  var mapValue = function mapValue(definition) {
    return function (_ref32) {
      var _ref33 = _slicedToArray(_ref32, 2),
        k = _ref33[0],
        v = _ref33[1];
      return [definition[k].$alias || k, v.value];
    };
  };
  var root = flow(filterKeys(isNotMeta), mapEntries(mapValue(fieldDefinitions['root'])))(template[0]);
  var content = template.slice(1).map(function (block) {
    var _block$$showPageBreak2;
    var transformValue = function transformValue(content, definition) {
      return function (_ref34) {
        var _ref35 = _slicedToArray(_ref34, 2),
          k = _ref35[0],
          v = _ref35[1];
        if (definition[k].$type === 'choice') {
          if (definition[k].$arity === '1') {
            content[k] = {
              $type: v.$type
            };
            entries(v).filter(withoutMetaKeys).forEach(transformValue(content[k], definition[k][v.$type]));
          } else if (definition[k].$arity === 'n') {
            content[k] = v.map(function (e) {
              var o = {
                $type: e.$type
              };
              entries(e).filter(withoutMetaKeys).forEach(transformValue(o, definition[k][e.$type]));
              return o;
            });
          }
          return;
        }
        if (Array.isArray(definition[k])) {
          var ndef = definition[k][0]; // TODO: fix, definition shouldn't actually be an array
          content[k] = v.map(function (nv) {
            var ndata = {};
            entries(nv).forEach(function (_ref36) {
              var _ref37 = _slicedToArray(_ref36, 2),
                k = _ref37[0],
                v = _ref37[1];
              var value = v.value;
              // Workaround since we can't currently map nested data (no alias definition possible)
              if (_typeof(value) === 'object' && 'annotation' in value) value = _objectSpread({
                anmerkung: value.annotation
              }, omit(['annotation'])(value));
              set(ndata, ndef[k].$alias || k, value);
            });
            if (k === 'whg') {
              ndata.groesseAny.groesse = nv.area.value; // TODO: fix. This is a workaround for nested data in select
            }
            return ndata;
          });
          return;
        }
        if (_typeof(definition[k].$alias) === 'object') {
          definition[k].$alias.set(content, block);
          return;
        }
        var value = v.value;
        if (_typeof(value) === 'object' && 'annotation' in value && block.$version < 3) value = _objectSpread({
          anmerkung: value.annotation
        }, omit(['annotation'])(value));
        var key = definition[k].$alias || k;
        content[key] = value;
        if (v.toggled) content["show".concat(capitalize(definition[k].$alias))] = v.toggled;
      };
    };
    var definition = fieldDefinitions[block.$key];
    var content = {};
    if (block.$active !== undefined) content.active = block.$active;
    if (block.$maxImages !== undefined) content.max = block.$maxImages;
    entries(block).filter(withoutMetaKeys).forEach(transformValue(content, definition));
    return _objectSpread(_objectSpread({
      hide: !block.$show,
      type: block.$key,
      version: block.$version
    }, ((_block$$showPageBreak2 = block.$showPageBreakToggle) !== null && _block$$showPageBreak2 !== void 0 ? _block$$showPageBreak2 : true) && {
      $pageBreak: block.$pageBreak
    }), {}, {
      content: content
    });
  });
  return _objectSpread(_objectSpread({
    key: template.$type
  }, root), {}, {
    content: content,
    type: 'gatype',
    version: 3
  });
}
var makeValueMapper = function makeValueMapper(context, val, idx) {
  return function (d) {
    return _objectSpread(_objectSpread(_objectSpread({
      $validation: d.$validation,
      $reduce: d.$reduce,
      readonly: d.default.readonly !== undefined ? d.default.readonly : false,
      display: d.default.display !== undefined ? d.default.display : true
    }, omit(['toggleable'])(d.default)), d.default.toggleable && {
      toggled: true
    }), {}, {
      value: d.default.initialValue !== undefined ? getInitialValue(d.default.initialValue, context, val, idx) : ''
    });
  };
};
export function makeFieldData(bname, fname, subname) {
  var definition = fieldDefinitions[bname][fname];
  var _valueMapper = makeValueMapper(null, null, null);
  var valueMapper = flow(filterKeys(isNotMeta), mapValues(_valueMapper));
  if (definition.$type === 'choice') {
    if (subname) return _objectSpread(_objectSpread({
      $type: subname
    }, filterKeys(isMeta)(definition[subname])), valueMapper(definition[subname]));
    if (definition.$arity === '1') return _objectSpread({
      $type: definition.$initialValue
    }, valueMapper(definition[subname || definition.$initialValue]));else if (definition.$arity === 'n') return definition.$initialValue.map(function (key) {
      return _objectSpread({
        $type: key
      }, valueMapper(definition[key]));
    });
  } else {
    definition = definition; // satisfy type checker
    return _valueMapper(definition);
  }
}
export function newFieldData(bname, context, idx) {
  var _block$$showPageBreak3;
  var block = fieldDefinitions[bname];
  if (isFunction(block)) block = block(context);
  var val = {};
  var valueMapper = makeValueMapper(context, val, idx);
  val.$key = bname;
  val.$title = block.$title;
  val.$component = block.$component;
  val.$show = true;
  val.$active = block.$initialToggleState !== undefined ? block.$initialToggleState : true;
  val.$showPdfToggle = block.$showPdfToggle;
  val.$showPageBreakToggle = (_block$$showPageBreak3 = block.$showPageBreakToggle) !== null && _block$$showPageBreak3 !== void 0 ? _block$$showPageBreak3 : true;
  val.$hideIfInactive = block.$hideIfInactive;
  val.$version = block.$version || 2;
  if (val.$showPageBreakToggle) val.$pageBreak = false;
  if (block.$maxImages) val.$maxImages = block.$maxImages;
  if (block.$reset) val.$reset = makeReset(bname, context, idx);
  var _iterator3 = _createForOfIteratorHelper(Object.entries(block).filter(withoutMetaKeys)),
    _step3;
  try {
    var _loop = function _loop() {
      var _step3$value = _slicedToArray(_step3.value, 2),
        k = _step3$value[0],
        definition = _step3$value[1];
      if (definition.$type === 'choice') {
        var mapChoiceElement = flow(filterKeys(isNotMeta), mapValues(valueMapper));
        if (definition.$arity === '1') val[k] = _objectSpread({
          $type: definition.$initialValue
        }, mapChoiceElement(definition[definition.$initialValue]));else if (definition.$arity === 'n') val[k] = definition.$initialValue.map(function (key) {
          return _objectSpread({}, mapChoiceElement(definition[key]));
        });
      } else if (Array.isArray(definition)) {
        val[k] = definition.map(function (i) {
          return mapValues(valueMapper)(i);
        });
      } else {
        definition = definition; // satisfy type checker
        val[k] = valueMapper(definition);
      }
    };
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      _loop();
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
  return val;
}
function getInitialValue(definition) {
  var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var idx = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  switch (_typeof(definition)) {
    case "string":
    case "number":
    case "boolean":
    case "object":
      return cloneDeep(definition);
    case "function":
      return definition(context, valuesOnly(data), idx);
    default:
      return undefined;
  }
}
function isMeta(x) {
  return x.startsWith('$');
}
function isNotMeta(x) {
  return !isMeta(x);
}
function makeReset(key, context, idx) {
  return function () {
    var block = fieldDefinitions[key];
    var val = {};
    var _iterator4 = _createForOfIteratorHelper(flow(filterKeys(isNotMeta), entries)(block)),
      _step4;
    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var _step4$value = _slicedToArray(_step4.value, 2),
          k = _step4$value[0],
          definition = _step4$value[1];
        definition = definition; // satisfy type checker
        this[k].value = definition.default.initialValue !== undefined ? getInitialValue(definition.default.initialValue, context, val, idx) : '';
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
  };
}
/**
 * Validate an opinion template
 * @param ga
 */
export function validate(ga) {
  var errors = ga.map(function (block) {
    var template = fieldDefinitions[block.$key];
    if (!template) return [];
    return entries(block).filter(withoutMetaKeys).map(function (_ref38) {
      var _fieldTemplate$$valid, _fieldTemplate$$valid2;
      var _ref39 = _slicedToArray(_ref38, 2),
        fieldName = _ref39[0],
        field = _ref39[1];
      var fieldTemplate = template[fieldName];
      var result = fieldTemplate === null || fieldTemplate === void 0 ? void 0 : (_fieldTemplate$$valid = fieldTemplate.$validation) === null || _fieldTemplate$$valid === void 0 ? void 0 : (_fieldTemplate$$valid2 = _fieldTemplate$$valid.input) === null || _fieldTemplate$$valid2 === void 0 ? void 0 : _fieldTemplate$$valid2.call(_fieldTemplate$$valid, field.value);
      // If the validator wasn't executed for whatever reason, we just assume the field is always valid.
      if (result === undefined) return [];
      if (result === true) return [];
      return [{
        block: block.$key,
        blockLabel: block.$title,
        field: fieldName,
        fieldLabel: fieldTemplate.$label,
        error: result
      }];
    }).flat(1);
  }).flat(1);
  if (!errors.length) return true;else return errors;
}