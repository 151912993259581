var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('gmap-marker', {
    attrs: {
      "icon": _vm.icon || {
        url: _vm.$root.img[_vm.resolvedIcon],
        size: {
          width: 20,
          height: 30,
          f: "px",
          b: "px"
        },
        scaledSize: {
          width: 20,
          height: 30,
          f: "px",
          b: "px"
        }
      },
      "position": _vm.position,
      "draggable": false,
      "clickable": true
    },
    on: {
      "click": function click($event) {
        return _vm.onMarkerClicked();
      },
      "mouseover": function mouseover($event) {
        return _vm.$emit('hover');
      },
      "mouseout": function mouseout($event) {
        return _vm.$emit('hover-end');
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };