var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('gmap-map', {
    ref: "gmap",
    staticClass: "map",
    attrs: {
      "center": {
        lat: 48.284987,
        lng: 16.367482
      },
      "options": {
        styles: _vm.mapStyles,
        streetViewControl: false
      }
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_c('gmap-info-window', {
    attrs: {
      "position": _vm.infoWindowPosition,
      "opened": _vm.infoWindow.open
    },
    on: {
      "closeclick": function closeclick($event) {
        _vm.infoWindow.open = false;
      }
    }
  }, [_vm._t("context-menu", null, {
    "marker": _vm.infoWindow.marker
  })], 2), _vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };