import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor"
  }, [_vm.content.$key == 'be_calculationtable' ? _c('p', [_c('br'), _c('br'), _c('span', [_vm._v("Hier zusätzliche Bauteile/Außenanlagen hinzufügen, die nicht bereits den Gebäuden hinzugefügt worden sind.")])]) : _vm._e(), _vm.readonlytitle ? _c('div', [_c('h3', {
    staticClass: "bluehead"
  }, [_vm._v(_vm._s(_vm.readonlytitle))])]) : _c('div', [_c('block-editor-text-input', {
    attrs: {
      "label": "Bezeichnung",
      "template": _vm.content.tableTitle,
      "placeholder": _vm.titlePreset
    }
  })], 1), _c('div', [_c('table', {
    staticClass: "calculation"
  }, [_vm._m(0), _c('tbody', {
    staticClass: "nobreak"
  }, [_vm._l(_vm.content.calculationtable.value, function (f, i) {
    var _vm$content, _vm$content$calculati, _vm$content$calculati2;
    return _c('tr', [_c('td', {
      staticClass: "tac no-padding"
    }, [_c('v-popover', {
      staticClass: "configbtn _hover",
      attrs: {
        "offset": "3",
        "placement": "right"
      }
    }, [_c('button', {
      staticClass: "btn _small _clear color-red"
    }, [_c('i', {
      staticClass: "icon-times _redp"
    })]), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('ul', {
      staticClass: "tac color-red"
    }, [_c('li', {
      staticClass: "choose-block-li padding _small"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          return _vm.content.calculationtable.value.splice(i, 1);
        }
      }
    }, [_vm._v("Zeile löschen?")])])])])], 2)], 1), _c('td', [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.type,
        expression: "f.type"
      }],
      on: {
        "change": function change($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.$set(f, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "byVolume"
      }
    }, [_vm._v("Vol.")]), _c('option', {
      attrs: {
        "value": "byArea"
      }
    }, [_vm._v("Flä.")]), _c('option', {
      attrs: {
        "value": "custom"
      }
    }, [_vm._v("pschl")])])]), f.type == 'byArea' || f.type == 'byVolume' ? [_c('td', {
      staticStyle: {
        "width": "30rem"
      }
    }, [_c('input', {
      directives: [{
        name: "debounce",
        rawName: "v-debounce",
        value: 500,
        expression: "500"
      }, {
        name: "model",
        rawName: "v-model.lazy",
        value: f.name,
        expression: "f.name",
        modifiers: {
          "lazy": true
        }
      }],
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": f.name
      },
      on: {
        "change": function change($event) {
          return _vm.$set(f, "name", $event.target.value);
        }
      }
    })])] : _vm._e(), f.type == 'custom' ? [_c('td', {
      staticStyle: {
        "width": "50%"
      },
      attrs: {
        "colspan": "4"
      }
    }, [_c('input', {
      directives: [{
        name: "debounce",
        rawName: "v-debounce",
        value: 500,
        expression: "500"
      }, {
        name: "model",
        rawName: "v-model.lazy",
        value: f.name,
        expression: "f.name",
        modifiers: {
          "lazy": true
        }
      }],
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": f.name
      },
      on: {
        "change": function change($event) {
          return _vm.$set(f, "name", $event.target.value);
        }
      }
    })])] : _vm._e(), f.type == 'byArea' ? [_c('td', [_c('input', {
      directives: [{
        name: "debounce",
        rawName: "v-debounce",
        value: 500,
        expression: "500"
      }, {
        name: "model",
        rawName: "v-model.lazy.number",
        value: f.area,
        expression: "f.area",
        modifiers: {
          "lazy": true,
          "number": true
        }
      }],
      staticStyle: {
        "width": "6rem"
      },
      attrs: {
        "type": "number",
        "step": "1",
        "min": "0",
        "max": "100000000"
      },
      domProps: {
        "value": f.area
      },
      on: {
        "change": function change($event) {
          _vm.$set(f, "area", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    })]), _vm._m(1, true), _vm._m(2, true), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model.number",
        value: f.pricePer,
        expression: "f.pricePer",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "input",
      staticStyle: {
        "width": "8rem"
      },
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": f.pricePer
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(f, "pricePer", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    })]), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isOutside,
        expression: "f.isOutside"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isOutside) ? _vm._i(f.isOutside, null) > -1 : f.isOutside
      },
      on: {
        "change": function change($event) {
          var $$a = f.isOutside,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isOutside", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isOutside", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isOutside", $$c);
          }
        }
      }
    })])]), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isBuiltUpArea,
        expression: "f.isBuiltUpArea"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isBuiltUpArea) ? _vm._i(f.isBuiltUpArea, null) > -1 : f.isBuiltUpArea
      },
      on: {
        "change": function change($event) {
          var $$a = f.isBuiltUpArea,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isBuiltUpArea", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isBuiltUpArea", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isBuiltUpArea", $$c);
          }
        }
      }
    })])]), _c('td'), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isAreaCostRelevant,
        expression: "f.isAreaCostRelevant"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isAreaCostRelevant) ? _vm._i(f.isAreaCostRelevant, null) > -1 : f.isAreaCostRelevant
      },
      on: {
        "change": function change($event) {
          var $$a = f.isAreaCostRelevant,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isAreaCostRelevant", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isAreaCostRelevant", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isAreaCostRelevant", $$c);
          }
        }
      }
    })])]), _c('td', {
      staticClass: "tarv condensed"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(f.pricePer * f.area, -3)))]), _c('td')] : _vm._e(), f.type == 'byVolume' ? [_c('td', {
      staticClass: "condensed"
    }, [_c('input', {
      directives: [{
        name: "debounce",
        rawName: "v-debounce",
        value: 500,
        expression: "500"
      }, {
        name: "model",
        rawName: "v-model.lazy.number",
        value: f.area,
        expression: "f.area",
        modifiers: {
          "lazy": true,
          "number": true
        }
      }],
      staticClass: "condensed",
      staticStyle: {
        "width": "5rem"
      },
      attrs: {
        "type": "number",
        "step": "1",
        "min": "0",
        "max": "100000000"
      },
      domProps: {
        "value": f.area
      },
      on: {
        "change": function change($event) {
          _vm.$set(f, "area", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    })]), _c('td', [_c('input', {
      directives: [{
        name: "debounce",
        rawName: "v-debounce",
        value: 500,
        expression: "500"
      }, {
        name: "model",
        rawName: "v-model.lazy.number",
        value: f.height,
        expression: "f.height",
        modifiers: {
          "lazy": true,
          "number": true
        }
      }],
      staticStyle: {
        "width": "5rem"
      },
      attrs: {
        "type": "number",
        "step": "0.01",
        "min": "0",
        "max": "30"
      },
      domProps: {
        "value": f.height
      },
      on: {
        "change": function change($event) {
          _vm.$set(f, "height", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    })]), _c('td', {
      staticClass: "vol"
    }, [_vm._v(_vm._s(_vm.round2(f.area * f.height)) + " m"), _c('sup', [_vm._v("3")])]), _c('td', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.pricePer,
        expression: "f.pricePer"
      }],
      staticClass: "input",
      staticStyle: {
        "width": "8rem"
      },
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": f.pricePer
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(f, "pricePer", $event.target.value);
        }
      }
    })]), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isOutside,
        expression: "f.isOutside"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isOutside) ? _vm._i(f.isOutside, null) > -1 : f.isOutside
      },
      on: {
        "change": function change($event) {
          var $$a = f.isOutside,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isOutside", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isOutside", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isOutside", $$c);
          }
        }
      }
    })])]), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isBuiltUpArea,
        expression: "f.isBuiltUpArea"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isBuiltUpArea) ? _vm._i(f.isBuiltUpArea, null) > -1 : f.isBuiltUpArea
      },
      on: {
        "change": function change($event) {
          var $$a = f.isBuiltUpArea,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isBuiltUpArea", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isBuiltUpArea", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isBuiltUpArea", $$c);
          }
        }
      }
    })])]), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isBuiltUpVolume,
        expression: "f.isBuiltUpVolume"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isBuiltUpVolume) ? _vm._i(f.isBuiltUpVolume, null) > -1 : f.isBuiltUpVolume
      },
      on: {
        "change": function change($event) {
          var $$a = f.isBuiltUpVolume,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isBuiltUpVolume", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isBuiltUpVolume", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isBuiltUpVolume", $$c);
          }
        }
      }
    })])]), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isAreaCostRelevant,
        expression: "f.isAreaCostRelevant"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isAreaCostRelevant) ? _vm._i(f.isAreaCostRelevant, null) > -1 : f.isAreaCostRelevant
      },
      on: {
        "change": function change($event) {
          var $$a = f.isAreaCostRelevant,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isAreaCostRelevant", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isAreaCostRelevant", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isAreaCostRelevant", $$c);
          }
        }
      }
    })])]), _c('td', {
      staticClass: "tarv"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(f.pricePer * f.area * f.height, -3)))]), _c('td', [_c('div', {
      staticClass: "tr"
    }, [_c('small', [_vm._v(_vm._s(f.area ? _vm.$_formatMoney(f.pricePer * f.area * f.height / f.area) : '') + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(f.area ? _vm.$_formatMoney(f.pricePer * f.area * f.height * 1.2 / f.area) : ''))])])])] : _vm._e(), f.type == 'custom' ? [_c('td', [_c('input', {
      directives: [{
        name: "debounce",
        rawName: "v-debounce",
        value: 500,
        expression: "500"
      }, {
        name: "model",
        rawName: "v-model.lazy.number",
        value: f.price,
        expression: "f.price",
        modifiers: {
          "lazy": true,
          "number": true
        }
      }],
      staticClass: "input",
      staticStyle: {
        "width": "8rem"
      },
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": f.price
      },
      on: {
        "change": function change($event) {
          _vm.$set(f, "price", _vm._n($event.target.value));
        },
        "blur": function blur($event) {
          return _vm.$forceUpdate();
        }
      }
    })]), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: f.isOutside,
        expression: "f.isOutside"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(f.isOutside) ? _vm._i(f.isOutside, null) > -1 : f.isOutside
      },
      on: {
        "change": function change($event) {
          var $$a = f.isOutside,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(f, "isOutside", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(f, "isOutside", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(f, "isOutside", $$c);
          }
        }
      }
    })])]), _c('td'), _c('td'), _c('td'), _c('td', {
      staticClass: "tarv"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(f.price, -3)))]), _c('td')] : _vm._e(), _c('td', [_c('v-popover', {
      staticClass: "configbtn _hover",
      attrs: {
        "offset": "3",
        "placement": "right"
      }
    }, [_c('button', {
      staticClass: "btn _small _clear color-red"
    }, [_c('i', {
      staticClass: "icon-sortarrows"
    })]), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('ul', {
      staticClass: "tac color-red"
    }, [i > 0 ? _c('li', {
      staticClass: "choose-block-li padding _small"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          return _vm.moveUp(i);
        }
      }
    }, [_vm._v("nach oben")])]) : _vm._e(), i < ((_vm$content = _vm.content) === null || _vm$content === void 0 ? void 0 : (_vm$content$calculati = _vm$content.calculationtable) === null || _vm$content$calculati === void 0 ? void 0 : (_vm$content$calculati2 = _vm$content$calculati.value) === null || _vm$content$calculati2 === void 0 ? void 0 : _vm$content$calculati2.length) - 1 ? _c('li', {
      staticClass: "choose-block-li padding _small"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          return _vm.moveDown(i);
        }
      }
    }, [_vm._v("nach unten")])]) : _vm._e()])])], 2)], 1)], 2);
  }), _c('tr', [_c('td'), _c('td'), _c('td', {
    staticStyle: {
      "padding": ".5rem"
    },
    attrs: {
      "colspan": "5"
    }
  }, [_c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        return _vm.addNewCalcItem();
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  }), _c('span', [_vm._v(" Zeile hinzufügen")])])]), _c('td')])], 2), _c('tfoot', {
    staticClass: "nobreak condensed"
  }, [_c('tr', {
    staticClass: "bold blue condensed"
  }, [_c('td'), _c('td'), _c('td', [_vm._v("SUMME:")]), _c('td', [_c('div', {
    staticClass: "tr"
  }, [_vm._v(_vm._s(_vm.round2(_vm.calculationResult.costRelevantArea, 2)) + " m"), _c('sup', [_vm._v("2")])])]), _c('td'), _c('td', [_c('div', {
    staticClass: "tr"
  }, [_vm._v(_vm._s(_vm.round2(_vm.calculationResult.costRelevantVolume, 2)) + " m"), _c('sup', [_vm._v("3")])])]), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _c('td', [_c('div', {
    staticClass: "tarv tr"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.calculationResult.priceWoOutside)))])]), _c('td'), _c('td'), _c('td')]), _c('tr', {
    staticClass: "bold blue condensed"
  }, [_c('td'), _c('td'), _c('td', [_vm._v("Außenanlagen")]), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _c('td', [_c('div', {
    staticClass: "tarv tr"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.calculationResult.costOutside)))])]), _c('td'), _c('td'), _c('td')]), _c('tr')])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('thead', {
    staticClass: "condensed"
  }, [_c('tr', [_c('th'), _c('th', [_vm._v("Rech.")]), _c('th', [_vm._v("Bauteil / Geschoss ")]), _c('th', [_vm._v("Fläche m"), _c('sup', [_vm._v("2")])]), _c('th', [_vm._v("Höhe m")]), _c('th', [_vm._v("Volumen")]), _c('th', [_vm._v("EHW")]), _c('th', [_vm._v("Aa.")]), _c('th', [_vm._v("v.Fl.")]), _c('th', [_vm._v("v.Vol")]), _c('th', [_vm._v("BGF")]), _c('th', [_vm._v("NBW.")]), _c('th', [_vm._v("Kontrolle")]), _c('th')])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('td', [_c('div', {
    staticStyle: {
      "width": "5rem"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('td', [_c('div', {
    staticClass: "vol",
    staticStyle: {
      "width": "5rem"
    }
  })]);
}];
export { render, staticRenderFns };