var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.display ? _c('div', {
    staticClass: "input-field atop"
  }, [_vm.label || _vm.$slots.label ? _c('div', {
    staticClass: "label"
  }, [_vm._t("label", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2) : _vm._e(), _vm._t("default")], 2) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };