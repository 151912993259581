var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('h3', [_vm._v("Zustand & Maßnahmen")]), _c('block-editor-options', {
    attrs: {
      "label": "Zustand zum Zeitpunkt der Befundaufnahme:",
      "template": _vm.content.constructionState,
      "type": "radio"
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Schadenmindernde Maßnahmen:",
      "template": _vm.content.damagePreventionMeasures,
      "type": "radio"
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Bewohnbarkeit:",
      "template": _vm.content.habitability,
      "type": "radio"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };