var render = function render() {
  var _vm$recordingData, _vm$recordingData2, _vm$recordingData3;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "card",
    attrs: {
      "id": "recording"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content ovauto"
  }, [((_vm$recordingData = _vm.recordingData) === null || _vm$recordingData === void 0 ? void 0 : _vm$recordingData.status) == 'done' ? _c('div', [_c('div', {
    staticClass: "white-space-pre-line",
    domProps: {
      "innerHTML": _vm._s(_vm.summary)
    }
  }), (_vm$recordingData2 = _vm.recordingData) !== null && _vm$recordingData2 !== void 0 && _vm$recordingData2.promptOverride ? _c('div', [_c('h4', [_vm._v("Prompt:")]), _c('div', {
    staticClass: "white-space-pre-line"
  }, [_vm._v(_vm._s((_vm$recordingData3 = _vm.recordingData) === null || _vm$recordingData3 === void 0 ? void 0 : _vm$recordingData3.promptOverride))])]) : _vm._e()]) : _c('div', [_vm._v("keine Daten vorhanden oder noch in Bearbeitung")])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', [_vm._v("Aufnahmen")])]);
}];
export { render, staticRenderFns };