var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h4', [_vm._v("SimpleTextBlock")]), _c('h4', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.content.title,
      expression: "content.title"
    }],
    staticClass: "input",
    domProps: {
      "value": _vm.content.title
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.content, "title", $event.target.value);
      }
    }
  })]), _c('TextareaAutosize', {
    attrs: {
      "placeholder": "",
      "min-height": 80,
      "max-height": 480
    },
    model: {
      value: _vm.content.freetext,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "freetext", $$v);
      },
      expression: "content.freetext"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };