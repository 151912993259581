var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("Kopfteil")]), _c('block-editor-text-input', {
    attrs: {
      "label": "Bericht vom",
      "template": _vm.content.placeAndDate
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Auftraggeber (Adresszeile)",
      "template": _vm.content.clientAddress
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "ConsenseNr.",
      "template": _vm.content.consenseNr
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Bearbeiter",
      "template": _vm.content.editor
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };