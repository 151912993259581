import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', [_c('label', {
    staticClass: "label"
  }, [_vm._v("Name:"), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value.name,
      expression: "value.name"
    }],
    staticClass: "input",
    attrs: {
      "id": "template-name"
    },
    domProps: {
      "value": _vm.value.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.value, "name", $event.target.value);
      }
    }
  })]), _c('button', {
    staticClass: "btn _clear",
    attrs: {
      "type": "button",
      "id": "save-template"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('save');
      }
    }
  }, [_c('i', {
    staticClass: "icon-cloud-upload"
  })]), _c('button', {
    staticClass: "btn _clear",
    attrs: {
      "type": "button",
      "id": "delete-template"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_c('i', {
    staticClass: "icon-trashbin"
  })]), _vm.value.configuration.length ? _c('ol', {
    attrs: {
      "id": "template-configuration"
    }
  }, _vm._l(_vm.value.configuration, function (el, idx) {
    return _c('li', [_c('template-configuration-entry', {
      attrs: {
        "kind": el.type,
        "fields-data": el.fieldsData
      },
      on: {
        "add-above": function addAbove($event) {
          return _vm.value.configuration.splice(idx, 0, {
            type: null
          });
        },
        "add-below": function addBelow($event) {
          return _vm.value.configuration.splice(idx + 1, 0, {
            type: null
          });
        },
        "delete": function _delete($event) {
          return _vm.value.configuration.splice(idx, 1);
        },
        "update:kind": function updateKind($event) {
          return _vm.$set(el, "type", $event);
        },
        "update:fieldsData": function updateFieldsData($event) {
          return _vm.$set(el, "fieldsData", $event);
        },
        "update:fields-data": function updateFieldsData($event) {
          return _vm.$set(el, "fieldsData", $event);
        }
      }
    })], 1);
  }), 0) : _c('div', {
    staticClass: "tac"
  }, [_c('button', {
    staticClass: "btn _clear",
    on: {
      "click": function click($event) {
        return _vm.value.configuration.push({
          type: null
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };