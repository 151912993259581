import "core-js/modules/es.array.splice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', [_c('h5', [_vm._v("Anwesend bei Befundaufnahme")]), _c('contact-list', {
    attrs: {
      "contacts": _vm.content.presentAtAssessment
    },
    on: {
      "add": _vm.addPerson,
      "remove": function remove($event) {
        return _vm.content.presentAtAssessment.splice($event, 1);
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };