var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('small', {
    staticClass: "bold"
  }, [_vm._v("Freigegeben für: ")]), _c('small', {
    staticClass: "color-green contacts"
  }, _vm._l(_vm.users, function (user) {
    return user !== _vm.CONSENSE_MITARBEITER_ID ? _c('span', {
      staticClass: "contact"
    }, [_vm._v(_vm._s(_vm.svNamesByCrmContactId[user] || 'kein Name'))]) : _vm._e();
  }), 0), _vm._isAdmin ? _c('small', [_vm._v(_vm._s(" ")), _c('a', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('AssignUserToAktModal');
      }
    }
  }, [_vm._v("(verwalten)")])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };