import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('block-editor-input', {
    attrs: {
      "display": _vm.display,
      "label": _vm.label
    },
    scopedSlots: _vm._u([_vm.$slots.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._t("label")];
      },
      proxy: true
    } : null, {
      key: "default",
      fn: function fn() {
        var _vm$template;
        return [_vm.readonly ? _c('div', {
          staticClass: "input"
        }, [_vm._v(_vm._s((_vm$template = _vm.template) === null || _vm$template === void 0 ? void 0 : _vm$template.value))]) : _c('div', {
          staticClass: "input"
        }, [_vm.toggleable ? _c('div', {
          staticClass: "input-field"
        }, [_c('small', {
          staticClass: "bold"
        }, [_vm._v("Anzeigen:")]), _c('toggle-button', {
          attrs: {
            "height": 13,
            "width": 42,
            "color": "#82C7EB",
            "sync": true,
            "labels": {
              checked: 'JA',
              unchecked: 'NEIN'
            }
          },
          model: {
            value: _vm.template.toggled,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "toggled", $$v);
            },
            expression: "template.toggled"
          }
        })], 1) : _vm._e(), !_vm.toggleable || _vm.template.toggled ? _c('label', {
          staticClass: "input-field"
        }, [(_vm.type || 'text') === 'checkbox' ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.template.value,
            expression: "template.value"
          }],
          staticClass: "input",
          attrs: {
            "placeholder": _vm.placeholder || '',
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.template.value) ? _vm._i(_vm.template.value, null) > -1 : _vm.template.value
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.template.value,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.template, "value", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.template, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.template, "value", $$c);
              }
            }
          }
        }) : (_vm.type || 'text') === 'radio' ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.template.value,
            expression: "template.value"
          }],
          staticClass: "input",
          attrs: {
            "placeholder": _vm.placeholder || '',
            "type": "radio"
          },
          domProps: {
            "checked": _vm._q(_vm.template.value, null)
          },
          on: {
            "change": function change($event) {
              return _vm.$set(_vm.template, "value", null);
            }
          }
        }) : _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.template.value,
            expression: "template.value"
          }],
          staticClass: "input",
          attrs: {
            "placeholder": _vm.placeholder || '',
            "type": _vm.type || 'text'
          },
          domProps: {
            "value": _vm.template.value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.template, "value", $event.target.value);
            }
          }
        }), _vm._t("after")], 2) : _vm._e()])];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };