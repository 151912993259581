var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('div', {
    staticClass: "input-field atop"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Titelfeld")]), _c('div', {
    staticClass: "input"
  }, [_c('v-radiocheck', {
    attrs: {
      "template": _vm.template.titelfeld,
      "item": _vm.content.title.value
    }
  })], 1)]), _c('h4', [_vm._v("Teil 1")]), _c('block-editor-text-input', {
    attrs: {
      "label": "Versicherungsnehmer",
      "template": _vm.content.insurant
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Schadennummer",
      "template": _vm.content.damageNumber
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Anspruchsteller",
      "template": _vm.content.claimant
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Auftragsnummer",
      "template": _vm.content.jobNumber
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Besichtigungsort",
      "template": _vm.content.inspectionLocation
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Schadendatum",
      "template": _vm.content.damageDate
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Polizzennummer",
      "template": _vm.content.policyNumber
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Auftrag vom",
      "template": _vm.content.jobDate
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "besichtigt am",
      "template": _vm.content.visitedAt
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Sparte",
      "template": _vm.content.branch
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "bewertet:",
      "template": _vm.content.assessed,
      "no-annotation": ""
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "ConsenseNr.",
      "template": _vm.content.consensenr
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Bearbeiter",
      "template": _vm.content.editor
    }
  }), _c('h4', [_vm._v("Teil 2")]), _c('block-editor-options', {
    attrs: {
      "label": "Regressmöglichkeit:",
      "template": _vm.content.regressAvailable,
      "type": "radio"
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Vorschaden:",
      "template": _vm.content.existingDamages,
      "type": "radio"
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Schadenursache",
      "template": _vm.content.causeOfDamage
    }
  }), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Neuwert/Zeitwert")]), _c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "input-field"
  }, [_c('v-checkbox', {
    attrs: {
      "label": 'Neuwert & Zeitwert NICHT aus Kostentabelle errechnen'
    },
    model: {
      value: _vm.content.noOriginalValueCalculation.value,
      callback: function callback($$v) {
        _vm.$set(_vm.content.noOriginalValueCalculation, "value", $$v);
      },
      expression: "content.noOriginalValueCalculation.value"
    }
  })], 1), _vm.content.noOriginalValueCalculation.value ? _c('div', {
    staticClass: "input"
  }, [_c('block-editor-text-input', {
    attrs: {
      "label": "Neuwert",
      "template": _vm.content.originalValue
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Zeitwert",
      "template": _vm.content.presentValue
    }
  })], 1) : _vm._e()])]), _c('block-editor-options', {
    attrs: {
      "label": "Schadensumme",
      "template": _vm.content.includeUstInDamages,
      "type": "radio",
      "no-annotation": ""
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Ablöse:",
      "template": _vm.content.wantsCompensation,
      "type": "radio",
      "no-annotation": ""
    }
  }), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("KontoNr.")]), _c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "input-field"
  }, [_c('v-checkbox', {
    attrs: {
      "label": 'Ablöse & KontoNr. im PDF anzeigen'
    },
    model: {
      value: _vm.content.showAccountNumber.value,
      callback: function callback($$v) {
        _vm.$set(_vm.content.showAccountNumber, "value", $$v);
      },
      expression: "content.showAccountNumber.value"
    }
  })], 1), _c('div', {
    staticClass: "input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.content.accountNumber.value,
      expression: "content.accountNumber.value"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "disabled": !_vm.content.showAccountNumber.value
    },
    domProps: {
      "value": _vm.content.accountNumber.value
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.content.accountNumber, "value", $event.target.value);
      }
    }
  })])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };