import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export function nullAsEmpty(pieces) {
  var result = pieces[0];
  for (var i = 0; i < (arguments.length <= 1 ? 0 : arguments.length - 1); ++i) {
    var sub = i + 1 < 1 || arguments.length <= i + 1 ? undefined : arguments[i + 1];
    result += sub !== null && sub !== undefined ? sub : '';
    result += pieces[i + 1];
  }
  return result.replace(/ +/g, ' ').trim();
}