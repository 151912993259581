var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('block-editor-input', {
    attrs: {
      "display": _vm.display,
      "label": _vm.label
    },
    scopedSlots: _vm._u([_vm.$slots.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._t("label")];
      },
      proxy: true
    } : null, {
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "input"
        }, [_vm.template.value.length > 0 && _vm.template.value[0] !== '<' ? [_c('TextareaAutosize', {
          attrs: {
            "placeholder": "",
            "min-height": 160,
            "max-height": 480
          },
          model: {
            value: _vm.template.value,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "value", $$v);
            },
            expression: "template.value"
          }
        }), _c('div', {
          staticClass: "tac"
        }, [_c('button', {
          staticClass: "btn _small _green",
          on: {
            "click": function click($event) {
              _vm.template.value = "<p>".concat(_vm.template.value, "</p>");
            }
          }
        }, [_vm._v("Zum Editor wechseln")])])] : _c('TipTap', {
          attrs: {
            "content": _vm.template,
            "ckey": "value"
          }
        })], 2)];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };