var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [!_vm.content.subblocks.length ? _c('div', {
    staticClass: "add-section"
  }, [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.openTemplateSelector
    }
  }, [_vm._v("Vorlage wählen")]), _c('add-block', {
    on: {
      "selected": function selected($event) {
        return _vm.addAbove(0, $event);
      }
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })])], 1) : _vm._e(), _vm._l(_vm.content.subblocks, function (sub, idx) {
    return _c('div', {
      key: idx,
      staticClass: "subblock"
    }, [_c('div', {
      staticClass: "subblock-header"
    }, [_c('add-block', {
      on: {
        "selected": function selected($event) {
          return _vm.addAbove(idx, $event);
        }
      }
    }, [_c('i', {
      staticClass: "icon-add-above"
    })]), _c('add-block', {
      on: {
        "selected": function selected($event) {
          return _vm.addBelow(idx, $event);
        }
      }
    }, [_c('i', {
      staticClass: "icon-add-below"
    })]), _c('button', {
      staticClass: "btn _clear nomargin",
      on: {
        "click": function click($event) {
          return _vm.moveDown(idx);
        }
      }
    }, [_c('i', {
      staticClass: "icon-arrow-down"
    })]), _c('button', {
      staticClass: "btn _clear nomargin",
      on: {
        "click": function click($event) {
          return _vm.moveUp(idx);
        }
      }
    }, [_c('i', {
      staticClass: "icon-arrow-top"
    })]), _c('button', {
      staticClass: "btn _clear nomargin",
      on: {
        "click": function click($event) {
          return _vm.clone(idx);
        }
      }
    }, [_c('i', {
      staticClass: "icon-copy"
    })]), _c('v-popover', {
      attrs: {
        "offset": "0",
        "position": "right"
      }
    }, [_c('button', {
      staticClass: "btn _clear nomargin"
    }, [_c('i', {
      staticClass: "icon-trashbin"
    })]), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('a', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          return _vm.remove(idx);
        }
      }
    }, [_vm._v("Sicher entfernen?")])])], 2)], 1), _c(sub.$component, {
      tag: "component",
      attrs: {
        "content": sub,
        "crm-akt": _vm.crmAkt
      }
    })], 1);
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };