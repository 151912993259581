import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.iterator.js";
// project
//   => can have several buildings
//   => can have several other objects
import { keyBy } from 'lodash';
function objectivy() {
  var vals = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var obj = [];
  // obj.push({ value: 'keine Angabe' })
  // obj.push({ value: 'nicht bekannt' })
  // obj.push({ value: 'nicht vorhanden' })
  vals.forEach(function (element) {
    return obj.push({
      value: element
    });
  });
  return obj;
}
function numberArray(from, to, dir) {
  var obj = [];
  // obj.push({ value: 'keine Angabe' })
  // obj.push({ value: 'nicht bekannt' })
  // obj.push({ value: 'nicht vorhanden' })
  if (dir > 0) for (var i = from; i <= to; i++) obj.push({
    value: i + ""
  });else if (dir < 0) for (var _i = to; _i >= from; _i--) obj.push({
    value: _i + ""
  });
  return obj;
}
var date = new Date();
var nextYear = date.getFullYear() + 1;
var valuesTypes = function valuesTypes() {
  var _vals$components, _vals$components$valu, _vals$components$valu2, _vals$components2, _vals$components2$val, _vals$components2$val2, _vals$components3, _vals$components3$val, _vals$components3$val2, _vals$components4, _vals$components4$val, _vals$components4$val2, _vals$components5, _vals$components5$val, _vals$components5$val2, _vals$components6, _vals$components6$val, _vals$components6$val2, _vals$components7, _vals$components7$val, _vals$components7$val2, _vals$components8, _vals$components8$val, _vals$components8$val2, _vals$components9, _vals$components9$val, _vals$components9$val2, _vals$components10, _vals$components10$va, _vals$components10$va2, _vals$components11, _vals$components11$va, _vals$components11$va2, _vals$components12, _vals$components12$va, _vals$components12$va2, _vals$components13, _vals$components13$va, _vals$components13$va2, _vals$outdoor, _vals$outdoor$values, _vals$outdoor$values$, _vals$houseTechnic, _vals$houseTechnic$va, _vals$houseTechnic$va2, _vals$houseTechnic2, _vals$houseTechnic2$v, _vals$houseTechnic2$v2, _vals$houseTechnic3, _vals$houseTechnic3$v, _vals$houseTechnic3$v2, _vals$houseTechnic4, _vals$houseTechnic4$v, _vals$houseTechnic4$v2, _vals$houseTechnic5, _vals$houseTechnic5$v, _vals$houseTechnic5$v2, _vals$houseTechnic6, _vals$houseTechnic6$v, _vals$houseTechnic6$v2, _vals$houseTechnic7, _vals$houseTechnic7$v, _vals$houseTechnic7$v2, _vals$houseTechnic8, _vals$houseTechnic8$v, _vals$houseTechnic8$v2, _vals$houseTechnic9, _vals$houseTechnic9$v, _vals$houseTechnic9$v2;
  var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var vals = keyBy(values, 'id');
  for (var vix in vals) {
    vals[vix].values = keyBy(vals[vix].values, 'id');
  }
  return {
    floors: objectivy(vals.usage.values.floors.values),
    roof: objectivy(vals.usage.values.roof.values),
    //
    // buildingStyle: objectivy(vals?.components?.values?.buildingStyle?.values),
    buildingType: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components = vals.components) === null || _vals$components === void 0 ? void 0 : (_vals$components$valu = _vals$components.values) === null || _vals$components$valu === void 0 ? void 0 : (_vals$components$valu2 = _vals$components$valu.buildingType) === null || _vals$components$valu2 === void 0 ? void 0 : _vals$components$valu2.values),
    construction: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components2 = vals.components) === null || _vals$components2 === void 0 ? void 0 : (_vals$components2$val = _vals$components2.values) === null || _vals$components2$val === void 0 ? void 0 : (_vals$components2$val2 = _vals$components2$val.construction) === null || _vals$components2$val2 === void 0 ? void 0 : _vals$components2$val2.values),
    facades: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components3 = vals.components) === null || _vals$components3 === void 0 ? void 0 : (_vals$components3$val = _vals$components3.values) === null || _vals$components3$val === void 0 ? void 0 : (_vals$components3$val2 = _vals$components3$val.facades) === null || _vals$components3$val2 === void 0 ? void 0 : _vals$components3$val2.values),
    ceiling: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components4 = vals.components) === null || _vals$components4 === void 0 ? void 0 : (_vals$components4$val = _vals$components4.values) === null || _vals$components4$val === void 0 ? void 0 : (_vals$components4$val2 = _vals$components4$val.ceiling) === null || _vals$components4$val2 === void 0 ? void 0 : _vals$components4$val2.values),
    roofing: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components5 = vals.components) === null || _vals$components5 === void 0 ? void 0 : (_vals$components5$val = _vals$components5.values) === null || _vals$components5$val === void 0 ? void 0 : (_vals$components5$val2 = _vals$components5$val.roofing) === null || _vals$components5$val2 === void 0 ? void 0 : _vals$components5$val2.values),
    stairs: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components6 = vals.components) === null || _vals$components6 === void 0 ? void 0 : (_vals$components6$val = _vals$components6.values) === null || _vals$components6$val === void 0 ? void 0 : (_vals$components6$val2 = _vals$components6$val.stairs) === null || _vals$components6$val2 === void 0 ? void 0 : _vals$components6$val2.values),
    flooring: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components7 = vals.components) === null || _vals$components7 === void 0 ? void 0 : (_vals$components7$val = _vals$components7.values) === null || _vals$components7$val === void 0 ? void 0 : (_vals$components7$val2 = _vals$components7$val.flooring) === null || _vals$components7$val2 === void 0 ? void 0 : _vals$components7$val2.values),
    wallCovering: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components8 = vals.components) === null || _vals$components8 === void 0 ? void 0 : (_vals$components8$val = _vals$components8.values) === null || _vals$components8$val === void 0 ? void 0 : (_vals$components8$val2 = _vals$components8$val.wallCovering) === null || _vals$components8$val2 === void 0 ? void 0 : _vals$components8$val2.values),
    ceilingCovering: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components9 = vals.components) === null || _vals$components9 === void 0 ? void 0 : (_vals$components9$val = _vals$components9.values) === null || _vals$components9$val === void 0 ? void 0 : (_vals$components9$val2 = _vals$components9$val.ceilingCovering) === null || _vals$components9$val2 === void 0 ? void 0 : _vals$components9$val2.values),
    windows: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components10 = vals.components) === null || _vals$components10 === void 0 ? void 0 : (_vals$components10$va = _vals$components10.values) === null || _vals$components10$va === void 0 ? void 0 : (_vals$components10$va2 = _vals$components10$va.windows) === null || _vals$components10$va2 === void 0 ? void 0 : _vals$components10$va2.values),
    gates: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components11 = vals.components) === null || _vals$components11 === void 0 ? void 0 : (_vals$components11$va = _vals$components11.values) === null || _vals$components11$va === void 0 ? void 0 : (_vals$components11$va2 = _vals$components11$va.gates) === null || _vals$components11$va2 === void 0 ? void 0 : _vals$components11$va2.values),
    heatingSystem: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components12 = vals.components) === null || _vals$components12 === void 0 ? void 0 : (_vals$components12$va = _vals$components12.values) === null || _vals$components12$va === void 0 ? void 0 : (_vals$components12$va2 = _vals$components12$va.heatingSystem) === null || _vals$components12$va2 === void 0 ? void 0 : _vals$components12$va2.values),
    houseTechnic: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$components13 = vals.components) === null || _vals$components13 === void 0 ? void 0 : (_vals$components13$va = _vals$components13.values) === null || _vals$components13$va === void 0 ? void 0 : (_vals$components13$va2 = _vals$components13$va.houseTechnic) === null || _vals$components13$va2 === void 0 ? void 0 : _vals$components13$va2.values),
    //
    outside: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$outdoor = vals.outdoor) === null || _vals$outdoor === void 0 ? void 0 : (_vals$outdoor$values = _vals$outdoor.values) === null || _vals$outdoor$values === void 0 ? void 0 : (_vals$outdoor$values$ = _vals$outdoor$values.outside) === null || _vals$outdoor$values$ === void 0 ? void 0 : _vals$outdoor$values$.values),
    electrics: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic = vals.houseTechnic) === null || _vals$houseTechnic === void 0 ? void 0 : (_vals$houseTechnic$va = _vals$houseTechnic.values) === null || _vals$houseTechnic$va === void 0 ? void 0 : (_vals$houseTechnic$va2 = _vals$houseTechnic$va.electrics) === null || _vals$houseTechnic$va2 === void 0 ? void 0 : _vals$houseTechnic$va2.values),
    photovoltaik: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic2 = vals.houseTechnic) === null || _vals$houseTechnic2 === void 0 ? void 0 : (_vals$houseTechnic2$v = _vals$houseTechnic2.values) === null || _vals$houseTechnic2$v === void 0 ? void 0 : (_vals$houseTechnic2$v2 = _vals$houseTechnic2$v.photovoltaik) === null || _vals$houseTechnic2$v2 === void 0 ? void 0 : _vals$houseTechnic2$v2.values),
    safetyEquipment: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic3 = vals.houseTechnic) === null || _vals$houseTechnic3 === void 0 ? void 0 : (_vals$houseTechnic3$v = _vals$houseTechnic3.values) === null || _vals$houseTechnic3$v === void 0 ? void 0 : (_vals$houseTechnic3$v2 = _vals$houseTechnic3$v.safetyEquipment) === null || _vals$houseTechnic3$v2 === void 0 ? void 0 : _vals$houseTechnic3$v2.values),
    sanitaryFacilities: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic4 = vals.houseTechnic) === null || _vals$houseTechnic4 === void 0 ? void 0 : (_vals$houseTechnic4$v = _vals$houseTechnic4.values) === null || _vals$houseTechnic4$v === void 0 ? void 0 : (_vals$houseTechnic4$v2 = _vals$houseTechnic4$v.sanitaryFacilities) === null || _vals$houseTechnic4$v2 === void 0 ? void 0 : _vals$houseTechnic4$v2.values),
    solarDevices: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic5 = vals.houseTechnic) === null || _vals$houseTechnic5 === void 0 ? void 0 : (_vals$houseTechnic5$v = _vals$houseTechnic5.values) === null || _vals$houseTechnic5$v === void 0 ? void 0 : (_vals$houseTechnic5$v2 = _vals$houseTechnic5$v.solarDevices) === null || _vals$houseTechnic5$v2 === void 0 ? void 0 : _vals$houseTechnic5$v2.values),
    heatingSystems: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic6 = vals.houseTechnic) === null || _vals$houseTechnic6 === void 0 ? void 0 : (_vals$houseTechnic6$v = _vals$houseTechnic6.values) === null || _vals$houseTechnic6$v === void 0 ? void 0 : (_vals$houseTechnic6$v2 = _vals$houseTechnic6$v.heatingSystems) === null || _vals$houseTechnic6$v2 === void 0 ? void 0 : _vals$houseTechnic6$v2.values),
    ventilationAirConditioning: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic7 = vals.houseTechnic) === null || _vals$houseTechnic7 === void 0 ? void 0 : (_vals$houseTechnic7$v = _vals$houseTechnic7.values) === null || _vals$houseTechnic7$v === void 0 ? void 0 : (_vals$houseTechnic7$v2 = _vals$houseTechnic7$v.ventilationAirConditioning) === null || _vals$houseTechnic7$v2 === void 0 ? void 0 : _vals$houseTechnic7$v2.values),
    propertyProtectionSystems: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic8 = vals.houseTechnic) === null || _vals$houseTechnic8 === void 0 ? void 0 : (_vals$houseTechnic8$v = _vals$houseTechnic8.values) === null || _vals$houseTechnic8$v === void 0 ? void 0 : (_vals$houseTechnic8$v2 = _vals$houseTechnic8$v.propertyProtectionSystems) === null || _vals$houseTechnic8$v2 === void 0 ? void 0 : _vals$houseTechnic8$v2.values),
    transportationFacilities: objectivy(vals === null || vals === void 0 ? void 0 : (_vals$houseTechnic9 = vals.houseTechnic) === null || _vals$houseTechnic9 === void 0 ? void 0 : (_vals$houseTechnic9$v = _vals$houseTechnic9.values) === null || _vals$houseTechnic9$v === void 0 ? void 0 : (_vals$houseTechnic9$v2 = _vals$houseTechnic9$v.transportationFacilities) === null || _vals$houseTechnic9$v2 === void 0 ? void 0 : _vals$houseTechnic9$v2.values)
    // specialities: objectivy(vals?.houseTechnic?.values?.specialities?.values),
  };
};
var makeValue = function makeValue(name, selectKey) {
  var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'select';
  var predefined = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  // if (type == 'number') return {
  //   name,
  //   value: predefined || 0,
  //   type,
  // }
  // if (type == 'select') 
  return {
    name: name,
    value: predefined || [],
    type: type,
    key: key,
    // values: selectKey ? valuesTypes[selectKey] : [],
    selectKey: selectKey || []
  };
};
var makeNumberValue = function makeNumberValue(name) {
  var predefined = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return {
    name: name,
    value: predefined,
    type: 'number'
  };
};
var makeTextValue = function makeTextValue(name) {
  var predefined = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return {
    name: name,
    value: predefined,
    type: 'text'
  };
};
// {
//   id: '01GQABNKLB7F2M3NXRQBHJ5UO2JVPZR2Z7',
//   path: '/Bilder/lage.png',
//   caption: 'BÜROS u. Lagerhallen / Bauhof - Großkrut',
// }
var buildingTemplate = [{
  //! #0  
  type: "root",
  key: "root",
  title: "Gebäudeobjekt",
  version: 1,
  values: [{
    name: "Bezeichnung",
    value: "Gebäudeobjekt",
    key: "root",
    description: {
      $alias: 'description',
      default: {
        initialValue: function initialValue() {
          return '';
        }
      }
    },
    images: {
      value: {
        data: [],
        layout: '1',
        maxImages: 1,
        quality: 'medium',
        $dontShowCaption: true
      }
    }
  }, {
    images: {
      value: {
        data: [],
        layout: '2',
        maxImages: 2,
        quality: 'medium',
        $dontShowCaption: true
      }
    }
  }]
}, {
  //! #1  
  type: "keyData",
  title: "Eckdaten",
  version: 1,
  values: [makeValue("Gebäudetyp", 'buildingType'), makeTextValue("Geschoße unter Erdniveau", '1'), makeTextValue("Geschoße über Erdniveau", '2')
  // makeTextValue("Dachgeschoße", '1')
  ]
}, {
  //! #2
  type: "usage",
  key: "usage",
  title: "Nutzung",
  version: 1,
  values: [makeValue("Kellergeschoss", 'floors', 'usage'), makeValue("Erdgeschoss", 'floors', 'usage'), makeValue("Obergeschoss", 'floors', 'usage'), makeValue("Dach", 'roof', 'usage')]
}, {
  //! #3
  type: "components",
  keye: "components",
  title: "Bauteile - Substanzbeschreibung",
  version: 1,
  values: [
  // makeValue("Gebäudetyp", "buildingType"),
  makeValue("Bauweise", "construction"), makeValue("Fassaden", "facades"), makeValue("Decken", "ceiling"), makeValue("Dachung/Dachdeckung", "roofing"), makeValue("Stiegenanlagen", "stairs"), makeValue("Bodenbelag", "flooring"), makeValue("Wandbelag", "wallCovering"), makeValue("Deckenbelag", "ceilingCovering"), makeValue("Fenster/Lichteinlass", "windows"), makeValue("Tore/Türen", "gates"), makeValue("Heizungsanlage", "heatingSystem"), makeValue("zusätzliche Haustechnik", "houseTechnic")]
}, {
  //! #4
  type: "outdoor",
  key: "outdoor",
  title: "Außenanlagen",
  version: 1,
  values: [makeValue("Außenanlagen", 'outside')]
}, {
  //! #5
  type: "houseTechnic",
  key: "houseTechnic",
  title: "Haustechnik",
  version: 1,
  values: [makeValue("Elektroanlagen", 'electrics'), makeValue("Photovoltaikanlagen", 'photovoltaik'), makeValue("Sicherheitseinrichtungen", 'safetyEquipment'), makeValue("Wasser/Sanitäranlagen", 'sanitaryFacilities'), makeValue("Solaranlagen", 'solarDevices'), makeValue("Heizungsanlagen", 'heatingSystems'), makeValue("Lüftung/Klimaanlagen", 'ventilationAirConditioning'), makeValue("Objektschutzanlagen", 'propertyProtectionSystems'), makeValue("Beförderungsanlagen", 'transportationFacilities')
  // makeValue("Besonderheiten", 'specialities'),
  ]
}, {
  //! #5.1  
  type: "specialities",
  key: "specialities",
  title: "Besonderheiten",
  version: 1,
  value: {
    $alias: 'specialities',
    default: {
      initialValue: function initialValue() {
        return '';
      }
    }
  }
}, {
  //! #6
  type: "calculation",
  key: "calculation",
  title: "Berechnungstabelle",
  tableTitle: {
    $alias: 'tableTitle',
    default: {
      initialValue: function initialValue() {
        return '';
      }
    }
  },
  calculationResults: {},
  calculationtable: {
    value: [{
      name: 'Keller',
      // comment: '...',
      area: 0,
      height: 0,
      pricePer: 0,
      price: 0,
      type: 'byVolume' // byArea | by Volume | custom
    }, {
      name: 'Erdgeschoß',
      // comment: '',
      area: 0,
      height: 0,
      pricePer: 0,
      price: 0,
      type: 'byVolume'
    }, {
      name: 'Obergeschoß',
      // comment: '',
      area: 0,
      height: 0,
      pricePer: 0,
      price: 0,
      type: 'byVolume'
    }]
  } //!
}, {
  //!7
  type: "images",
  key: "images",
  title: "Bilder",
  version: 1,
  values: [{
    images: {
      value: {
        data: [
          // {id: '01GQABNKLB7F2M3NXRQBHJ5UO2JVPZR2Z7',path: '/Bilder/lage.png',caption: 'BÜROS u. Lagerhallen / Bauhof - Großkrut',}
        ],
        layout: '6',
        maxImages: 99,
        quality: 'medium'
      }
    }
  }]
}];
//! # will be re-generated by keyData
var calculationTemplate = {
  type: "calculation",
  title: "Caclulation",
  calculationType: "area",
  version: 1,
  values: [{
    name: 'Keller',
    // comment: '...',
    area: 0,
    height: 3,
    pricePer: 0,
    price: 0,
    type: 'byVolume' // byArea | byVolume | custom
  }, {
    name: 'Erdgeschoß',
    // comment: '',
    area: 0,
    height: 0,
    pricePer: 0,
    price: 0,
    type: 'byVolume'
  }, {
    name: 'Obergeschoß',
    // comment: '',
    area: 0,
    height: 0,
    pricePer: 0,
    price: 0,
    type: 'byVolume'
  }]
};
export { buildingTemplate, calculationTemplate, valuesTypes };