var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "step-progress"
  }, [_c('ol', _vm._l(_vm.steps, function (step, idx) {
    return _c('li', {
      class: {
        complete: idx < _vm.numCompleted,
        next: idx === _vm.numCompleted
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('node-clicked', {
            index: idx,
            step: step
          });
        }
      }
    }, [idx > 0 ? _c('div', {
      staticClass: "progress-in"
    }) : _vm._e(), idx < _vm.steps.length - 1 ? _c('div', {
      staticClass: "progress-out"
    }) : _vm._e(), _c('div', {
      staticClass: "progress-node"
    }), _c('span', {
      staticClass: "step-label"
    }, [_vm._v(_vm._s(step))])]);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };