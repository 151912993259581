var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('block-editor-input', {
    attrs: {
      "display": _vm.display,
      "label": _vm.label
    },
    scopedSlots: _vm._u([_vm.$slots.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._t("label")];
      },
      proxy: true
    } : null, {
      key: "default",
      fn: function fn() {
        return [_c('money', {
          staticClass: "tal input",
          model: {
            value: _vm.template.value,
            callback: function callback($$v) {
              _vm.$set(_vm.template, "value", $$v);
            },
            expression: "template.value"
          }
        })];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };