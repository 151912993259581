var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('block-editor-textarea', {
    attrs: {
      "label": "Auftraggeber (Adresszeile)",
      "template": _vm.content.clientAddress
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Zweck",
      "template": _vm.content.purpose
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "BK-Index:",
      "template": _vm.content.bkIndex
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "BP-Index:",
      "template": _vm.content.bpIndex
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "VN",
      "template": _vm.content.insuree
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "VN Adresse",
      "template": _vm.content.insureeAddress
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Risikoadresse",
      "template": _vm.content.riskAddress
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Begehung",
      "template": _vm.content.inspectionDate
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Stichtag",
      "template": _vm.content.keyDate
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };