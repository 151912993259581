import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('h2', [_vm._v("Email versenden:")]), _c('c-card', [_c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Email speichern unter:")]), _c('div', {
    staticClass: "input"
  }, [_c('sharepoint-store-file-selector', {
    attrs: {
      "commission": _vm.crmAkt
    },
    model: {
      value: _vm.storageLocation,
      callback: function callback($$v) {
        _vm.storageLocation = $$v;
      },
      expression: "storageLocation"
    }
  }), _vm.errors.storageLocation ? _c('div', {
    staticClass: "invalid-description"
  }, [_vm._v(_vm._s(_vm.errors.storageLocation))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label",
    attrs: {
      "id": "label-dringend"
    }
  }, [_vm._v("Dringend:")]), _c('div', {
    staticClass: "input"
  }, [_c('v-checkbox', {
    attrs: {
      "id": "urgent",
      "aria-labelledby": "label-dringend"
    },
    model: {
      value: _vm.urgent,
      callback: function callback($$v) {
        _vm.urgent = $$v;
      },
      expression: "urgent"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Empfänger:")]), _c('div', {
    staticClass: "input"
  }, [_c('v-select', {
    attrs: {
      "id": "recipients",
      "label": "recipients",
      "options": _vm.availableRecipients,
      "get-option-label": function getOptionLabel(o) {
        return o.name ? "".concat(o.name, " <").concat(o.email, ">") : o.email;
      },
      "create-option": _vm.parseOption,
      "multiple": "",
      "taggable": ""
    },
    model: {
      value: _vm.selectedRecipients,
      callback: function callback($$v) {
        _vm.selectedRecipients = $$v;
      },
      expression: "selectedRecipients"
    }
  }), _vm.errors.selectedRecipients ? _c('div', {
    staticClass: "invalid-description"
  }, [_vm._v(_vm._s(_vm.errors.selectedRecipients))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Cc:")]), _c('div', {
    staticClass: "input"
  }, [_c('v-select', {
    attrs: {
      "id": "copy-to",
      "label": "copy-to",
      "options": _vm.availableRecipients,
      "get-option-label": function getOptionLabel(o) {
        return o.name ? "".concat(o.name, " <").concat(o.email, ">") : o.email;
      },
      "create-option": _vm.parseOption,
      "multiple": "",
      "taggable": ""
    },
    model: {
      value: _vm.copyToRecipients,
      callback: function callback($$v) {
        _vm.copyToRecipients = $$v;
      },
      expression: "copyToRecipients"
    }
  }), _vm.errors.copyToRecipients ? _c('div', {
    staticClass: "invalid-description"
  }, [_vm._v(_vm._s(_vm.errors.copyToRecipients))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Bcc:")]), _c('div', {
    staticClass: "input"
  }, [_c('v-select', {
    attrs: {
      "id": "blind-copy-to",
      "label": "blind-copy-to",
      "options": _vm.availableRecipients,
      "get-option-label": function getOptionLabel(o) {
        return o.name ? "".concat(o.name, " <").concat(o.email, ">") : o.email;
      },
      "create-option": _vm.parseOption,
      "multiple": "",
      "taggable": ""
    },
    model: {
      value: _vm.blindCopyToRecipients,
      callback: function callback($$v) {
        _vm.blindCopyToRecipients = $$v;
      },
      expression: "blindCopyToRecipients"
    }
  }), _vm.errors.blindCopyToRecipients ? _c('div', {
    staticClass: "invalid-description"
  }, [_vm._v(_vm._s(_vm.errors.blindCopyToRecipients))]) : _vm._e()], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Betreff:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.subject,
      expression: "subject"
    }],
    staticClass: "input",
    attrs: {
      "id": "subject"
    },
    domProps: {
      "value": _vm.subject
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.subject = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "input"
  }, [_c('tip-tap', {
    attrs: {
      "content": this,
      "ckey": "content"
    }
  })], 1)]), _c('div', {
    staticClass: "input-field top"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Anhang")]), _c('div', {
    staticClass: "input"
  }, [_c('sharepoint-file-selector', {
    staticClass: "input",
    model: {
      value: _vm.attachments,
      callback: function callback($$v) {
        _vm.attachments = $$v;
      },
      expression: "attachments"
    }
  }), _c('sharepoint-dropzone', {
    attrs: {
      "directory": "Auftragsunterlagen",
      "commission": _vm.crmAkt
    },
    on: {
      "file-uploaded": _vm.appendAttachment
    }
  })], 1)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "input"
  }, [!_vm.sending ? _c('button', {
    staticClass: "btn _mid _bordered",
    on: {
      "click": function click($event) {
        return _vm.send();
      }
    }
  }, [_c('span', [_vm._v("Senden")])]) : _c('i', {
    staticClass: "loading-spinner"
  })])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };