import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "crm-allgemein"
  }, [_vm.commission ? _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-content"
  }, [_c('div', {
    staticClass: "inlined-content"
  }, [_c('check-fee-note', {
    attrs: {
      "commission": _vm.commission
    }
  }), _c('div', {
    staticClass: "input-field"
  }, [_vm._isAdmin ? _c('v-select', {
    staticClass: "input",
    attrs: {
      "value": "".concat(_vm.commission.status),
      "label": "name",
      "options": _vm._filteredStatusList,
      "reduce": function reduce(o) {
        return o.id;
      },
      "clearable": false
    },
    on: {
      "input": function input($event) {
        _vm.commission.status = parseInt($event);
      }
    }
  }) : _c('div', {
    staticClass: "input bold"
  }, [_vm._v(_vm._s(_vm.statusName))])], 1), _c('ChangeUserToAkt', {
    key: _vm.commission.id,
    attrs: {
      "aktId": _vm.commission.id,
      "main-user-id": (_vm.commission.assignedAppraiser || {}).id
    },
    on: {
      "userChanged": function userChanged($event) {
        return _vm.$emit('refreshCrmAkt');
      }
    }
  }), _c('shared-with-users', {
    attrs: {
      "users": _vm.sharedUsers
    }
  }), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('visit-address', {
    attrs: {
      "value": _vm.commission.visitAddress,
      "commission-id": _vm.commission.id
    }
  }), _c('div', {
    staticClass: "spacer _vertical _small"
  })], 1), _c('input-field', {
    attrs: {
      "label": "Versicherungssparte:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.insuranceLineInfo))])]), _c('input-field', {
    attrs: {
      "label": "Wichtige Hinweise:"
    }
  }, [_vm._isAdmin ? _c('TextareaAutosize', {
    staticClass: "input",
    attrs: {
      "value": _vm.commission.info,
      "minRows": 3,
      "maxRows": 10
    },
    on: {
      "input": function input($event) {
        _vm.commission.info = $event;
      }
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.info))])], 1), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Kontaktperson")]), _c('input-field', {
    attrs: {
      "label": "Kontaktoption:"
    }
  }, [_c('v-select', {
    staticClass: "input",
    attrs: {
      "reduce": function reduce(x) {
        return x.id;
      },
      "clearable": false,
      "options": _vm.contactModeOptions
    },
    model: {
      value: _vm.commission.contactMode,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "contactMode", $$v);
      },
      expression: "commission.contactMode"
    }
  })], 1), _vm.modeContactPerson ? _c('input-field', {
    attrs: {
      "label": "Kontaktperson:"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.commission.contactPerson,
      expression: "commission.contactPerson"
    }],
    staticClass: "input",
    domProps: {
      "value": _vm.commission.contactPerson
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.commission, "contactPerson", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('input-field', {
    attrs: {
      "label": "Persönliche Notizen"
    }
  }, [_c('TextareaAutosize', {
    staticClass: "input",
    attrs: {
      "value": _vm.userMetadata.notes,
      "minRows": 3,
      "maxRows": 10,
      "placeholder": "Hier können Sie private Notizen ablegen, die nur von Ihnen gelesen werden können.\nGeteilte Notizen können Sie im Tab \"Journal / Notizen\" ablegen"
    },
    on: {
      "input": function input($event) {
        _vm.userMetadata.notes = $event;
        _vm.notesChanged();
      }
    }
  })], 1), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Versicherungsnehmer")]), _vm.commission.insuree ? [_c('input-field', {
    attrs: {
      "label": "Name:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm.commission.insuree.display ? [_vm._v(_vm._s(_vm.commission.insuree.display))] : [_vm._v(_vm._s(_vm.commission.insuree.title) + " " + _vm._s(_vm.commission.insuree.firstname) + " " + _vm._s(_vm.commission.insuree.lastname))]], 2)]), _c('input-field', {
    attrs: {
      "label": "Email:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.insuree.email))])]), _c('input-field', {
    attrs: {
      "label": "Telefon:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s([_vm.commission.insuree.phone, _vm.commission.insuree.mobile].filter(function (x) {
    return x;
  }).join('; ')))])])] : _c('div'), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Geschädigte")]), _vm._l(_vm.commission.aggrievedParties, function (party) {
    return party.value ? [party.value.display ? _c('h5', {
      staticClass: "underline"
    }, [_vm._v(_vm._s(party.value.display))]) : _c('h5', {
      staticClass: "underline"
    }, [_vm._v(_vm._s(party.value.title) + " " + _vm._s(party.value.firstname) + " " + _vm._s(party.value.lastname))]), _c('input-field', {
      attrs: {
        "label": "Email:"
      }
    }, [_c('div', {
      staticClass: "input"
    }, [_vm._v(_vm._s(party.value.email))])]), _c('input-field', {
      attrs: {
        "label": "Telefon:"
      }
    }, [_c('div', {
      staticClass: "input"
    }, [_vm._v(_vm._s([party.value.phone, party.value.mobile].filter(function (x) {
      return x;
    }).join('; ')))])])] : _vm._e();
  }), !_vm.commission.aggrievedParties.length ? _c('div', [_vm._v("keine Daten verfügbar")]) : _vm._e(), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Referent")]), _vm.commission.referee ? [_c('input-field', {
    attrs: {
      "label": "Name:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.referee.title) + " " + _vm._s(_vm.commission.referee.firstname) + " " + _vm._s(_vm.commission.referee.lastname))])]), _c('input-field', {
    attrs: {
      "label": "Email:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.referee.email))])]), _c('input-field', {
    attrs: {
      "label": "Telefon:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.referee.phone))])]), !_vm.refereeEqualsInsurant ? _c('input-field', {
    attrs: {
      "label": "Geschäftsbeziehung:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.referee.employedAt.name))])]) : _vm._e()] : _c('div', [_vm._v("keine Daten verfügbar")]), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Auftraggeber")]), _vm.commission.insurant ? [_c('input-field', {
    attrs: {
      "label": "Name"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.insurant.name))])]), _c('input-field', {
    attrs: {
      "label": "Email: "
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.insurant.email))])]), _c('input-field', {
    attrs: {
      "label": "Telefon:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s([_vm.commission.insurant.phone1, _vm.commission.insurant.phone2].filter(function (x) {
    return x;
  }).join('; ')))])])] : _c('div', [_vm._v("keine Daten verfügbar")]), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Makler")]), _vm.commission.broker ? [_c('input-field', {
    attrs: {
      "label": "Name:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.broker.title) + " " + _vm._s(_vm.commission.broker.firstname) + " " + _vm._s(_vm.commission.broker.lastname))])]), _c('input-field', {
    attrs: {
      "label": "Email:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.broker.email))])]), _c('input-field', {
    attrs: {
      "label": "Telefon:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.broker.phone))])])] : _c('div', [_vm._v("keine Daten verfügbar")]), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Maklerorganisation")]), _vm.commission.brokerCompany ? [_c('input-field', {
    attrs: {
      "label": "Name"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.brokerCompany.name))])]), _c('input-field', {
    attrs: {
      "label": "Email: "
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.brokerCompany.email))])]), _c('input-field', {
    attrs: {
      "label": "Telefon:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s([_vm.commission.brokerCompany.phone1, _vm.commission.brokerCompany.phone2].filter(function (x) {
    return x;
  }).join('; ')))])])] : _c('div', [_vm._v("keine Daten verfügbar")]), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('input-field', {
    attrs: {
      "label": "Auftragsnummer:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.orderNumber))])]), _c('input-field', {
    attrs: {
      "label": "Auftragsdatum:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.commission.orderDate).format('LLLL')))])]), _c('input-field', {
    attrs: {
      "label": "Schadennummer:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.damageNumber))])]), _c('input-field', {
    attrs: {
      "label": "Schadendatum:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.commission.damageDate).format('LLLL')))])]), _c('input-field', {
    attrs: {
      "label": "Polizzennummer:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.insurancePolicyNumber))])]), _c('input-field', {
    attrs: {
      "label": "Art des Auftrags:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commissionKind))])]), _c('input-field', {
    attrs: {
      "label": "Consense gewünscht"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.consenseRequested))])]), _c('input-field', {
    attrs: {
      "label": "Dringende Kontaktaufnahme:"
    }
  }, [_vm._isAdmin ? _c('toggle-button', {
    staticClass: "input",
    attrs: {
      "color": "#ee5555",
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: _vm.commission.urgent,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "urgent", $$v);
      },
      expression: "commission.urgent"
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.urgent ? 'Ja' : 'Nein'))])], 1), _c('input-field', {
    attrs: {
      "label": "Urgenz:"
    }
  }, [_vm._isAdmin ? _c('toggle-button', {
    staticClass: "input",
    attrs: {
      "color": "#ee5555",
      "sync": true,
      "labels": {
        checked: 'JA',
        unchecked: 'NEIN'
      }
    },
    model: {
      value: _vm.commission.urgencyRaised,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "urgencyRaised", $$v);
      },
      expression: "commission.urgencyRaised"
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.urgent ? 'Ja' : 'Nein'))]), _c('div', [_vm.postUrgencyInProgress ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e(), _vm._v("Anzahl Urgenzen: " + _vm._s(_vm.commission.raisedUrgencies.length)), _vm._isAdmin && _vm.commission.urgencyRaised ? _c('span', [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.postUrgency
    }
  }, [_vm._v("Neue Urgenz ablegen")])]) : _vm._e()])], 1), _c('input-field', {
    attrs: {
      "label": "Besichtigungsdatum"
    }
  }, [_vm._isAdmin ? _c('div', {
    staticClass: "input"
  }, [_c('crm-date-native', {
    model: {
      value: _vm.commission.visitDate,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "visitDate", $$v);
      },
      expression: "commission.visitDate"
    }
  })], 1) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.visitDate ? _vm.$dayjs(_vm.commission.visitDate).format('LLLL') : 'kein Datum'))])]), _c('input-field', {
    attrs: {
      "label": "Erledigungsdatum"
    }
  }, [_vm._isAdmin ? _c('div', {
    staticClass: "input"
  }, [_c('crm-date-native', {
    model: {
      value: _vm.commission.completionDate,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "completionDate", $$v);
      },
      expression: "commission.completionDate"
    }
  })], 1) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.completionDate ? _vm.$dayjs(_vm.commission.completionDate).format('LLLL') : 'kein Datum'))])]), _c('input-field', {
    attrs: {
      "label": "Erstellt am:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.commission.createdAt).format('LLLL')))])]), _c('input-field', {
    attrs: {
      "label": "Geändert am:"
    }
  }, [_c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.commission.updatedAt).format('LLLL')))])]), _c('input-field', {
    attrs: {
      "label": "Kostenstelle:"
    }
  }, [_vm._isAdmin ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.commission.costCenter,
      expression: "commission.costCenter"
    }],
    staticClass: "input",
    domProps: {
      "value": _vm.commission.costCenter
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.commission, "costCenter", $event.target.value);
      }
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.commission.costCenter))])]), _c('input-field', {
    attrs: {
      "label": "Honorar Für:"
    }
  }, [_vm._isAdmin ? _c('v-select', {
    staticClass: "input",
    attrs: {
      "options": _vm.feeForOptions,
      "reduce": function reduce(x) {
        return x.id;
      },
      "clearable": false
    },
    model: {
      value: _vm.commission.feeFor,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "feeFor", $$v);
      },
      expression: "commission.feeFor"
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.feeFor))])], 1), _c('input-field', {
    attrs: {
      "label": "Erhöhungsanfrage"
    }
  }, [_vm._isAdmin ? _c('v-select', {
    staticClass: "input",
    attrs: {
      "options": _vm.increaseRequestedOptions,
      "reduce": function reduce(x) {
        return x.id;
      },
      "clearable": false
    },
    model: {
      value: _vm.commission.increaseRequested,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "increaseRequested", $$v);
      },
      expression: "commission.increaseRequested"
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.increaseRequested))])], 1), _c('input-field', {
    attrs: {
      "label": "Besonderheiten zum Auftraggeber"
    }
  }, [_vm._isAdmin ? _c('v-select', {
    staticClass: "input",
    attrs: {
      "options": _vm.additionalInfoOptions,
      "reduce": function reduce(x) {
        return x.id;
      },
      "clearable": false
    },
    model: {
      value: _vm.commission.additionalInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "additionalInfo", $$v);
      },
      expression: "commission.additionalInfo"
    }
  }) : _c('div', {
    staticClass: "input"
  }, [_vm._v(_vm._s(_vm.additionalInfo))])], 1), _c('input-field', {
    attrs: {
      "label": "Schadenmerkmal"
    }
  }, [_c('v-select', {
    staticClass: "input",
    attrs: {
      "options": _vm.damageFeatureOptions,
      "reduce": function reduce(x) {
        return x.id;
      },
      "clearable": true
    },
    model: {
      value: _vm.commission.damageFeature,
      callback: function callback($$v) {
        _vm.$set(_vm.commission, "damageFeature", $$v);
      },
      expression: "commission.damageFeature"
    }
  })], 1)], 2)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };