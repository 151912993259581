var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', {
    staticClass: "attachments"
  }, [_c('h5', [_vm._v("ANLAGEN")]), _vm.showAttachmentSummaryToggle ? [_c('block-editor-toggle', {
    attrs: {
      "label": "Anlagenverzeichnis anzeigen",
      "template": _vm.content.showAttachmentSummary,
      "label-checked": "Ja",
      "label-unchecked": "Nein"
    }
  })] : _vm._e(), _vm.content.attachments.value.data.length === 0 ? _c('div', {
    staticClass: "tac"
  }, [_vm._v("Ausgewählte PDF können an das Gutachten angehängt werden:")]) : _vm._e(), _c('drop-list', {
    staticClass: "flex gaAtts__list",
    attrs: {
      "items": _vm.content.attachments.value.data,
      "mode": "cut"
    },
    on: {
      "reorder": function reorder($event) {
        return $event.apply(_vm.content.attachments.value.data);
      },
      "insert": _vm.cut
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
          reorder = _ref.reorder;
        return [_c('drag', {
          key: item.id,
          staticClass: "gaAtts__listIt",
          attrs: {
            "data": item
          },
          on: {
            "cut": _vm.cut
          }
        }, [_c('div', {
          staticClass: "gaAtts__img",
          style: _vm.makeImgBgStyle(item.id),
          attrs: {
            "width": "80px"
          }
        }), _c('div', {
          staticClass: "gaAtts__delBtn g-hover",
          on: {
            "click": function click($event) {
              return _vm.removeImage(item.id);
            }
          }
        }, [_c('i', {
          staticClass: "icon-times inline"
        })]), _c('div', {
          staticClass: "gaAtts__caption"
        }, [_c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: item.caption,
            expression: "item.caption"
          }],
          staticClass: "input",
          attrs: {
            "placeholder": "Beschreibung"
          },
          domProps: {
            "value": item.caption
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(item, "caption", $event.target.value);
            }
          }
        })])])];
      }
    }])
  }), _c('div', {
    staticClass: "gaAtts__addIt"
  }, [_c('a', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ChooseImagesForBlock', {
          content: _vm.content.attachments.value,
          onlyFormat: ['pdf']
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })])])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };