import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('block-editor-input', {
    attrs: {
      "display": _vm.display,
      "label": _vm.label
    },
    scopedSlots: _vm._u([_vm.$slots.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._t("label")];
      },
      proxy: true
    } : null, {
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "input"
        }, [_c('div', {
          staticClass: "input-field"
        }, [_vm._l(_vm.template.value.options, function (opt) {
          return _c('span', [_c('v-checkbox', {
            attrs: {
              "rctype": _vm.type,
              "value": _vm.type === 'radio' ? _vm.template.value.selected : _vm.template.value.checked,
              "item": opt.value,
              "label": opt.name
            },
            on: {
              "input": _vm.update
            }
          })], 1);
        }), !_vm.noAnnotation ? _c('label', {
          staticClass: "input-field"
        }, [_c('div', {
          staticClass: "label _small"
        }, [_vm._v("Anmerkung:")]), _c('div', {
          staticClass: "input"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.template.value.annotation,
            expression: "template.value.annotation"
          }],
          class: {
            invalid: _vm.annotationValid !== true
          },
          attrs: {
            "type": "text"
          },
          domProps: {
            "value": _vm.template.value.annotation
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.template.value, "annotation", $event.target.value);
            }
          }
        }), _vm.annotationValid !== true ? _c('div', {
          staticClass: "invalid-description"
        }, [_vm._v(_vm._s(_vm.annotationValid))]) : _vm._e()])]) : _vm._e()], 2)])];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };