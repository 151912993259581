var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("OBJEKTBESCHREIBUNG"), _c('toggle-button', {
    staticClass: "ml-1",
    attrs: {
      "value": _vm.content.findings.$type === 'building',
      "width": 120,
      "height": 23,
      "color": "#5e88ad",
      "labels": {
        checked: 'Gebäude',
        unchecked: 'Schadensbereich'
      }
    },
    on: {
      "input": _vm.onTypeChanged
    }
  })], 1), _vm.content.findings.$type === 'building' ? [_c('block-editor-radiocheck', {
    attrs: {
      "label": "Gebäude-Nutzung:",
      "template": _vm.content.findings.buildingUsage,
      "options": _vm.template.gebaeudenutzung
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Lage:",
      "template": _vm.content.findings.site,
      "options": _vm.template.lage
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Bauweise",
      "template": _vm.content.findings.structure,
      "options": _vm.template.bauweise
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Dach",
      "template": _vm.content.findings.roofing,
      "options": _vm.template.dachdeckung
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Errichtung-Sanierung:",
      "template": _vm.content.findings.construction
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Zustand / Wartung:",
      "template": _vm.content.findings.condition,
      "type": "radio"
    }
  })] : _vm.content.findings.$type === 'area' ? [_c('block-editor-textarea', {
    attrs: {
      "label": "Lage",
      "template": _vm.content.findings.site
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Gegebenheiten vor Ort",
      "template": _vm.content.findings.circumstancesOnSite
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };