import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("Gebäude")]), _c('div', {
    staticClass: "padding"
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "Übersicht/ Beschreibung",
      "template": _vm.content.introText
    }
  }), _c('br'), _c('div', {
    staticClass: "rel wz-tabs sticky top0"
  }, [_vm._l(_vm.content.buildings.value, function (building, ix) {
    return [_c('div', {
      staticClass: "wz-tab",
      class: {
        _selected: _vm.selectedBidx == ix
      },
      on: {
        "click": function click($event) {
          _vm.selectedBidx = ix;
        }
      }
    }, [_vm._v(_vm._s(building[0].values[0].value))])];
  }), _c('div', {
    staticClass: "wz-tab",
    on: {
      "click": function click($event) {
        return _vm.addNewBuilding();
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  })])], 2), _c('div', {
    staticClass: "padding"
  }), _vm.content.buildings.value[_vm.selectedBidx] ? _c('div', [_vm._l([_vm.content.buildings.value[_vm.selectedBidx]], function (building, ix) {
    return _vm._l(building, function (bBlock, idx) {
      var _building$, _building$$values, _building$$values$;
      return _c('div', {
        staticClass: "side-border clear"
      }, [_c('div', {
        staticClass: "clear"
      }), _c('div', {
        staticClass: "sticky top2"
      }, [_c('h2', {
        staticClass: "p-0 clear link cursor-pointer",
        on: {
          "click": function click($event) {
            return _vm.openAccordion(idx);
          }
        }
      }, [_vm._v(" " + _vm._s(bBlock.title) + " ")])]), bBlock.key == 'root' ? _c('div', {
        staticClass: "clear"
      }, [_c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.accordion[idx],
          expression: "accordion[idx]"
        }],
        staticClass: "clear"
      }, [_c('label', {
        staticClass: "input-field"
      }, [_c('div', {
        staticClass: "label"
      }, [_vm._v(_vm._s(bBlock.values[0].name) + ": ")]), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: bBlock.values[0].value,
          expression: "bBlock.values[0].value"
        }],
        staticClass: "input",
        attrs: {
          "type": "text"
        },
        domProps: {
          "value": bBlock.values[0].value
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(bBlock.values[0], "value", $event.target.value);
          }
        }
      }), _c('v-popover', {
        attrs: {
          "offset": "0",
          "position": "right"
        }
      }, [_c('button', {
        staticClass: "btn _clear nomargin"
      }, [_c('i', {
        staticClass: "icon-trashbin _redp"
      })]), _c('template', {
        staticClass: "new-block-tt",
        slot: "popover"
      }, [_c('a', {
        directives: [{
          name: "close-popover",
          rawName: "v-close-popover"
        }],
        staticClass: "link color-red",
        on: {
          "click": function click($event) {
            return _vm.removeLine(_vm.selectedBidx);
          }
        }
      }, [_vm._v("Entfernen?")])])], 2)], 1), bBlock.values[0] ? _c('div', {
        staticClass: "clear"
      }, [_c('h4', [_vm._v("Übersichtsbild (max 1x) mit Beschreibung")]), _c('ImagesSelector', {
        attrs: {
          "content": bBlock.values[0]
        }
      })], 1) : _vm._e(), _c('div', [_c('block-editor-textarea', {
        attrs: {
          "label": "Beschreibung",
          "template": bBlock.values[0].description
        }
      })], 1), bBlock.values[1] ? _c('div', {
        staticClass: "clear"
      }, [_c('h4', [_vm._v("Beschreibungsbilter (max 2x)")]), _c('ImagesSelector', {
        attrs: {
          "content": bBlock.values[1]
        }
      })], 1) : _vm._e()])]) : bBlock.key === 'specialities' ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.accordion[idx],
          expression: "accordion[idx]"
        }],
        staticClass: "clear"
      }, [_c('block-editor-textarea', {
        attrs: {
          "label": "",
          "template": bBlock.value
        }
      })], 1) : !['images', 'calculation', 'specialities'].includes(bBlock.key) ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.accordion[idx],
          expression: "accordion[idx]"
        }]
      }, [_vm._l(bBlock.values, function (bValue, bidx) {
        var _bBlock$values;
        return _c('div', {
          staticClass: "clear"
        }, [bValue.type == 'select' ? _c('div', {
          staticClass: "overflow-auto"
        }, [_c('h4', {
          staticClass: "mt-4"
        }, [_vm._v(_vm._s(bValue.name))]), bBlock.key == 'usage' ? _c('div', {
          staticClass: "clear"
        }, [_c('label', {
          staticClass: "input-field"
        }, [_c('div', {
          staticClass: "label bold"
        }, [_vm._v("Bez.: ")]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: bValue.name,
            expression: "bValue.name"
          }],
          staticClass: "input _medium",
          attrs: {
            "type": "text"
          },
          domProps: {
            "value": bValue.name
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(bValue, "name", $event.target.value);
            }
          }
        }), _c('button', {
          staticClass: "btn _clear",
          on: {
            "click": function click($event) {
              return bBlock.values.splice(bidx, 1);
            }
          }
        }, [_c('i', {
          staticClass: "icon-trashbin _redp"
        })]), bBlock.key == 'usage' ? _c('span', [_c('v-popover', {
          staticClass: "configbtn _hover",
          attrs: {
            "offset": "3",
            "placement": "left"
          }
        }, [_c('button', {
          staticClass: "btn _small _clear color-red"
        }, [_c('i', {
          staticClass: "icon-sortarrows"
        })]), _c('template', {
          staticClass: "new-block-tt",
          slot: "popover"
        }, [_c('ul', {
          staticClass: "tac color-red"
        }, [idx > 0 ? _c('li', {
          staticClass: "choose-block-li padding _small"
        }, [_c('a', {
          directives: [{
            name: "close-popover",
            rawName: "v-close-popover"
          }],
          staticClass: "link color-red",
          on: {
            "click": function click($event) {
              return _vm.moveUp(ix, idx, bidx);
            }
          }
        }, [_vm._v("nach oben")])]) : _vm._e(), idx < ((_bBlock$values = bBlock.values) === null || _bBlock$values === void 0 ? void 0 : _bBlock$values.length) - 1 ? _c('li', {
          staticClass: "choose-block-li padding _small"
        }, [_c('a', {
          directives: [{
            name: "close-popover",
            rawName: "v-close-popover"
          }],
          staticClass: "link color-red",
          on: {
            "click": function click($event) {
              return _vm.moveDown(ix, idx, bidx);
            }
          }
        }, [_vm._v("nach unten")])]) : _vm._e()])])], 2)], 1) : _vm._e()])]) : _vm._e(), _vm.valuesTypes ? _c('div', {
          staticClass: "list_value flex"
        }, [_vm._l(_vm.customValueTypes(bValue.value, _vm.valuesTypes[bValue.selectKey]), function (sValue, vidx) {
          return _c('label', {
            key: "label_".concat(bValue.selectKey, "_").concat(idx, "_").concat(bidx, "_").concat(vidx),
            staticClass: "list_value_item",
            class: {
              _sel: bValue.value.includes(sValue.value),
              custom: sValue.custom
            }
          }, [_c('div', [((bValue === null || bValue === void 0 ? void 0 : bValue.value) || []).includes(sValue.value) ? _c('i', {
            staticClass: "icon-check"
          }) : _c('i', {
            staticClass: "icon-circle"
          }), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: bValue.value,
              expression: "bValue.value"
            }],
            staticClass: "check",
            attrs: {
              "type": "checkbox"
            },
            domProps: {
              "value": sValue.value,
              "checked": Array.isArray(bValue.value) ? _vm._i(bValue.value, sValue.value) > -1 : bValue.value
            },
            on: {
              "change": function change($event) {
                var $$a = bValue.value,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = sValue.value,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(bValue, "value", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(bValue, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(bValue, "value", $$c);
                }
              }
            }
          }), _c('span', [_vm._v(_vm._s(sValue.value))])])]);
        }), _c('label', {
          staticClass: "list_value_item _sel addnew",
          on: {
            "click": function click($event) {
              return _vm.addNewField(ix, idx, bidx);
            }
          }
        }, [_vm._m(0, true)])], 2) : _vm._e()]) : _vm._e(), bValue.type == 'number' ? _c('div', [_c('label', {
          staticClass: "input-field"
        }, [_c('div', {
          staticClass: "label bold"
        }, [_vm._v(_vm._s(bValue.name))]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: bValue.value,
            expression: "bValue.value"
          }],
          staticClass: "input _medium",
          attrs: {
            "type": "number"
          },
          domProps: {
            "value": bValue.value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(bValue, "value", $event.target.value);
            }
          }
        })])]) : bValue.type == 'text' ? _c('div', [_c('label', {
          staticClass: "input-field"
        }, [_c('div', {
          staticClass: "label bold"
        }, [_vm._v(_vm._s(bValue.name))]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: bValue.value,
            expression: "bValue.value"
          }],
          staticClass: "input _medium",
          attrs: {
            "type": "text"
          },
          domProps: {
            "value": bValue.value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(bValue, "value", $event.target.value);
            }
          }
        })])]) : _vm._e()]);
      }), bBlock.key == 'usage' ? _c('div', {
        staticClass: "clear"
      }, [_c('div', {
        staticClass: "padding mt-4"
      }, [_c('button', {
        staticClass: "btn",
        on: {
          "click": function click($event) {
            return bBlock.values.push({
              'name': 'Geschoß',
              'value': [],
              'type': 'select',
              key: 'usage',
              'selectKey': 'floors'
            });
          }
        }
      }, [_c('span', [_vm._v("Geschoß hinzufügen ")]), _c('i', {
        staticClass: "icon-add2"
      })]), _c('button', {
        staticClass: "btn",
        on: {
          "click": function click($event) {
            return bBlock.values.push({
              'name': 'Dach',
              'value': [],
              'type': 'select',
              key: 'usage',
              'selectKey': 'roof'
            });
          }
        }
      }, [_c('span', [_vm._v("Dach hinzufügen ")]), _c('i', {
        staticClass: "icon-add2"
      })])]), _c('div', {
        staticClass: "padding"
      })]) : _vm._e()], 2) : bBlock.key === 'calculation' ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.accordion[idx],
          expression: "accordion[idx]"
        }],
        staticClass: "clear"
      }, [_c('BECalculation', {
        attrs: {
          "content": bBlock,
          "titlePreset": (building === null || building === void 0 ? void 0 : (_building$ = building[0]) === null || _building$ === void 0 ? void 0 : (_building$$values = _building$.values) === null || _building$$values === void 0 ? void 0 : (_building$$values$ = _building$$values[0]) === null || _building$$values$ === void 0 ? void 0 : _building$$values$.value) || ''
        },
        on: {
          "update:content": function updateContent($event) {
            bBlock = $event;
          },
          "updateCalcResults": function updateCalcResults($event) {
            return _vm.updateCalcResults($event, idx);
          }
        }
      })], 1) : bBlock.key === 'images' ? _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.accordion[idx],
          expression: "accordion[idx]"
        }],
        staticClass: "clear"
      }, [_c('ImagesSelector', {
        attrs: {
          "content": bBlock.values[0],
          "blockedImages": _vm.blockedImages
        }
      })], 1) : _vm._e()]);
    });
  })], 2) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('i', {
    staticClass: "icon-add2"
  })]);
}];
export { render, staticRenderFns };