var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-selector"
  }, [_c('ul', _vm._l(_vm.preBlocks, function (item) {
    return _c('li', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      key: item.key,
      staticClass: "choose-block-li padding _small",
      on: {
        "click": function click($event) {
          return _vm.emitBlock(item.key);
        }
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };