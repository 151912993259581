import "core-js/modules/es.array.splice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor gaAtts"
  }, [_c('h3', [_vm._v("GRUNDLAGEN")]), _c('attachments-section', {
    attrs: {
      "content": _vm.content
    }
  }), _c('present-at-assessment-section', {
    attrs: {
      "content": _vm.content
    }
  }), _c('h5', [_vm._v("Weitere Kontakte")]), _c('block-editor-toggle', {
    attrs: {
      "label": "In PDF anzeigen",
      "template": _vm.content.showOtherContactsInPdf,
      "label-checked": "Ja",
      "label-unchecked": "Nein"
    }
  }), _c('contact-list', {
    attrs: {
      "contacts": _vm.content.otherContacts
    },
    on: {
      "add": _vm.addOtherContact,
      "remove": function remove($event) {
        return _vm.content.otherContacts.splice($event, 1);
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };