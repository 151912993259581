var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("SANIERUNGSMASSNAHMEN")]), _c('block-editor-tip-tap', {
    attrs: {
      "label": "SANIERUNGS-\nMASSNAHMEN",
      "template": _vm.content.renovationMeasures
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };