var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("Grundlagen Texte")]), _c('block-editor-tip-tap', {
    attrs: {
      "label": "Allgemein",
      "template": _vm.content.general
    }
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "Gebäude",
      "template": _vm.content.buildings
    }
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "Wertermittlung",
      "template": _vm.content.valuation
    }
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "Unterlagen - Anlagen",
      "template": _vm.content.attachments
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };