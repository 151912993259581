var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("Eckdaten zur Liegenschaft")]), _c('block-editor-text-input', {
    attrs: {
      "label": "Gebäudebezeichnung",
      "template": _vm.content.buildingLabel
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Bautyp",
      "template": _vm.content.buildingType
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Baujahr/Bauperiode",
      "template": _vm.content.buildingYear
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Letzte Renovierung",
      "template": _vm.content.lastRenovation
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Lage",
      "template": _vm.content.location
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Nutzung",
      "template": _vm.content.usage
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Wohnungen",
      "template": _vm.content.apartments
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Gewerbe",
      "template": _vm.content.business
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Bauweise",
      "template": _vm.content.construction
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Denkmalschutz",
      "template": _vm.content.monumentProtection
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Besonderheit",
      "template": _vm.content.specialities
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Liegenschaft",
      "template": _vm.content.property
    }
  }), _c('h3', [_vm._v("Eckdaten zum Gebäude")]), _c('block-editor-text-input', {
    attrs: {
      "label": "Gebäudeanzahl",
      "template": _vm.content.buildingCount
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Geschosse",
      "template": _vm.content.floors
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Außenanlagen",
      "template": _vm.content.outside
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };