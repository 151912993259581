import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "assignUserToAktModal"
  }, [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "AssignUserToAktModal",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("SV zum Akt verwalten"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('AssignUserToAktModal');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_vm.isLoading || _vm.isLoadingAkt ? _c('div', {
    staticClass: "fullScreenLoader"
  }, [_c('span', {
    staticClass: "loading-spinner large"
  })]) : _vm._e(), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("SV zugeordnet")]), _c('ul', {
    staticClass: "input"
  }, [_c('li', {
    staticClass: "_flexItem"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s((_vm._svNamesByCrmContactId[_vm.mainUserId] || {}).name))])]), _vm._l(_vm.sharedUsers, function (u) {
    return u !== _vm.mainUserId ? _c('li', {
      staticClass: "_flexItem"
    }, [_c('span', [_vm._v(_vm._s((_vm._svNamesByCrmContactId[u] || {}).name))]), _c('a', {
      staticClass: "link color-red",
      on: {
        "click": function click($event) {
          !_vm.isLoadingAkt && _vm.deAssignUserToAkt(u);
        }
      }
    }, [_c('i', {
      staticClass: "icon-times"
    })])]) : _vm._e();
  })], 2)]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("SV neu hinzufügen")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedUser,
      expression: "selectedUser"
    }],
    staticClass: "input",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedUser = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(Object.values(_vm._svNamesByCrmContactId), function (s) {
    return _c('option', {
      domProps: {
        "value": s
      }
    }, [_vm._v(_vm._s(s.name))]);
  }), 0)]), _c('div', {
    staticClass: "spacer _vertical"
  }), _vm.sharedUsers.includes(_vm.selectedUser.id) ? _c('div', {
    staticClass: "tac"
  }, [_vm._v("SV bereits zugeordnet")]) : _c('div', {
    staticClass: "tac"
  }, [_c('button', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        _vm.selectedUser.id && !_vm.isLoadingAkt && _vm.assignUserToAkt();
      }
    }
  }, [_vm._v("Hinzufügen")])])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('AssignUserToAktModal');
      }
    }
  }, [_vm._v("Schließen")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };