var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor ga-attachments"
  }, [_vm.content.attachments.value.data.length === 0 ? _c('div', {
    staticClass: "tac"
  }, [_vm._v("Ausgewählte PDF können an das Gutachten angehängt werden:")]) : _vm._e(), _c('ul', {
    staticClass: "gaAtts__list"
  }, [_vm._l(_vm.content.attachments.value.data, function (img, ix) {
    return _c('li', {
      staticClass: "gaAtts__listIt"
    }, [_c('div', {
      staticClass: "gaAtts__img",
      style: _vm.makeImgBgStyle(img.id),
      attrs: {
        "width": "80px"
      }
    }), _c('div', {
      staticClass: "gaAtts__delBtn g-hover",
      on: {
        "click": function click($event) {
          return _vm.removeImage(ix);
        }
      }
    }, [_c('i', {
      staticClass: "icon-times inline"
    })])]);
  }), _c('li', {
    staticClass: "gaAtts__addIt"
  }, [_c('a', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ChooseImagesForBlock', {
          content: _vm.content.attachments.value,
          onlyFormat: ['pdf']
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })])])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };