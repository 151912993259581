var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("SCHADENHERGANG – SCHADENURSACHE")]), _c('block-editor-tip-tap', {
    attrs: {
      "label": "SCHADENHERGANG – SCHADENURSACHE",
      "template": _vm.content.damageDescription
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Zustand zum Zeitpunkt der Befundaufnahme:",
      "template": _vm.content.constructionState,
      "type": "radio"
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Schadenmindernde Maßnahmen:",
      "template": _vm.content.damagePreventionMeasures,
      "type": "radio"
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Bewohnbarkeit:",
      "template": _vm.content.habitability,
      "type": "radio"
    }
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "SCHÄDEN/MASSNAHMEN AM GEBÄUDE",
      "template": _vm.content.buildingDamages
    }
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "SCHÄDEN AM INHALT – INVENTAR",
      "template": _vm.content.furnishmentDamages
    }
  }), _c('block-editor-tip-tap', {
    attrs: {
      "label": "SONSTIGE INFORMATIONEN",
      "template": _vm.content.additionalInfo
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };