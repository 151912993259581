var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('h3', [_vm._v("Bilder (max: " + _vm._s(_vm.content.$maxImages) + ")")]), _c('ImagesBlock', {
    attrs: {
      "content": _vm.content,
      "blockedImages": _vm.blockedImages
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };