var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "tabs"
  }, [_vm._l(_vm.data, function (opt, idx) {
    return _c('div', {
      staticClass: "tabs__tab",
      class: {
        '-sel': _vm.selectionSync === idx
      },
      on: {
        "click": function click($event) {
          return _vm.onClick(idx);
        }
      }
    }, [_vm._t("head", function () {
      return [_vm._v(_vm._s(idx + 1))];
    }, {
      "data": opt,
      "idx": idx
    })], 2);
  }), _vm.$slots['add-popover'] ? _c('v-popover', {
    staticClass: "tabs__tab g-hover color-lightgrey",
    attrs: {
      "position": "right"
    }
  }, [_c('button', {
    staticClass: "btn _small _clear"
  }, [_c('i', {
    staticClass: "icon-add2"
  })]), _c('template', {
    staticClass: "new-block-tt",
    slot: "popover"
  }, [_vm._t("add-popover")], 2)], 2) : !_vm.noAdd ? _c('div', {
    staticClass: "tabs__tab",
    on: {
      "click": function click($event) {
        return _vm.$emit('add-clicked');
      }
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  })]) : _vm._e()], 2), _vm._l(_vm.data, function (opt, idx) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: idx === _vm.selectionSync,
        expression: "idx === selectionSync"
      }],
      key: idx
    }, [_vm._t("tab", null, {
      "data": opt,
      "idx": idx
    })], 2);
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };