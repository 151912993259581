import { render, staticRenderFns } from "./BlockSelector.vue?vue&type=template&id=6771afcb&scoped=true&lang=pug&"
import script from "./BlockSelector.vue?vue&type=script&lang=ts&"
export * from "./BlockSelector.vue?vue&type=script&lang=ts&"
import style0 from "./BlockSelector.vue?vue&type=style&index=0&id=6771afcb&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6771afcb",
  null
  
)

export default component.exports