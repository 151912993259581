var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v(_vm._s((_vm.content.title || {}).value1 || 'Besichtigungsbericht'))]), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Titelfeld",
      "options": _vm.template.titelfeld,
      "template": _vm.content.title,
      "no-annotation": ""
    }
  }), !_vm.content.title ? [_c('button', {
    staticClass: "btn _small",
    on: {
      "click": function click($event) {
        return _vm.addTitleField();
      }
    }
  }, [_vm._v("Titelfeld ändern")])] : _vm._e(), _c('block-editor-text-input', {
    attrs: {
      "label": "Bearbeiter",
      "template": _vm.content.editor
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Schadennummer",
      "template": _vm.content.damageNumber
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Versicherungsnehmer",
      "template": _vm.content.insurant
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Polizzennummer",
      "template": _vm.content.policyNumber
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Besichtigungsort",
      "template": _vm.content.inspectionLocation
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Schadendatum",
      "template": _vm.content.damageDate
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Besichtigungsdatum",
      "template": _vm.content.inspectionDate
    }
  }), !!_vm.content.includeUstInDamages.value ? _c('block-editor-options', {
    attrs: {
      "label": "Schadensumme",
      "template": _vm.content.includeUstInDamages,
      "type": "radio",
      "no-annotation": ""
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };