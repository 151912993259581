import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "crmHonorarNote"
  }, [_vm._isAdmin ? _c('div', [_c('h2', {
    staticClass: "padding"
  }, [_vm._v("Honorarnoten")]), _c('tabs', {
    attrs: {
      "data": _vm.hnNumberData,
      "selected": _vm.selectedHnIdx
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selectedHnIdx = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head",
      fn: function fn(_ref) {
        var data = _ref.data,
          idx = _ref.idx;
        return [_vm._v(_vm._s(data.new_honorarnr || ' --HN-NEU--'))];
      }
    }, !_vm.hasEmptyHnNr ? {
      key: "add-popover",
      fn: function fn() {
        return [_c('div', {
          staticClass: "new-block-tt",
          attrs: {
            "slot": "popover"
          },
          slot: "popover"
        }, [_c('ul', {
          directives: [{
            name: "close-popover",
            rawName: "v-close-popover"
          }],
          staticClass: "chooseList tac"
        }, [_c('li', {
          directives: [{
            name: "close-popover",
            rawName: "v-close-popover"
          }],
          staticClass: "chooseList_li color-blue",
          on: {
            "click": function click($event) {
              !_vm.isLoadingPrepareNewHN && _vm.crmCreateAssignHnToAkt();
            }
          }
        }, [_vm._v("Neue Honorarnote für Akt vorbereiten")])])])];
      },
      proxy: true
    } : null, {
      key: "tab",
      fn: function fn(_ref2) {
        var data = _ref2.data,
          idx = _ref2.idx;
        return [_c('honorarnote', {
          attrs: {
            "fee-note": data,
            "expenses": _vm.aufwandData.filter(function (x) {
              return x.new_honorarnr === data.new_honorarnr;
            }),
            "crm-akt": _vm.crmAkt,
            "positions": _vm.positions.filter(function (x) {
              return x.active;
            }),
            "tp-positions": _vm.tpPositions.filter(function (x) {
              return x.active;
            }),
            "has-empty-hn-nr": _vm.hasEmptyHnNr,
            "is-crm-aufwand-changed": _vm.isCrmAufwandChanged
          },
          on: {
            "refresh": function refresh($event) {
              return _vm.$emit('refresh');
            }
          }
        })];
      }
    }], null, true)
  }), _c('cost-positions', {
    attrs: {
      "positions": _vm.positions,
      "show-ust": _vm.theHnData.new_ustverrechnen,
      "ust": _vm.ustPercent
    }
  }), _c('activity-protocol', {
    attrs: {
      "tp-positions": _vm.tpPositions
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };