import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("Kostentabelle")]), _c('block-editor-toggle', {
    attrs: {
      "label": "Zeitwert automatisch berechnen",
      "template": _vm.content.calculatePresentValue,
      "label-checked": "EIN",
      "label-unchecked": "AUS"
    }
  }), _c('div', {
    staticClass: "ovauto"
  }, [_c('table', {
    staticClass: "table noborder"
  }, [_vm._m(0), _vm._l(_vm.content.data.value, function (v, ix) {
    return _c('tbody', [_c('tr', [_c('td', {
      staticClass: "td-input tac bold",
      attrs: {
        "colspan": "1"
      }
    }, [_vm._v(_vm._s(ix + 1))]), _c('td', {
      staticClass: "td-input",
      attrs: {
        "colspan": "6"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: v.title,
        expression: "v.title"
      }],
      staticClass: "bold _large",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": v.title
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(v, "title", $event.target.value);
        }
      }
    })]), _c('td', [_c('v-popover', {
      staticClass: "configbtn _hover",
      attrs: {
        "offset": "3",
        "placement": "right"
      }
    }, [_c('i', {
      staticClass: "icon-times color-red"
    }), _c('template', {
      staticClass: "new-block-tt",
      slot: "popover"
    }, [_c('ul', [_c('li', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      staticClass: "choose-block-li padding _small tac link color-red",
      on: {
        "click": function click($event) {
          return _vm.removePart(ix);
        }
      }
    }, [_vm._v("Teil Löschen?")])])])], 2)], 1)]), _vm._l(v.values, function (val, idx) {
      return _c('tr', [_c('td', {
        staticClass: "_verysmall"
      }, [_vm._v(_vm._s(ix + 1) + "." + _vm._s(idx + 1))]), _c('td', {
        staticClass: "td-input _large"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: val.bez,
          expression: "val.bez"
        }],
        attrs: {
          "type": "text"
        },
        domProps: {
          "value": val.bez
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(val, "bez", $event.target.value);
          }
        }
      })]), _c('td', {
        staticClass: "td-input _small"
      }, [_c('money', {
        staticClass: "tar",
        on: {
          "input": function input($event) {
            return _vm.updatePresentValue(val);
          }
        },
        model: {
          value: val.neuwert,
          callback: function callback($$v) {
            _vm.$set(val, "neuwert", $$v);
          },
          expression: "val.neuwert"
        }
      })], 1), _c('td', {
        staticClass: "td-input _small"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: val.prozent,
          expression: "val.prozent"
        }],
        staticClass: "tar",
        attrs: {
          "type": "number"
        },
        domProps: {
          "value": val.prozent
        },
        on: {
          "input": [function ($event) {
            if ($event.target.composing) return;
            _vm.$set(val, "prozent", $event.target.value);
          }, function ($event) {
            return _vm.updatePresentValue(val);
          }]
        }
      }), _c('div', {
        staticClass: "unit"
      }, [_vm._v("%")])]), _c('td', {
        staticClass: "td-input _small"
      }, [_vm.content.calculatePresentValue.value ? _c('div', {
        staticClass: "tr"
      }, [_vm._v(_vm._s(_vm.$_formatMoney(val.zeitwert || _vm.presentValueFromRow(val))))]) : _c('money', {
        staticClass: "tar",
        model: {
          value: val.zeitwert,
          callback: function callback($$v) {
            _vm.$set(val, "zeitwert", $$v);
          },
          expression: "val.zeitwert"
        }
      })], 1), _c('td', {
        staticClass: "td-input _small"
      }, [_c('money', {
        staticClass: "tar",
        model: {
          value: val.ablose,
          callback: function callback($$v) {
            _vm.$set(val, "ablose", $$v);
          },
          expression: "val.ablose"
        }
      })], 1), _c('td', {
        staticClass: "movingrows"
      }, [idx !== 0 ? _c('small', {
        staticClass: "link",
        on: {
          "click": function click($event) {
            return _vm.moveTableItemUp(ix, idx);
          }
        }
      }, [_c('i', {
        staticClass: "icon-arrow-top"
      })]) : _vm._e(), idx !== v.values.length - 1 ? _c('small', {
        staticClass: "link",
        on: {
          "click": function click($event) {
            return _vm.moveTableItemDown(ix, idx);
          }
        }
      }, [_c('i', {
        staticClass: "icon-arrow-down"
      })]) : _vm._e()]), _c('td', [_c('small', [_c('v-popover', {
        staticClass: "configbtn _hover",
        attrs: {
          "offset": "3",
          "placement": "right"
        }
      }, [_c('i', {
        staticClass: "icon-times color-red"
      }), _c('template', {
        staticClass: "new-block-tt",
        slot: "popover"
      }, [_c('ul', [_c('li', {
        directives: [{
          name: "close-popover",
          rawName: "v-close-popover"
        }],
        staticClass: "choose-block-li padding _small tac link color-red",
        on: {
          "click": function click($event) {
            return _vm.removeNewField(ix, idx);
          }
        }
      }, [_vm._v("Feld Löschen?")])])])], 2)], 1)])]);
    }), _c('tr', [_c('td', {
      attrs: {
        "colspan": "1"
      }
    }), _c('td', {
      attrs: {
        "colspan": "2"
      }
    }, [_c('button', {
      staticClass: "btn _small _bordered",
      on: {
        "click": function click($event) {
          return _vm.addNewField(ix);
        }
      }
    }, [_vm._v("neues Feld")])]), _c('td', {
      staticClass: "bold tac",
      attrs: {
        "colspan": "7"
      }
    })]), _c('tr', {
      staticClass: "italic"
    }, [_c('td', {
      attrs: {
        "colspan": "1"
      }
    }), _c('td', {
      staticClass: "_large",
      attrs: {
        "colspan": "1"
      }
    }, [_vm._v("Summe")]), _c('td', {
      staticClass: "td-input _small"
    }, [_c('money', {
      staticClass: "tar no-border",
      attrs: {
        "disabled": ""
      },
      model: {
        value: _vm.calculated.sums[ix].neuwert,
        callback: function callback($$v) {
          _vm.$set(_vm.calculated.sums[ix], "neuwert", $$v);
        },
        expression: "calculated.sums[ix].neuwert"
      }
    })], 1), _c('td', {
      staticClass: "_small"
    }), _c('td', {
      staticClass: "td-input _small"
    }, [_c('div', {
      staticClass: "tr"
    }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.calculated.sums[ix].zeitwert)))])]), _c('td', {
      staticClass: "td-input _small"
    }, [_c('money', {
      staticClass: "tar no-border",
      attrs: {
        "disabled": ""
      },
      model: {
        value: _vm.calculated.sums[ix].ablose,
        callback: function callback($$v) {
          _vm.$set(_vm.calculated.sums[ix], "ablose", $$v);
        },
        expression: "calculated.sums[ix].ablose"
      }
    })], 1), _c('td', {
      attrs: {
        "colspan": "2"
      }
    })])], 2);
  }), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('button', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        return _vm.addNewPart();
      }
    }
  }, [_vm._v("neuen Teil")])]), _c('td', {
    staticClass: "bold tac",
    attrs: {
      "colspan": "6"
    }
  })])]), _c('tfoot', [_vm._m(1), _c('tr', {
    staticClass: "bold"
  }, [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Gesamt")]), _c('td', {
    staticClass: "td-input"
  }, [_c('money', {
    staticClass: "tar bold no-border",
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.calculated.allsums.neuwert,
      callback: function callback($$v) {
        _vm.$set(_vm.calculated.allsums, "neuwert", $$v);
      },
      expression: "calculated.allsums.neuwert"
    }
  })], 1), _c('td'), _c('td', {
    staticClass: "td-input"
  }, [_c('div', {
    staticClass: "tr"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.calculated.allsums.zeitwert)))])]), _c('td', {
    staticClass: "td-input"
  }, [_c('money', {
    staticClass: "tar bold no-border",
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.calculated.allsums.ablose,
      callback: function callback($$v) {
        _vm.$set(_vm.calculated.allsums, "ablose", $$v);
      },
      expression: "calculated.allsums.ablose"
    }
  })], 1), _c('td', {
    staticClass: "td-input"
  })]), _c('tr', {
    staticClass: "bold"
  }, [_c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("Gemeldeter Betrag")]), _c('td', {
    staticClass: "td-input _small"
  }, [_c('money', {
    staticClass: "tar bold",
    model: {
      value: _vm.content.reportedAmount.value,
      callback: function callback($$v) {
        _vm.$set(_vm.content.reportedAmount, "value", $$v);
      },
      expression: "content.reportedAmount.value"
    }
  })], 1)]), _c('tr', {
    staticClass: "bold"
  }, [_c('td', {
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("Einsparung")]), _c('td', {
    staticClass: "td-input _small"
  }, [_c('money', {
    staticClass: "tar bold no-border",
    attrs: {
      "value": _vm.savedAmount,
      "disabled": ""
    }
  })], 1)])])], 2)]), _c('block-editor-options', {
    attrs: {
      "label": "Festgestellter Betrag von",
      "template": _vm.content.aggregateUsedForEstablishedAmount,
      "type": "radio",
      "no-annotation": ""
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Meldungsgrundlage",
      "template": _vm.content.reportedVia,
      "options": _vm.template.damageSumReportedVia,
      "no-annotation": ""
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Kategorie",
      "template": _vm.content.category,
      "options": _vm.template.damageSumCategory,
      "no-annotation": ""
    }
  }), _c('block-editor-textarea', {
    attrs: {
      "label": "Beschreibung",
      "template": _vm.content.description
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "_verysmall"
  }, [_vm._v("Pos.")]), _c('th', {
    staticClass: "_large"
  }, [_vm._v("Bezeichnung/Beurteilung")]), _c('th', {
    staticClass: "_small tr"
  }, [_vm._v("€Neuwert")]), _c('th', {
    staticClass: "_small tr"
  }, [_c('small', [_vm._v("ZW in % "), _c('br'), _vm._v(" vom NW")])]), _c('th', {
    staticClass: "_small tr"
  }, [_vm._v("€Zeitwert")]), _c('th', {
    staticClass: "_small tr"
  }, [_vm._v("€Ablöse")]), _c('th', {
    staticClass: "_small"
  }, [_c('i', {
    staticClass: "icon-times inline"
  })]), _c('th', [_c('i', {
    staticClass: "icon-times inline"
  })])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('tr', [_c('td', {
    staticClass: "bold",
    attrs: {
      "colspan": "8"
    }
  }, [_c('hr')])]);
}];
export { render, staticRenderFns };