import _slicedToArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _unitMap;
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.entries.js";
import { chain, first, groupBy, partition, round } from "lodash";
import * as constants from "crm";
export var officePercentage = 0.1;
export var overheadPercentage = 0.1;
var unitMap = (_unitMap = {}, _defineProperty(_unitMap, constants.EINHEITPAUSCHAL, 'flat'), _defineProperty(_unitMap, constants.EINHEITKM, 'travel'), _defineProperty(_unitMap, constants.EINHEITSTUNDEN, 'hourly'), _unitMap);
export function fromCrm(crmFeeNote) {
  return {
    expenses: crmFeeNote.aufwandArr.map(expenseFromCrm)
  };
}
export function costFromCrm(cost) {
  var _constants$EINHEITPAU;
  var unit = (_constants$EINHEITPAU = {}, _defineProperty(_constants$EINHEITPAU, constants.EINHEITPAUSCHAL, 'flat'), _defineProperty(_constants$EINHEITPAU, constants.EINHEITKM, 'km'), _defineProperty(_constants$EINHEITPAU, constants.EINHEITSTUNDEN, 'h'), _constants$EINHEITPAU)[cost.new_einheit];
  return _objectSpread({
    unit: unit,
    pos: cost.new_position,
    activity: mapActivity(cost.new_hntexte),
    cost: cost.new_kostenbetrag
  }, unit !== 'flat' && {
    amount: cost.new_anzahl
  });
}
export function expenseFromCrm(aufwand) {
  var unit = unitMap[aufwand.new_einheit];
  return _objectSpread(_objectSpread({
    feeNoteNumber: aufwand.new_honorarnr,
    userContactId: aufwand._new_bearbeiter_value,
    cost: aufwand.new_kosten,
    billableCost: aufwand.new_kostenverrechenbar,
    active: aufwand.new_uebernehmen,
    activity: mapActivity(aufwand.new_hntexte),
    task: "".concat(aufwand.new_taetigkeit),
    date: aufwand.new_datum,
    description: aufwand.new_taetigkeitsdetails,
    unit: unit
  }, unit === 'hourly' && {
    hours: aufwand.new_dauer,
    billableHours: aufwand.new_dauerverrechenbar
  }), unit === 'travel' && {
    distance: aufwand.new_fahrtstrecke,
    billableDistance: aufwand.new_fahrtstreckeverrechenbar
  });
}
export function intoCostPositions(expenses) {
  return chain(expenses).filter('active').groupBy('activity').map(function (vs, k) {
    return [k, vs];
  }).sort(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
      a1 = _ref3[0];
    var _ref4 = _slicedToArray(_ref2, 1),
      a2 = _ref4[0];
    // a1 cannot be equal to a2 by the preceding logic
    if (a1 === 'administration' || a1 === 'office') return 1;
    if (a2 === 'administration' || a2 === 'office') return -1;
    return 0;
  }).map(function (_ref5, idx) {
    var _ref6 = _slicedToArray(_ref5, 2),
      activity = _ref6[0],
      entries = _ref6[1];
    var unit = first(entries).unit;
    if (unit === 'flat') {
      var cost = chain(entries).map('billableCost').sum().value();
      return {
        unit: 'flat',
        pos: idx + 1,
        activity: activity,
        cost: cost
      };
    }
    if (unit === 'hourly') {
      var amount = chain(entries).map('billableHours').sum().value();
      return {
        unit: 'h',
        pos: idx + 1,
        activity: activity,
        cost: first(entries).billableCost || 0,
        amount: amount
      };
    }
    if (unit === 'travel') {
      var _amount = chain(entries).map('billableDistance').sum().value();
      return {
        unit: 'km',
        pos: idx + 1,
        activity: activity,
        cost: first(entries).billableCost || 0,
        amount: _amount
      };
    }
    throw new Error('unreachable');
  }).value();
}
function mapActivity(act) {
  if (act === constants.FREMDKOSTEN) return 'third-party-costs';else if (act === constants.SEKRETARIAT) return 'administration';else if (act === constants.BUERO) return 'office';else return "".concat(act);
}
export function contributionMargin(feeNote, userResolver) {
  var billable = totalExpenses(revenueOfExpense, feeNote.expenses);
  if (billable === 0) return 0;
  var personnelCost = Object.entries(groupBy(feeNote.expenses, function (x) {
    return x.feeNoteNumber || '__new__';
  })).map(function (_ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      _ = _ref8[0],
      expenses = _ref8[1];
    return totalCost(expenses, userResolver);
  }).reduce(function (a, b) {
    return a + b;
  }, 0);
  var revenue = billable - officeCosts(feeNote) - overheadCosts(feeNote) - personnelCost;
  return round(revenue / billable, 4);
}
export function officeCosts(feeNote) {
  // Third party costs are never considered for office overhead calculation
  var officeCostBase = totalExpenses(revenueOfExpense, feeNote.expenses.filter(function (x) {
    return x.activity !== 'third-party-costs';
  }));
  return officeCostBase * officePercentage;
}
export function overheadCosts(feeNote) {
  var overheadCostBase = totalExpenses(revenueOfExpense, feeNote.expenses.filter(function (x) {
    return x.activity !== 'third-party-costs';
  }));
  return overheadCostBase * overheadPercentage;
}
export function totalCost(expenses, resolver) {
  var val = Object.entries(groupBy(expenses, function (x) {
    return x.feeNoteNumber || '__new__';
  })).map(function (_ref9) {
    var _ref10 = _slicedToArray(_ref9, 2),
      _hnr = _ref10[0],
      expenses = _ref10[1];
    // Third party costs are always fully converted to personnel expenses
    var _partition = partition(expenses, function (e) {
        return e.activity === 'third-party-costs';
      }),
      _partition2 = _slicedToArray(_partition, 2),
      thirdPartyExpenses = _partition2[0],
      otherExpenses = _partition2[1];
    var thirdPartyCost = thirdPartyExpenses.map(function (e) {
      return e.cost;
    }).reduce(function (a, b) {
      return a + b;
    }, 0);
    var billable = totalExpenses(revenueOfExpense, otherExpenses);
    return thirdPartyCost + Object.entries(groupBy(otherExpenses, 'userContactId')).map(function (_ref11) {
      var _ref12 = _slicedToArray(_ref11, 2),
        userContactId = _ref12[0],
        expenses = _ref12[1];
      var billingType = resolver(userContactId);
      if (billingType.type === 'hourly') return totalExpenses(costOfExpense, expenses);else if (billingType.type === 'flat') return billingType.sharePercentage / 100 * billable;else return 0;
    }).reduce(function (a, b) {
      return a + b;
    }, 0);
  }).reduce(function (a, b) {
    return a + b;
  }, 0);
  return val;
}
export function totalExpenses(mapping, expenses) {
  return expenses.map(mapping).reduce(function (a, b) {
    return a + b;
  }, 0);
}
export function costOfExpense(expense) {
  var cost = expense.cost;
  switch (expense.unit) {
    case 'flat':
      return cost;
    case 'hourly':
      return cost * (expense.hours || 0);
    case 'travel':
      return cost * (expense.distance || 0);
    default:
      return 0;
  }
}
export function revenueOfExpense(expense) {
  if (!expense.active) return 0;
  var cost = expense.billableCost || expense.cost;
  switch (expense.unit) {
    case 'flat':
      return cost;
    case 'hourly':
      return cost * (expense.billableHours || expense.hours || 0);
    case 'travel':
      return cost * (expense.billableDistance || expense.distance || 0);
    default:
      return 0;
  }
}
export function usedInFeeNote(feeNote) {
  return function (e) {
    return e.active && e.feeNoteNumber === feeNote;
  };
}
export function totalCostOfPosition(cost) {
  switch (cost.unit) {
    case "flat":
      return cost.cost;
    default:
      return cost.cost * cost.amount;
  }
}