var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-popover', [_c('button', {
    staticClass: "btn _small",
    class: _vm.buttonClass
  }, [_vm._t("default", function () {
    return [_c('span', [_vm._v(_vm._s(_vm.label))])];
  })], 2), _c('template', {
    staticClass: "new-block-tt",
    slot: "popover"
  }, [_c('ul', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList tac"
  }, [_c('li', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList_li color-red bold",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._v(_vm._s(_vm.confirmLabel))])])])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };