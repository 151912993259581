var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('h3', [_vm._v(_vm._s(_vm.content.title.value || 'Stockwerk'))]), _c('block-editor-text-input', {
    attrs: {
      "template": _vm.content.title
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("Bezeichnung/Titel "), _c('br'), _vm._v(" (optional):")];
      },
      proxy: true
    }])
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Haus:",
      "template": _vm.content.room,
      "options": _vm.template.floor
    }
  }), _c('div', {
    staticClass: "input-field atop"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Größe")]), _c('div', {
    staticClass: "input"
  }, [_c('block-editor-text-input', {
    attrs: {
      "template": _vm.content.area
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "label _small"
        }, [_vm._v("ca. in m2:"), _c('br'), _c('small', {
          staticClass: "thin"
        }, [_vm._v("(nur Zahlen)")])])];
      },
      proxy: true
    }])
  }), _c('block-editor-options', {
    attrs: {
      "label": "lt. AST:",
      "template": _vm.content.ast,
      "type": "radio"
    }
  })], 1)]), _c('block-editor-options', {
    attrs: {
      "label": "Ausstattung",
      "template": _vm.content.equipment,
      "type": "radio"
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Zustand / Wartung:",
      "template": _vm.content.condition,
      "type": "radio"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };