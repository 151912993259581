var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": "Konflikt beim Speichern des Gutachtens"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "body"
  }, [_c('p', [_vm._v("Beim Speichern des Gutachtens wurde ein Konflikt festgestellt: Die gespeicherten Daten stimmen nicht mit dem Letztstand überein. Das Gutachten wurde wahrscheinlich seit dem Öffnen bearbeitet, daher wurde das Gutachten NICHT gespeichert, um einen Verlust von Daten zu vermeiden. ")]), _c('p', {
    staticClass: "color-red"
  }, [_c('b', [_vm._v("ACHTUNG:")]), _vm._v(" Schließen Sie diesen Tab "), _c('b', [_vm._v("NICHT")]), _vm._v(", damit Ihre Änderungen nicht verloren gehen.")]), _c('p', [_vm._v("Klicken Sie " + _vm._s(' ')), _c('a', {
    attrs: {
      "href": _vm.currentUrl,
      "target": "_blank",
      "rel": "noreferrer noopener"
    }
  }, [_vm._v("hier")]), _vm._v(", um das Gutachten in einem neuen Tab zu öffnen und die Datenbestände zu vergleichen.")]), _c('p', [_vm._v("Wenn Sie sich sicher sind, dass Sie mit Ihren Änderungen die gespeicherten Daten überschreiben wollen, klicken Sie den folgenden Button: ")]), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.overrideOpinionData
    }
  }, [_vm._v("Speichern und Überschreiben")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };