import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h3', [_vm._v("BEFUNDAUFNAHME")]), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Gebäude-Nutzung:",
      "template": _vm.content.buildingUsage,
      "options": _vm.template.gebaeudenutzung
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Lage:",
      "template": _vm.content.site,
      "options": _vm.template.lage
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Bauweise",
      "template": _vm.content.structure,
      "options": _vm.template.bauweise
    }
  }), _c('block-editor-radiocheck', {
    attrs: {
      "label": "Dachdeckung",
      "template": _vm.content.roofing,
      "options": _vm.template.dachdeckung
    }
  }), _c('block-editor-text-input', {
    attrs: {
      "label": "Errichtung-Sanierung:",
      "template": _vm.content.construction
    }
  }), _c('block-editor-options', {
    attrs: {
      "label": "Zustand / Wartung:",
      "template": _vm.content.condition,
      "type": "radio"
    }
  }), _c('tabs', {
    attrs: {
      "data": _vm.content.whg,
      "selected": _vm.selectedWhg
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selectedWhg = $event;
      },
      "add-clicked": _vm.addNewFlat
    },
    scopedSlots: _vm._u([{
      key: "head",
      fn: function fn(_ref) {
        var idx = _ref.idx;
        return [_vm._v("Wohnung " + _vm._s(idx + 1))];
      }
    }, {
      key: "tab",
      fn: function fn(_ref2) {
        var whg = _ref2.data,
          idx = _ref2.idx;
        return [_c('block-editor-text-input', {
          attrs: {
            "template": whg.name
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v("Bezeichnung/Titel "), _c('br'), _vm._v(" (optional):")];
            },
            proxy: true
          }, {
            key: "after",
            fn: function fn() {
              return [_c('v-popover', [_c('button', {
                staticClass: "btn _small _clear"
              }, [_c('i', {
                staticClass: "icon-trashbin"
              })]), _c('template', {
                staticClass: "new-block-tt",
                slot: "popover"
              }, [_c('a', {
                directives: [{
                  name: "close-popover",
                  rawName: "v-close-popover"
                }],
                staticClass: "link color-red",
                on: {
                  "click": function click($event) {
                    return _vm.deleteFlat(idx);
                  }
                }
              }, [_vm._v("Sicher Wohnung löschen?")])])], 2), _c('v-popover', [_c('button', {
                staticClass: "btn _small _clear"
              }, [_c('i', {
                staticClass: "icon-copy"
              })]), _c('template', {
                staticClass: "new-block-tt",
                slot: "popover"
              }, [_c('a', {
                directives: [{
                  name: "close-popover",
                  rawName: "v-close-popover"
                }],
                staticClass: "link color-blue",
                on: {
                  "click": function click($event) {
                    return _vm.duplicateFlat(idx);
                  }
                }
              }, [_vm._v("Kopie von Wohnung " + _vm._s(idx + 1) + " erstellen?")])])], 2)];
            },
            proxy: true
          }], null, true)
        }), _c('block-editor-radiocheck', {
          attrs: {
            "label": "Wohnung / Räumlichkeiten:",
            "template": whg.room,
            "options": _vm.template.whgraum
          }
        }), _c('div', {
          staticClass: "input-field atop"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Größe")]), _c('div', {
          staticClass: "input"
        }, [_c('block-editor-text-input', {
          attrs: {
            "template": whg.area
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "label _small"
              }, [_vm._v("ca. in m2:"), _c('br'), _c('small', {
                staticClass: "thin"
              }, [_vm._v("(nur Zahlen)")])])];
            },
            proxy: true
          }], null, true)
        }), _c('block-editor-options', {
          attrs: {
            "label": "lt. AST:",
            "template": whg.ast,
            "type": "radio"
          }
        })], 1)]), _c('block-editor-options', {
          attrs: {
            "label": "Ausstattung",
            "template": whg.equipment,
            "type": "radio"
          }
        }), _c('block-editor-options', {
          attrs: {
            "label": "Zustand / Wartung:",
            "template": whg.condition,
            "type": "radio"
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };