var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('block-editor-input', {
    attrs: {
      "display": _vm.display,
      "label": _vm.label
    },
    scopedSlots: _vm._u([_vm.$slots.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._t("label")];
      },
      proxy: true
    } : null, {
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "input"
        }, [_c('v-radiocheck', {
          attrs: {
            "template": _vm.options,
            "item": _vm.template.value,
            "reduce": _vm.template.$reduce
          }
        }), !_vm.noAnnotation ? _c('label', {
          staticClass: "input-field annotation"
        }, [_c('div', {
          staticClass: "label _small"
        }, [_vm._v("Anmerkung:")]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.template.value.annotation,
            expression: "template.value.annotation"
          }],
          staticClass: "input",
          attrs: {
            "type": "text"
          },
          domProps: {
            "value": _vm.template.value.annotation
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.template.value, "annotation", $event.target.value);
            }
          }
        })]) : _vm._e()], 1)];
      },
      proxy: true
    }], null, true)
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };