var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.value ? _c('div', {
    staticClass: "address"
  }, [_c('div', {
    staticClass: "fr"
  }, [false ? _c('div', {
    staticClass: "loading-spinner"
  }) : _c('div', {
    staticClass: "btn _s _green",
    on: {
      "click": _vm.CheckAddress
    }
  }, [_vm._v("Adresse überprüfen")])]), _c('h5', [_vm._v("Besichtigungsaddresse")]), _c('div', [_vm._v(_vm._s(_vm.value.street) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.value.streetAddition) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.value.postalCode.value) + ", " + _vm._s(_vm.value.city) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.value.country) + " "), _c('br'), _vm.value.hasCoords === 100000000 ? [_vm._v(_vm._s(_vm.value.lat) + " / " + _vm._s(_vm.value.lng))] : _vm._e(), _vm.value.hasCoords === 100000000 ? _c('div', {
    staticClass: "fr"
  }, [_c('div', {
    staticClass: "btn _s",
    on: {
      "click": function click($event) {
        _vm.showMap = !_vm.showMap;
      }
    }
  }, [_vm._v("Karte " + _vm._s(_vm.showMap ? 'ausblenden' : 'anzeigen'))])]) : _vm._e()], 2), _vm.value.hasCoords === 100000000 ? _c('gmap-map', {
    staticClass: "gmap",
    class: {
      show: _vm.showMap
    },
    attrs: {
      "center": {
        lat: _vm.value.lat,
        lng: _vm.value.lng
      },
      "zoom": 14
    }
  }, [_c('gmap-marker', {
    attrs: {
      "icon": {
        url: _vm.$root.img.icon15,
        size: {
          width: 20,
          height: 30,
          f: 'px',
          b: 'px'
        },
        scaledSize: {
          width: 20,
          height: 30,
          f: 'px',
          b: 'px'
        }
      },
      "position": {
        lat: _vm.value.lat,
        lng: _vm.value.lng
      },
      "draggable": false
    }
  })], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };