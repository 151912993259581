var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor simple-text"
  }, [_c('h1', {
    staticClass: "blue padding _large tac"
  }, [_vm._v("Zusammenfassung ")]), _c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("verbaute Fläche (v.Fl.)")]), _c('td', [_c('div', {
    staticClass: "tr"
  }, [_vm._v(_vm._s(_vm.round2(_vm.calculationResult.builtUpArea)) + " m"), _c('sup', [_vm._v("2")])])])]), _c('tr', [_c('td', [_vm._v("BGF gesamt")]), _c('td', [_c('div', {
    staticClass: "tr"
  }, [_vm._v(_vm._s(_vm.round2(_vm.calculationResult.allArea)) + " m"), _c('sup', [_vm._v("2")])])])]), _c('tr', [_c('td', [_vm._v("verbauter Raum")]), _c('td', [_c('div', {
    staticClass: "tr"
  }, [_vm._v(_vm._s(_vm.round2(_vm.calculationResult.allVolume)) + " m"), _c('sup', [_vm._v("3")])]), _c('div', {
    staticClass: "tr"
  }, [_vm._v(_vm._s(_vm.round2(_vm.calculationResult.builtUpVolume)) + " m"), _c('sup', [_vm._v("3")])])])]), _c('tr', [_vm._m(0), _c('td', [_c('div', {
    staticClass: "tr bold"
  }, [_vm._v(_vm._s(_vm.$_formatMoney(_vm.calculationResult.price)))])])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('td', [_c('div', {
    staticClass: "bold"
  }, [_vm._v("Gesamtwert")])]);
}];
export { render, staticRenderFns };