import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor"
  }, [_vm._l(_vm.beSummary.buildings, function (building) {
    return [_vm._l(building, function (bBlock, idx) {
      var _building$, _building$$values, _building$$values$;
      return [bBlock.key == 'calculation' ? _c('div', [_c('BECalculation', {
        attrs: {
          "content": bBlock,
          "readonlytitle": bBlock.tableTitle.value || (building === null || building === void 0 ? void 0 : (_building$ = building[0]) === null || _building$ === void 0 ? void 0 : (_building$$values = _building$.values) === null || _building$$values === void 0 ? void 0 : (_building$$values$ = _building$$values[0]) === null || _building$$values$ === void 0 ? void 0 : _building$$values$.value) || ''
        },
        on: {
          "update:content": function updateContent($event) {
            bBlock = $event;
          },
          "updateCalcResults": function updateCalcResults($event) {
            bBlock.calculationResults = $event;
          }
        }
      })], 1) : _vm._e()];
    })];
  }), _c('BECalculation', {
    attrs: {
      "content": _vm.content
    },
    on: {
      "update:content": function updateContent($event) {
        _vm.content = $event;
      },
      "updateCalcResults": function updateCalcResults($event) {
        return _vm.updateCalcResultsExtra($event);
      }
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };