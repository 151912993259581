var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-popover', {
    attrs: {
      "offset": "0",
      "position": "right"
    }
  }, [_c('button', {
    staticClass: "btn _clear nomargin"
  }, [_vm._t("default")], 2), _c('template', {
    staticClass: "new-block-tt",
    slot: "popover"
  }, [_c('ul', _vm._l(_vm.availableSubBlocks, function (item, key) {
    return _c('li', {
      directives: [{
        name: "close-popover",
        rawName: "v-close-popover"
      }],
      key: key,
      staticClass: "choose-block-li padding _small"
    }, [_c('button', {
      staticClass: "btn _clear nomargin",
      on: {
        "click": function click($event) {
          return _vm.$emit('selected', key);
        }
      }
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0)])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };