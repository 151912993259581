import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "row"
  }, [_c('button', {
    staticClass: "btn _clear",
    attrs: {
      "data-testid": "add-above"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add-above');
      }
    }
  }, [_c('i', {
    staticClass: "icon-add-above"
  })]), _c('button', {
    staticClass: "btn _clear",
    attrs: {
      "data-testid": "add-below"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add-below');
      }
    }
  }, [_c('i', {
    staticClass: "icon-add-below"
  })]), _c('div', {
    staticClass: "type-section"
  }, [_c('div', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.kindSync,
      expression: "kindSync"
    }],
    staticClass: "input type",
    on: {
      "input": function input($event) {
        return _vm.updateFields($event.target.value);
      },
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.kindSync = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.availableTypes, function (o, key) {
    return _c('option', {
      domProps: {
        "value": key
      }
    }, [_vm._v(_vm._s(o.label))]);
  }), 0)]), _vm.kindSync in _vm.availableTypes ? _c('ol', _vm._l(_vm.availableTypes[_vm.kindSync].fields || {}, function (f, fkey) {
    return _c('li', [_c('label', [_vm._v(_vm._s(f.label) + ":"), f.type === 'text' ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.fieldsDataSync[fkey],
        expression: "fieldsDataSync[fkey]"
      }],
      staticClass: "input",
      domProps: {
        "value": _vm.fieldsDataSync[fkey]
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.fieldsDataSync, fkey, $event.target.value);
        }
      }
    }) : _vm._e()])]);
  }), 0) : _vm._e()]), _c('button', {
    staticClass: "btn _clear",
    attrs: {
      "data-testid": "delete"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_c('i', {
    staticClass: "icon-trashbin"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };