import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.replace-all.js";
import { schadensumme } from "@consense/crm";
/ * eslint-disable */;
import dayjs from "dayjs";
import crmstuff from "@/assets/json/crmstuff.js";
var multipleRadioBtns = {
  contact: {
    multiple: false,
    options1: ["Sachverständiger", "Versicherungsnehmer", "Anspruchsteller", "Mieter", "Makler/Betreuer", "Auftraggeber/Referent", "Hausverwaltung", "Fachfirma", "Sanierungsunternehmen", "Andere"]
  },
  titelfeld: {
    multiple: false,
    options1: ["Gutachterliche Stellungnahme", "Besichtigungsbericht", "Gutachten", "Stellungnahme", "Expertise"]
  },
  gebaeudenutzung: {
    multiple: false,
    options1: ["Mehrfamilienwohnhaus", "Wohn- u. Geschäftsgebäude", "Wohnhausanlage", "Einfamilienhaus", "Reihenhaus", "Alt Wiener Haus", "Betriebsobjekt", "Kleingartenhaus", "Landwirtschaft"],
    options2: ["ebenerdig", "mehrgeschossig"]
  },
  lage: {
    multiple: true,
    options1: ["Freistehend", "Einseitig gekoppelt", "Beidseitig gekoppelt ", "Gebäudeverbund", "Stadtgebiet/Ortsgebiet", "Siedlungsgebiet", "Ebene Grundstückslage", "Leichte Geländeneigung", "Starke Hanglage"]
  },
  bauweise: {
    multiple: false,
    options1: ["Massiv", "Gemischt", "Fertigteil (Leichtbauweise)"],
    options2: ["einfache Ausführung", "Standardausführung", "hochwertige Ausführung"]
  },
  dachdeckung: {
    multiple: true,
    options1: ["Pultdach", "Flachdach", "Satteldach", "Walmdach", "harte Deckung", "weiche Deckung", "Nicht Bekannt", "sonstiges"]
  },
  whgraum: {
    multiple: false,
    options1: ["Eigentum", "Genossenschaft", "Miete"],
    options2: ["Erdgeschoß", "Dachgeschoß", "1. Stockwerk", "2. Stockwerk", "3. Stockwerk", "4. Stockwerk", "5. Stockwerk", "6. Stockwerk", "7. Stockwerk", "8. Stockwerk", "9. Stockwerk", "10. Stockwerk"],
    options3: ["dauerhaft bewohnt", "temporär bewohnt", "unbewohnt"]
  },
  house: {
    multiple: false,
    options1: ["Eigentum", "Genossenschaft", "Miete"],
    options2: ["dauerhaft bewohnt", "temporär bewohnt", "unbewohnt"]
  },
  stairwell: {
    multiple: true,
    options1: ["Erdgeschoß", "Dachgeschoß", "1. Stockwerk", "2. Stockwerk", "3. Stockwerk", "4. Stockwerk", "5. Stockwerk", "6. Stockwerk", "7. Stockwerk", "8. Stockwerk", "9. Stockwerk", "10. Stockwerk"]
  },
  room: {
    multiple: false,
    options1: ['Wohnzimmer', 'Schlafzimmer', 'Kinderzimmer', 'Esszimmer', 'Küche', 'Badezimmer', 'Flur/Gang/Stiegenhaus', 'Kabinett', 'Abstellraum', 'Keller', 'Dachboden', 'WC'],
    options2: ["Erdgeschoß", "Dachgeschoß", "1. Stockwerk", "2. Stockwerk", "3. Stockwerk", "4. Stockwerk", "5. Stockwerk", "6. Stockwerk", "7. Stockwerk", "8. Stockwerk", "9. Stockwerk", "10. Stockwerk"]
  },
  floor: {
    multiple: false,
    options1: ["Erdgeschoß", "Dachgeschoß", "1. Stockwerk", "2. Stockwerk", "3. Stockwerk", "4. Stockwerk", "5. Stockwerk", "6. Stockwerk", "7. Stockwerk", "8. Stockwerk", "9. Stockwerk", "10. Stockwerk"],
    options2: ["dauerhaft bewohnt", "temporär bewohnt", "unbewohnt"]
  },
  damageSumCategory: {
    multiple: false,
    options1: schadensumme.kategorie
  },
  damageSumReportedVia: {
    multiple: false,
    options1: schadensumme.von
  }
};
var BlockPrepareCrmAkt = {
  µ_template: function µ_template() {
    return multipleRadioBtns;
  },
  µ_prepareGaHeadContent: function µ_prepareGaHeadContent(crmAkt) {
    // console.log(JSON.stringify(crmAkt));
    var mtabtr = crmAkt.new_mitarbeiter || {};
    var aftgbr = crmAkt.new_auftraggeber || {};
    var rfrent = crmAkt.new_referent || {};
    var headInfo1 = ((aftgbr.name || "") + "\n" + "zH " + (rfrent.salutation || "") + " " + (rfrent.new_titel || "") + " " + (rfrent.firstname || "") + " " + (rfrent.lastname || "") + "\n" + aftgbr.address1_line1 + "\n" + aftgbr.address1_postalcode + " " + aftgbr.address1_city).replace("  ", " ").replace("  ", " ").replace("  ", " ").replace("  ", " ").trim();
    var bearbeiter = ((mtabtr.new_titel || "") + " " + (mtabtr.firstname || "") + " " + (mtabtr.lastname || "")).replace("  ", " ").replace("  ", " ").replace("  ", " ").trim();
    var gaData = {
      headInfo1: headInfo1,
      headInfo2: "",
      consensenr: crmAkt.new_name.substring(0, 10),
      bearbeiter: bearbeiter,
      date: "Wien, am " + dayjs().format("DD.MM.YYYY")
    };
    return gaData;
  },
  µ_prepareGaMainContent: function µ_prepareGaMainContent(crmAkt) {
    var geschadigterData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    console.log("µ_prepareGaMainContent");
    var mtabtr = crmAkt.new_mitarbeiter || {};
    // let geschadigte = crmAkt.i || {}
    console.log(geschadigterData);
    var anspruchsteller = "";
    geschadigterData.forEach(function (i) {
      var g = i.new_geschaedigter;
      var firmenname = g.new_firmenname ? "(".concat(g.new_firmenname, ")") : '';
      var vorname = g.new_vorname ? "".concat(g.new_vorname) : '';
      var nachname = g.new_nachname ? "".concat(g.new_nachname) : '';
      var versicherungsnehmer = g.new_versicherungsnehmer ? "".concat(g.new_versicherungsnehmer) : '';
      var anspruchstellerTmp = "".concat(vorname, " ").concat(nachname, " ").concat(firmenname || versicherungsnehmer);
      anspruchsteller += anspruchstellerTmp.replace("  ", " ").trim() + "\n";
    });
    var gaData = {
      titelfeld: {
        value1: "Besichtigungsbericht"
      },
      consensenr: crmAkt.new_name.substring(0, 10) || "",
      bearbeiter: (mtabtr.new_titel || "") + " " + (mtabtr.firstname || "") + " " + (mtabtr.lastname || ""),
      // date: "Wien, am " + dayjs().format("DD.MM.YYYY"),
      //HEADPART
      // versicherungsnehmer: crmAkt.new_versicherungsnehmer.new_versicherungsnehmer || "",
      versicherungsnehmer: ((crmAkt.new_versicherungsnehmer.new_titel || "") + ' ' + (crmAkt.new_versicherungsnehmer.new_vorname || "") + ' ' + (crmAkt.new_versicherungsnehmer.new_versicherungsnehmer || "")).replace("  ", " ").replace("  ", " ").trim(),
      schadennummer: crmAkt.new_schadennummer || "",
      anspruchsteller: anspruchsteller.trim(),
      auftragsnummer: crmAkt.new_auftragsnummer || "",
      besichtigungsort: ((crmAkt.new_plz || {}).new_plz || "") + ' ' + (crmAkt.new_besichtigungsadresse.new_ort || "") + ', ' + (crmAkt.new_besichtigungsadresse.new_strasse || ""),
      schadendatum: crmAkt.new_schadendatum ? dayjs(crmAkt.new_schadendatum).format("DD.MM.YYYY") : "",
      polizzennummer: crmAkt.new_polizzennummer || "",
      auftragvom: crmAkt.new_auftragsdatum ? dayjs(crmAkt.new_auftragsdatum).format("DD.MM.YYYY") : "",
      // besichtigtam: crmAkt.new_besichtigungsdatumkurz,
      besichtigtam: crmAkt.new_besichtigungsdatumkurz ? dayjs(crmAkt.new_besichtigungsdatumkurz).format("DD.MM.YYYY") : "",
      sparte: crmstuff.sparten.details[crmAkt.new_spartendetails],
      // bewertet_html: "--bewertet_html--",
      bewertetAny: {
        options: [{
          name: "Gebäude",
          value: "gebaude"
        }, {
          name: "Inhalt",
          value: "inhalt"
        }, {
          name: "Sonstiges",
          value: "sonstiges"
        }],
        checked: [],
        anmerkung: ""
      },
      anwesendbefund: "",
      // regressmoglichkeit_html: "--regressmoglichkeit_html--",
      regressmoglichkeitAny: {
        options: [{
          name: "Ja",
          value: "ja"
        }, {
          name: "Nein",
          value: "nein"
        }],
        selected: "",
        anmerkung: ""
      },
      // vorschaden_html: "--vorschaden_html--",
      vorschadenAny: {
        options: [{
          name: "Ja",
          value: "ja"
        }, {
          name: "Nein",
          value: "nein"
        }],
        selected: "",
        anmerkung: ""
      },
      schadenursache: "",
      // schadensumme_html: "schadensumme_html",
      schadensummeAny: {
        options: [{
          name: "inkl. USt.",
          value: "incmwst"
        }, {
          name: "exkl. USt.",
          value: "excmwst"
        }],
        selected: "",
        anmerkung: ""
      },
      neuwertubernehmen: false,
      neuwert: '',
      zeitwert: '',
      // ablose_html: "ablose_html",
      abloseAny: {
        options: [{
          name: "JA, AST an Ablöse interessiert",
          value: "ja"
        }, {
          name: "Nein",
          value: "nein"
        }, {
          name: "Details siehe Gutachten",
          value: "siehega"
        }],
        selected: ""
      },
      ablosesumme: "",
      kontonr: "",
      kontonranzeigen: false
      // },
    };
    return gaData;
  },
  µ_prepareGaBaContent: function µ_prepareGaBaContent() {
    var gaData = {
      // template:multipleRadioBtns.gebaeudenutzung,
      gebaeudenutzung: {
        anmerkung: ""
      },
      //Gebäude-Nutzung:
      lage: {
        anmerkung: ""
      },
      bauweise: {
        anmerkung: ""
      },
      dachdeckung: {
        anmerkung: ""
      },
      errichtungsanierung: "",
      gebzustandWartungAny: {
        options: [{
          name: "neuwertig",
          value: "neuwertig"
        }, {
          name: "gut",
          value: "gut"
        }, {
          name: "abgenützt",
          value: "abgenutzt"
        }, {
          name: "desolat",
          value: "desolat"
        }],
        selected: "",
        anmerkung: ""
      },
      whg: [{
        whgname: "",
        whgraum: {
          anmerkung: ""
        },
        groesseAny: {
          options: [
          // {
          //   name: "Keine Auswahl",
          //   value: "empty",
          // },
          {
            name: "nachvollziehbar",
            value: "nachvollziehbar"
          }, {
            name: "nicht nachvollziehbar",
            value: "nichtnachvollziehbar"
          }],
          selected: "empty",
          anmerkung: ""
        },
        ausstattungAny: {
          options: [
          // {
          //   name: "Keine Auswahl",
          //   value: "empty",
          // },
          {
            name: "Einfach",
            value: "einfach"
          }, {
            name: "Standard",
            value: "standard"
          }, {
            name: "Exklusiv - Hochwertig",
            value: "exklusivhochwertig"
          }],
          selected: "empty",
          anmerkung: ""
        },
        whgzustandwartungAny: {
          options: [
          // {
          //   name: "Keine Auswahl",
          //   value: "empty",
          // },
          {
            name: "neuwertig",
            value: "neuwertig"
          }, {
            name: "gut",
            value: "gut"
          }, {
            name: "abgenützt",
            value: "abgenutzt"
          }, {
            name: "desolat",
            value: "desolat"
          }],
          selected: "empty",
          anmerkung: ""
        }
      }]
    };
    return gaData;
  },
  µ_prepareGaSbContent: function µ_prepareGaSbContent() {
    var gaData = {
      schadenhergangursache: "",
      zustandbefundAny: {
        options: [{
          name: "unsaniert",
          value: "unsaniert"
        }, {
          name: "teilsaniert",
          value: "teilsaniert"
        }, {
          name: "saniert",
          value: "saniert"
        }],
        selected: "",
        anmerkung: ""
      },
      schadenmassnahmenAny: {
        options: [{
          name: "besprochen",
          value: "besprochen"
        }, {
          name: "notwendig",
          value: "notwendig"
        }, {
          name: "nicht notwendig",
          value: "nichtnotwendig"
        }, {
          name: "veranlasst",
          value: "veranlasst"
        }],
        selected: "",
        anmerkung: ""
      },
      bewohnbarkeitAny: {
        options: [{
          name: "bewohnbar",
          value: "bewohnbar"
        }, {
          name: "eingeschränkt bewohnbar",
          value: "eingeschranktbewohnbar"
        }, {
          name: "unbewohnbar",
          value: "unbewohnbar"
        }, {
          name: "(nicht im PDF anzeigen)",
          value: ""
        }],
        selected: "",
        anmerkung: ""
      },
      schadenamgebaude: "",
      schadenaminhalt: "",
      sonstigeInfo: ""
    };
    return gaData;
  },
  µ_prepareGaCostContent: function µ_prepareGaCostContent() {
    var gaData = {
      data: [{
        title: "Gebäudeschaden",
        values: [{
          bez: "",
          neuwert: 0,
          prozent: 0,
          ablose: 0
        }]
      }, {
        title: "Inhaltschaden - Inventar",
        values: [{
          bez: "",
          neuwert: 0,
          prozent: 0,
          ablose: 0
        }]
      }, {
        title: "Kosten für Gebäude / Inhalt (Abbruch-, Aufräum-, Reinigungskosten, etc.)",
        values: [{
          bez: "",
          neuwert: 0,
          prozent: 0,
          ablose: 0
        }]
      }]
    };
    return gaData;
  },
  µ_prepareGaLngVsum: function µ_prepareGaLngVsum() {
    var gaData = {
      gebaeudevslautag: "",
      gebaeudevsplausibel: {
        options: [{
          name: "keine Angabe",
          value: "kA"
        }, {
          name: "plausibel",
          value: "plausibel"
        }, {
          name: "nicht plausibel,",
          value: "nichtplausibel"
        }],
        selected: "kA"
      },
      inhaltvslautag: "",
      inhaltvsplausibel: {
        options: [{
          name: "keine Angabe",
          value: "kA"
        }, {
          name: "plausibel",
          value: "plausibel"
        }, {
          name: "nicht plausibel,",
          value: "nichtplausibel"
        }],
        selected: "kA"
      }
    };
    return gaData;
  },
  µ_prepareGaLngNotices: function µ_prepareGaLngNotices() {
    var gaData = {
      besonderehinweise: ""
    };
    return gaData;
  },
  µ_prepareGaLngAttachments: function µ_prepareGaLngAttachments(crmAkt) {
    // let aftgbr = crmAkt.new_auftraggeber || {}
    var rfrent = crmAkt.new_referent || {};
    var makler = crmAkt.new_makler || {};
    var maklerName = ((makler.salutation || "") + " " + (makler.new_titel || "") + " " + (makler.firstname || "") + " " + (makler.lastname || "")).trim().replaceAll("  ", " ");
    var rfrentName = ((rfrent.salutation || "") + " " + (rfrent.new_titel || "") + " " + (rfrent.firstname || "") + " " + (rfrent.lastname || "")).trim().replaceAll("  ", " ");
    var vnehmer = ((crmAkt.new_versicherungsnehmer.new_titel || "") + ' ' + (crmAkt.new_versicherungsnehmer.new_vorname || "") + ' ' + (crmAkt.new_versicherungsnehmer.new_versicherungsnehmer || "")).trim().replaceAll("  ", " ");
    var gaData = {
      data: [],
      vnehmer: vnehmer,
      ast: "",
      mieter: "",
      makler: maklerName,
      ageberref: rfrentName,
      hv: "",
      fachfirma: "",
      sanierfirma: "",
      anmerkung: "",
      showVnehmer: true,
      showAst: true,
      showMieter: true,
      showMakler: true,
      showAgeberref: true,
      showHv: true,
      showFachfirma: true,
      showSanierfirma: true,
      showAnmerkung: true
    };
    return gaData;
  },
  µ_prepareGaLngSuContent: function µ_prepareGaLngSuContent() {
    var gaData = {
      schadenursache: ""
    };
    return gaData;
  },
  µ_prepareGaLngMnContent: function µ_prepareGaLngMnContent() {
    var gaData = {
      massnahmengebaude: '',
      massnahmeninventar: '',
      sonstigeInfo: ''
    };
    return gaData;
  },
  µ_prepareSnMainTable: function µ_prepareSnMainTable(crmAkt) {
    var mtabtr = crmAkt.new_mitarbeiter || {};
    // let aftgbr = crmAkt.new_auftraggeber || {}
    // let rfrent = crmAkt.new_referent || {}
    var snData = {
      titelfeld: {
        value1: "Stellungnahme"
      },
      consensenr: crmAkt.new_name.substring(0, 10) || "",
      bearbeiter: (mtabtr.new_titel || "") + " " + (mtabtr.firstname || "") + " " + (mtabtr.lastname || ""),
      schadennummer: crmAkt.new_schadennummer || "",
      versicherungsnehmer: ((crmAkt.new_versicherungsnehmer.new_titel || "") + ' ' + (crmAkt.new_versicherungsnehmer.new_vorname || "") + ' ' + (crmAkt.new_versicherungsnehmer.new_versicherungsnehmer || "")).replace("  ", " ").replace("  ", " ").trim(),
      polizzennummer: crmAkt.new_polizzennummer || "",
      besichtigungsort: ((crmAkt.new_plz || {}).new_plz || "") + ' ' + (crmAkt.new_besichtigungsadresse.new_ort || "") + ', ' + (crmAkt.new_besichtigungsadresse.new_strasse || ""),
      schadendatum: crmAkt.new_schadendatum ? dayjs(crmAkt.new_schadendatum).format("DD.MM.YYYY") : "",
      schadensummeAny: {
        options: [{
          name: "inkl. USt.",
          value: "incmwst"
        }, {
          name: "exkl. USt.",
          value: "excmwst"
        }],
        selected: ""
      }
    };
    return snData;
  },
  µ_prepareSnTexts: function µ_prepareSnTexts() {
    var gaData = {
      title: "",
      sntexte: ""
    };
    return gaData;
  }
};
export default BlockPrepareCrmAkt;